import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment";

export const createOrGetUser = async (res: any) => {
  const decoded: { name: string; picture: string; sub: string } = jwt_decode(
    res.credential
  );
  const { name, picture, sub } = decoded;

  return decoded;
};

// Kiểm tra ngày hiện tại có vượt quá ngày hết hạn hay không
export const isExpired = (expiredAt: string) => {
  const currentDate = moment();
  const expirationDate = moment(expiredAt);
  return currentDate.isAfter(expirationDate);
};