/* eslint-disable */
import { Checkbox, Form, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import { notifyError, notifySuccess } from "src/components/notification";
import { AppState, RouteParams } from "src/types";
import routerNames from "src/utils/data/routerName";
import { getMessageV1 } from "src/utils/helpers/functions/getMessage";
import { geneUniqueKey } from "src/utils/helpers/functions/textUtils";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { ArrowLeftOutlined } from "@ant-design/icons";
import SvgIconMoveRight from "src/assets/svg/SvgIconMoveRight";
import { getModuleFunct } from "src/services/actions/mouduleFunct.actioms";
import { updatePageHeader } from "src/services/actions/pageHeader.action";
import { getRoleDetail } from "src/services/actions/roles.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import "./role.scss";
import Loading from "src/components/loading/Loading";
const RoleDetail = ({}: any) => {
	const [formUpdate] = Form.useForm();
	const [mainChecked, setMainChecked] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [checkStatus, setCheckStatus] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [callback, setCallback] = useState<boolean>(false);
	const dispatch = useDispatch();
	const isMount = useIsMount();
	const paramsURL = useParams<RouteParams>();
	const [functions, setFunctions] = useState<any[]>([]);
	// const { stateRoleDetail } = useSelector((e: AppState) => e.rolesReducer);
	const [stateRoleDetail, setStateRoleDetail] = useState<any>([]);
	const { stateGetModuleFunct } = useSelector((e: AppState) => e.moduleFunctReducer);

	/****************************START**************************/
	/*                         Life Cycle                      */
	// const [roles, setRoles] = useState<any>([]);
	// const pathName = useHistory().location.pathname.slice(1, 12);
	// useEffect(() => {
	// 	let _dataUser = JSON.parse(localStorage.getItem("ACCOUNT") || "");
	// 	let fakeRoles = [];
	// 	if (_dataUser?.menu) {
	// 		for (let i = 0; i < _dataUser.menu.length; i++) {
	// 			for (let j = 0; j < _dataUser.menu[i].children.length; j++) {
	// 				if (_dataUser.menu[i].children[j].funct_code === pathName.toString()) {
	// 					for (let k = 0; k < _dataUser.menu[i].children[j].children.length; k++) {
	// 						if (_dataUser.menu[i].children[j].children[k].funct_code === "update-user-group") {
	// 							fakeRoles.push("update-user-group");
	// 						}
	// 					}
	// 					break;
	// 				}
	// 			}
	// 		}
	// 		setRoles(fakeRoles);
	// 	}
	// }, [localStorage.getItem("ACCOUNT")]);
	useEffect(() => {
		let _params = [
			{
				path: "/",
				name: "Trang chủ"
			},
			{
				name: "Thiết lập hệ thống"
			},
			{
				path: routerNames.ROLES,
				name: "Quản lý nhóm vai trò"
			},
			{
				path: "/",
				name: "Chi tiết"
			}
		];
		dispatch(updatePageHeader(_params));

	}, [dispatch]);

	useEffect(() => {
		dispatch(getModuleFunct({ status: "A" }));
	}, []);

	useEffect(() => {
		// dispatch(getRoleDetail(paramsURL?.id));
		try {
			setLoading(true);
			const res: any = _apiNotSaga
				.getRolFunction(paramsURL.id, {})
				.then((res: any) => {
					if (res.success) {
						setStateRoleDetail(res.data);
					}
				})
				.catch((err) => {
					notifyError(err.message);
				});

			// setStateRoleDetail(res.data);
			notifySuccess(res.message);
			setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	}, [paramsURL.id]);


	useEffect(() => {
		if (isMount) return;
		const { data, message, success, error, isLoading } = stateGetModuleFunct;

		setLoading(isLoading || false);
		if (success) {
			let dataFunctions = data;
			let fakeFunctions = [...dataFunctions?.sort((a: any, b: any) => b?.children.length - a?.children.length)];
			let convertArray = [];
			let fakeCheckStatus = [];

			for (var i = 0; i < fakeFunctions?.length; i++) {
				fakeCheckStatus.push({
					id: fakeFunctions[i]?.id,
					status: true
				});
				let convertLevel1 = {
					children: [] as any,
					value: fakeFunctions[i]?.id,
					label: fakeFunctions[i]?.funct_name,
					id: fakeFunctions[i]?.id
				};
				for (var j = 0; j < fakeFunctions[i]?.children?.length; j++) {
					let convertLevel2 = {
						children: [] as any,
						value: fakeFunctions[i]?.children[j]?.id,
						label: fakeFunctions[i]?.children[j]?.funct_name,
						id: fakeFunctions[i]?.children[j]?.id
					};
					for (var k = 0; k < fakeFunctions[i]?.children[j]?.children?.length; k++) {
						convertLevel2.children.push({
							value: fakeFunctions[i]?.children[j]?.children[k]?.id,
							label: fakeFunctions[i]?.children[j]?.children[k]?.funct_name,
							relative_funct_ids: fakeFunctions[i]?.children[j]?.children[k]?.relative_funct_ids
						});
					}

					convertLevel1.children.push(convertLevel2);
				}
				convertArray.push(convertLevel1);
			}
			setCheckStatus(fakeCheckStatus);
			setFunctions(convertArray);
		}
		if (success === false || error) {
			let msg = getMessageV1(message, ", ");
			return notifyError(msg);
		}
	}, [stateGetModuleFunct.isLoading]);
	// console.log("mainChecked",mainChecked)

	useEffect(() => {
		if (isMount) return;
		if (functions.length === 0) return;
		setLoading(false);
		if (stateRoleDetail?.length > 0) {
			let arrayChecked = [] as any;
			for (var i = 0; i < stateRoleDetail?.length; i++) {
				for (var j = 0; j < functions.length; j++) {
					if (stateRoleDetail[i].funct_id === functions[j].value && functions[j].children.length === 0) {
						let fakeMainChecked = [...mainChecked];
						let a = fakeMainChecked.find((x) => x.id === stateRoleDetail[i]);
						if (!a) {
							fakeMainChecked.push({
								id: stateRoleDetail[i]?.funct_id,
								checkAll: true,
								ilu: false
							});
							setMainChecked(fakeMainChecked);
						}
					}
					for (var k = 0; k < functions[j]?.children.length; k++) {
						if (
							functions[j]?.children[k].value === stateRoleDetail[i]?.funct_id &&
							functions[j]?.children[k].children.length === 0
						) {
							let a = arrayChecked.find((x: any) => x.id === functions[j].children[k].id);

							if (a) {
								a.listLabel = [];
								a.value = [];
								a.indeterminate = false;
								a.checkAll = true;
								arrayChecked = arrayChecked.map((x: any) => (x.id === functions[j].children[k].id ? a : x));
							} else {
								arrayChecked.push({
									checkAll: true,
									id: functions[j].children[k].id,
									indeterminate: false,
									listLabel: [],
									parent_id: functions[j].id,
									value: []
								});
							}
						}
						for (var l = 0; l < functions[j].children[k].children.length; l++) {
							if (stateRoleDetail[i]?.funct_id == functions[j].children[k].children[l].value) {
								let a = arrayChecked.find((x: any) => x.id === functions[j].children[k].id);
								if (a) {
									a.listLabel.push(functions[j].children[k].children[l]);
									a.value.push(stateRoleDetail[i]?.funct_id);
									a.indeterminate =
										functions[j]?.children[k]?.children.length > a.value.length && a.value.length > 0 ? true : false;
									a.checkAll = functions[j]?.children[k]?.children.length === a.value.length ? true : false;
									arrayChecked = arrayChecked.map((x: any) => (x.id === functions[j].children[k].id ? a : x));
								} else {
									arrayChecked.push({
										checkAll: functions[j].children[k].children.length == 1 ? true : false,
										id: functions[j].children[k].id,
										indeterminate: functions[j].children[k].children.length == 1 ? false : true,
										listLabel: [functions[j].children[k].children[l]],
										parent_id: functions[j].id,
										value: [stateRoleDetail[i]?.funct_id]
									});
								}
							}
						}
					}
				}
			}
			setCheckedList(arrayChecked);
		}

		// if (success === false || error) {
		// 	let msg = getMessageV1(message, ", ");
		// 	return notifyError(msg);
		// }
	}, [stateRoleDetail, functions]);



	const btnCreatePrivilege = async () => {
		let arrayId = [] as any;
		for (var i = 0; i < checkedList.length; i++) {
			if (checkedList[i].value.length > 0 || checkedList[i].checkAll) {
				arrayId.push(checkedList[i].id);
				arrayId = arrayId.concat(checkedList[i].value);
			}
		}
		for (var i = 0; i < mainChecked.length; i++) {
			if (mainChecked[i].checkAll || mainChecked[i].ilu) {
				arrayId.push(mainChecked[i].id);
			}
		}
		let params = {
			role_id: paramsURL.id,
			// role_name: stateRoleDetail?.data?.role_name || "",
			// status: stateRoleDetail?.data?.status,
			funct_ids: [...new Set(arrayId)]
		};

		
		console.log("params",params)
		console.log("arrayId", arrayId)
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.updateRoleFunction(paramsURL.id, params);
			notifySuccess(res.message);
			setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};

	const onCheckAllChange = (id: any, parent_id: any) => {
		let fakeCate = [] as any;
		let listHaveValue = [] as any[];
		let listCate = functions
			?.find((a) => a.children.find((x: any) => x.id === id))
			?.children?.find((x: any) => x.value === id)?.children;
		for (var c = 0; c < listCate.length; c++) {
			if (listCate[c].relative_funct_ids) {
				fakeCate = [...fakeCate, ...listCate[c].relative_funct_ids.split(",").map((e: any) => +e)];
			}
			fakeCate.push(listCate[c].value);
			listHaveValue.push(listCate[c]);
		}
		for (var i = 0; i < checkedList.length; i++) {
			if (checkedList[i].id === id) {
				let fakeList = [...checkedList];
				setCheckedList(
					fakeList.map((obj) =>
						obj.id === id
							? obj.checkAll === false
								? {
										parent_id: parent_id,

										id: id,
										value: fakeCate,
										listLabel: listHaveValue,

										checkAll: true,
										indeterminate: false
								  }
								: {
										parent_id: parent_id,

										id: id,
										value: [],
										checkAll: false,
										indeterminate: false
								  }
							: obj
					)
				);
				return;
			} else {
				continue;
			}
		}
		setCheckedList([
			...checkedList,
			{
				parent_id: parent_id,
				id: id,
				value: fakeCate,
				listLabel: listHaveValue,
				checkAll: true,
				indeterminate: false
			}
		]);
	};
	const onChange = (list: any, id: any, parent_id: any) => {
		let listCate = functions
			?.find((a) => a.children.find((x: any) => x.id === id))
			?.children?.find((x: any) => x.value === id)?.children;
		let listHaveValue = [] as any[];
		let _list = [] as any[];
		for (var x = 0; x < list.length; x++) {
			const _find = listCate?.find((cate: any) => cate.value === list[x]);
			listHaveValue.push(_find);
			if (_find.relative_funct_ids) {
				_list = [..._list, ..._find.relative_funct_ids.split(",").map((e: any) => +e)];
			}
			_list.push(_find.value);
		}
		for (var i = 0; i < checkedList.length; i++) {
			if (checkedList[i].id === id) {
				let fakeList = [...checkedList];
				setCheckedList(
					fakeList.map((obj) =>
						obj.id === id
							? {
									parent_id: parent_id,

									id: id,
									value: _list,
									listLabel: listHaveValue,

									checkAll: list.length === listCate.length,
									indeterminate: !!list.length && list.length < listCate.length
							  }
							: obj
					)
				);
				return;
			} else {
				continue;
			}
		}

		setCheckedList([
			...checkedList,
			{
				parent_id: parent_id,
				id: id,
				value: _list,
				listLabel: listHaveValue,
				checkAll: list.length === listCate.length,
				indeterminate: !!list.length && list.length < listCate.length
			}
		]);
	};
	useEffect(() => {
		let arrayTest = [...mainChecked];
		const handleCheckLevel1True = (id: any) => {
			let fakeArray = [...checkedList].filter((x) => x.parent_id === id);
			let lengthArray = [...functions].find((x) => x.id === id);
			let array = arrayTest;

			if (!fakeArray.some((x) => x.checkAll === true) && !fakeArray.some((x) => x.indeterminate === true)) {
				let item = {
					checkAll: false,
					ilu: false,
					id: id
				};
				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			} else if (fakeArray.some((x) => x.indeterminate === true)) {
				let item = {
					checkAll: false,
					ilu: true,
					id: id
				};
				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			} else if (
				(fakeArray && fakeArray.length === 0) ||
				(fakeArray.length === 1 && fakeArray[0].value.length === 0 && !fakeArray[0].checkAll)
			) {
				let item = {
					checkAll: false,
					ilu: false,
					id: id
				};

				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			} else if (
				fakeArray.filter((x) => x.checkAll === false).length > 0 ||
				fakeArray.length < lengthArray?.children?.length
			) {
				let item = {
					checkAll: false,
					ilu: true,
					id: id
				};
				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			} else {
				for (var i = 0; i < fakeArray.length; i++) {
					if (!fakeArray[i].checkAll) {
						let item = {
							checkAll: false,
							ilu: true,
							id: id
						};

						if (array.find((x) => x.id === id)) {
							array = array.map((x) => (x.id === id ? item : x));
						} else {
							array.push(item);
						}
						return;
					}
				}
				let item = {
					checkAll: true,
					ilu: false,
					id: id
				};
				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			}
			arrayTest = array;
		};
		for (var i = 0; i < functions.length; i++) {
			handleCheckLevel1True(functions[i].id);
		}
		setMainChecked(arrayTest);
	}, [checkedList]);

	const handleCheckLevel1 = (e: any, id: any) => {
		let lengthArray = [...functions].find((x) => x.id === id);
		let convertChecked = [...checkedList];
		if (e) {
			for (var i = 0; i < lengthArray.children.length; i++) {
				let value = [] as any;
				let listLabel = [] as any;
				for (var j = 0; j < lengthArray.children[i].children.length; j++) {
					const _item = lengthArray.children[i].children[j];
					if (_item.relative_funct_ids) {
						value = [...value, ..._item.relative_funct_ids.split(",").map((e: any) => +e)];
					}
					value.push(_item.value);
					listLabel.push(_item);
				}
				let item = {
					parent_id: id,
					id: lengthArray.children[i].id,
					value: value,
					listLabel: listLabel,
					checkAll: true,
					indeterminate: false
				};
				convertChecked = convertChecked.map((x) => (x.id === item.id ? item : x));
				if (!convertChecked.find((x) => x.id === item.id)) {
					convertChecked.push(item);
				}
				setCheckedList(convertChecked);
			}
			let item2 = {
				checkAll: true,
				ilu: false,
				id: id
			};
			if (mainChecked.find((x) => x.id === id)) {
				let fake = [...mainChecked].map((x) => (x.id === id ? item2 : x));
				setMainChecked(fake);
			} else {
				setMainChecked([...mainChecked, item2]);
			}
		} else {
			for (var i = 0; i < lengthArray.children.length; i++) {
				convertChecked = convertChecked.filter((x) => x.id !== lengthArray.children[i].id);
			}
			setCheckedList(convertChecked);
			setMainChecked(mainChecked.filter((x) => x.id !== id));
		}
	};
	/**************************** END **************************/

	return (
		<div className="contentSection">
			<Link to={`/roles`} style={{ cursor: "pointer" }}>
				<div className="flex items-center mb-5">
					<ArrowLeftOutlined className="-mt-[1px] text-[#222]" />
					<span className="ml-1 text-[#222]">Quay lại</span>
				</div>
			</Link>
			<Loading spinning={loading}>
				<div className="mainPages contentSection-filter">
					<Form
						name="formUpdate"
						layout="vertical"
						form={formUpdate}
						onFinish={btnCreatePrivilege}
						onFinishFailed={onFinishFailed}
					>
						{false ? (
							<div className="h-full w-full flex justify-center">
								<div style={{ marginTop: "20%" }}>
									<Spin />
								</div>
							</div>
						) : (
							<div className="contentSection" style={{ margin: "0" }}>
								<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
									<Typography.Title level={5}>Cấu hình phân quyền</Typography.Title>
									<div onClick={() => formUpdate.submit()} className="defaultButton border-none">
										<SvgIconStorage style={{ transform: "scale(0.7)" }} />
										&nbsp;Lưu
									</div>
								</div>

								<div
									style={{
										display: "flex",
										justifyContent: "space-between",

										marginTop: "4px"
									}}
								>
									<div
										style={{
											width: "calc(50% - 4px)"
										}}
									>
										{functions &&
											functions.length > 0 &&
											functions.map(
												(functionLevel1, index) =>
													index % 2 === 0 && (
														<div
															style={{
																width: "100%",
																marginBottom: "8px",

																borderRadius: "5px",
																overflow: "hidden",
																height: checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																	? "auto"
																	: "32px"
															}}
															key={geneUniqueKey()}
														>
															<div
																style={{
																	background: "rgb(212,212,212)",
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "space-between",
																	padding: "4px 8px "
																}}
															>
																<Checkbox
																	key={geneUniqueKey()}
																	indeterminate={mainChecked.find((x) => x.id === functionLevel1.id)?.ilu}
																	onChange={(e: any) => handleCheckLevel1(e.target.checked, functionLevel1?.id)}
																	checked={mainChecked.find((x) => x.id === functionLevel1.id)?.checkAll}
																>
																	<div
																		style={{
																			fontSize: "14px",
																			fontWeight: "600"
																		}}
																	>
																		{functionLevel1?.label}
																	</div>
																</Checkbox>
																<SvgIconMoveRight
																	className={`${
																		checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																			? "rotateIconActive"
																			: "rotateIcon"
																	}`}
																	onClick={() => {
																		let fakeCheckStatus = [...checkStatus];
																		fakeCheckStatus = fakeCheckStatus.map((x: any) =>
																			x.id === functionLevel1.id ? { ...x, status: !x.status } : x
																		);
																		setCheckStatus(fakeCheckStatus);
																	}}
																/>
															</div>
															<div
																className={`${
																	checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																		? "listContentActive"
																		: "listContent"
																}`}
															>
																{functionLevel1?.children &&
																	functionLevel1?.children.map((functionLevel2: any) => (
																		<div
																			key={geneUniqueKey()}
																			style={{ padding: "4px 8px " }}
																			className="listContentChild"
																		>
																			<Checkbox
																				key={geneUniqueKey()}
																				indeterminate={
																					checkedList.find((element) => element.id === functionLevel2?.id)
																						?.indeterminate
																				}
																				onChange={() => onCheckAllChange(functionLevel2?.id, functionLevel1.id)}
																				checked={
																					checkedList.find((element) => element.id === functionLevel2?.id)?.checkAll
																				}
																				className="checkboxUserGroup"
																			>
																				<div
																					key={geneUniqueKey()}
																					style={{
																						fontSize: "14px",
																						fontWeight: "500"
																					}}
																				>
																					{functionLevel2?.label}
																				</div>
																				{functionLevel2?.children?.length > 0 && (
																					<Checkbox.Group
																						className="endChild"
																						key={geneUniqueKey()}
																						options={functionLevel2?.children}
																						value={
																							checkedList.find((element) => element.id === functionLevel2?.id)?.value
																						}
																						onChange={(e: any) => onChange(e, functionLevel2?.id, functionLevel1?.id)}
																					/>
																				)}
																			</Checkbox>
																		</div>
																	))}
															</div>
														</div>
													)
											)}
									</div>
									<div
										style={{
											width: "calc(50% - 4px)"
										}}
									>
										{functions &&
											functions.length > 0 &&
											functions.map(
												(functionLevel1, index) =>
													index % 2 === 1 && (
														<div
															style={{
																width: "100%",
																marginBottom: "8px",

																borderRadius: "5px",
																overflow: "hidden",
																height: checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																	? "auto"
																	: "32px"
															}}
															key={geneUniqueKey()}
														>
															<div
																style={{
																	background: "rgb(212,212,212)",
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "space-between",
																	padding: "4px 8px "
																}}
															>
																<Checkbox
																	key={geneUniqueKey()}
																	indeterminate={mainChecked.find((x) => x.id === functionLevel1.id)?.ilu}
																	onChange={(e: any) => handleCheckLevel1(e.target.checked, functionLevel1?.id)}
																	checked={mainChecked.find((x) => x.id === functionLevel1.id)?.checkAll}
																>
																	<div
																		style={{
																			fontSize: "14px",
																			fontWeight: "600"
																		}}
																	>
																		{functionLevel1?.label}
																	</div>
																</Checkbox>
																<SvgIconMoveRight
																	className={`${
																		checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																			? "rotateIconActive"
																			: "rotateIcon"
																	}`}
																	onClick={() => {
																		let fakeCheckStatus = [...checkStatus];
																		fakeCheckStatus = fakeCheckStatus.map((x: any) =>
																			x.id === functionLevel1.id ? { ...x, status: !x.status } : x
																		);
																		setCheckStatus(fakeCheckStatus);
																	}}
																/>
															</div>
															<div
																className={`${
																	checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																		? "listContentActive"
																		: "listContent"
																}`}
															>
																{functionLevel1?.children &&
																	functionLevel1?.children.map((functionLevel2: any) => (
																		<div
																			key={geneUniqueKey()}
																			className="listContentChild"
																			style={{ padding: "4px 8px " }}
																		>
																			<Checkbox
																				key={geneUniqueKey()}
																				indeterminate={
																					checkedList.find((element) => element.id === functionLevel2?.id)
																						?.indeterminate
																				}
																				onChange={() => onCheckAllChange(functionLevel2?.id, functionLevel1.id)}
																				checked={
																					checkedList.find((element) => element.id === functionLevel2?.id)?.checkAll
																				}
																				className="checkboxUserGroup"
																			>
																				<div
																					key={geneUniqueKey()}
																					style={{
																						fontSize: "14px",
																						fontWeight: "500"
																					}}
																				>
																					{functionLevel2?.label}
																				</div>
																				{functionLevel2?.children?.length > 0 && (
																					<Checkbox.Group
																						className="endChild"
																						key={geneUniqueKey()}
																						options={functionLevel2?.children}
																						value={
																							checkedList.find((element) => element.id === functionLevel2?.id)?.value
																						}
																						onChange={(e: any) => onChange(e, functionLevel2?.id, functionLevel1?.id)}
																					/>
																				)}
																			</Checkbox>
																		</div>
																	))}
															</div>
														</div>
													)
											)}
									</div>
								</div>
							</div>
						)}
					</Form>
				</div>
			</Loading>
		</div>
	);
};

export default RoleDetail;
/* eslint-disable */
