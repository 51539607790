import { Button, Col, Form, Modal, Row, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import { PAGE_TYPE } from "src/constants";
import { getOrgs } from "src/services/actions/org.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";

const UserModal = ({ visible = true, detail = {} as any, onCancel = () => {}, onSuccess = () => {} }) => {
	const isCreate = detail?.id ? false : true;
	const [form] = Form.useForm();
	const [status, setStatus] = useState<boolean>(true);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [roles, setRoles] = useState<any>([]);
	const [userTypes, setUserTypes] = useState<any>([]);
	const isMount = useIsMount();
	const dispatch = useDispatch();
	// const { stateGetRoles } = useSelector((e: AppState) => e.rolesReducer);
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [users, setUsers] = useState<any>([]);

	useEffect(() => {
		if (stateGetUserTypes?.data) {
			setUserTypes(stateGetUserTypes?.data?.data);
		} else {
			dispatch(getUserTypes());
		}
		if (!stateGetOrgs?.data) {
			dispatch(getOrgs({}));
		}
	}, []); //eslint-disable-line
	// useEffect(() => {
	// 	if (isMount) return;
	// 	const { success, data, error } = stateGetRoles;
	// 	if (success) {
	// 		setRoles(data?.data);
	// 		return;
	// 	}
	// 	if (success === false || error) {
	// 		return notifyError(`${error}`);
	// 	}
	// }, [stateGetRoles.isLoading]); //eslint-disable-line
	// useEffect(() => {
	// 	if (isMount) return;
	// 	const { success, data, error } = stateGetUserTypes;
	// 	if (success) {
	// 		setUserTypes(data?.data);
	// 		return;
	// 	}
	// 	if (success === false || error) {
	// 		return notifyError(`${error}`);
	// 	}
	// }, [stateGetUserTypes.isLoading]); //eslint-disable-line
	// useEffect(() => {
	// 	const getUsers = async () => {
	// 		const res: any = await _apiNotSaga.getUsers({});
	// 		const _users = res?.data?.data.map((e: any) => ({
	// 			id: e.id,
	// 			name: `${e?.profile?.user_code} - ${e?.profile?.full_name}`
	// 		}));
	// 		setUsers(_users);
	// 	};
	// 	getUsers();
	// }, []);
	useEffect(() => {
		const init = async () => {
			try {
				// if (users.length === 0 || !stateGetOrgs?.data) return;
				// setLoading(true);
				// const res: any = await _apiNotSaga.userDetail(detail.id);
				// form.setFieldsValue({
				// 	full_name: res?.data?.profile?.full_name,
				// 	user_code: res?.data?.profile?.user_code,
				// 	role_id: res?.data?.role?.id,
				// 	phone: res?.data?.phone,
				// 	email: res?.data?.email,
				// 	user_type_id: res?.data?.profile?.user_type_id,
				// 	parent_id: res?.data?.profile?.parent_id ? res.data.profile.parent_id : undefined,
				// 	org_code: res?.data?.profile?.org_code
				// });
				// onGetRoles(res?.data?.profile?.user_type_id);
				// setStatus(res?.data?.status === "A");
				// setLock(res?.data?.locked === "Y");
				// setLoading(false);
				// if (users.length === 0 || !stateGetOrgs?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.pageDetail(detail.id);
				form.setFieldsValue({
					page_code: res?.data?.page_code,
					page_name: res?.data?.page_name,
					page_type: res?.data?.page_type == "1" ? "Trang bình thường" : "Landing page",
					url: res?.data?.url
				});
				// onGetRoles(res?.data?.profile?.user_type_id);
				setStatus(res?.data?.status === "A");
				// setLock(res?.data?.locked === "Y");
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		if (isCreate) {
			form.resetFields();
		} else {
			init();
		}
	}, [users.length, stateGetOrgs.isLoading]); //eslint-disable-line
	const checkUserCodeOnERP = async (e: any) => {
		const userCode = e.target.value.trim();
		if (!userCode) return;
		setLoading(true);
		try {
			const res: any = await _apiNotSaga.getUserByCode(userCode);
			setLoading(false);
			form.setFieldsValue({
				full_name: res?.data?.name,
				phone: res?.data?.phone,
				email: res?.data?.email,
				org_code: res?.data?.orgValue
			});
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const onChangeUserType = (val: number) => {
		form.setFieldsValue({ role_id: undefined });
		onGetRoles(val);
	};
	const onGetRoles = (val: number) => {
		_apiNotSaga
			.getRoles({ user_type_id: val, status: "A" })
			.then((res: any) => setRoles(res.data?.data))
			.catch((error) => notifyError(`${error}`));
	};
	const onSubmit = async (val: any) => {
		setLoading(true);
		const params = {
			...val,
			status: status ? "A" : "D"
			// locked: lock ? "Y" : "N",
			// org_name: stateGetOrgs?.data?.find((e: any) => e.value === val.org_code)?.name
		};
		try {
			const res: any = isCreate
				? await _apiNotSaga.createPage(params)
				: await _apiNotSaga.updatePage(detail.id, params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const deletePage = async (id: any) => {
		try {
			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;

			const res: any = await _apiNotSaga.deletePage(detail.id);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
			// history.push(`/banner-list`);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	return (
		<>
			<Modal
				open={visible}
				onCancel={onCancel}
				// maskClosable={false}
				title={`${isCreate ? "Tạo mới" : "Cập nhật"} người dùng`}
				style={{
					maxWidth: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div>
							{/* <div className="flex text-[13px] mb-4 md:mb-0">
								Khóa tài khoản &nbsp;
								<Switch disabled={loading} size="default" checked={lock} onChange={(e) => setLock(e)}></Switch>
							</div> */}
						</div>
						<Button
							disabled={loading}
							// htmlType="submit"
							// form="myForm"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
							onClick={deletePage}
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">Xóa trang</p>
						</Button>
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!isCreate ? "Cập nhật" : "Tạo mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12}>
								{/* <FormSelect
									name="user_type_id"
									label="Chọn site"
									dataSource={SITE_BASE}
									placeholder="Chọn site"
									slOption={{
										name: "name",
										value: "id"
									}}
									onChange={onChangeUserType}
									showSearch
									optionFilterProp="children"
									required
								/> */}
								<FormInput
									name="page_code"
									label="Mã Trang"
									type="text"
									size="large"
									placeholder="Nhập mã trang"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								{/* <FormSelect
									name="user_type_id"
									label="Trang hiển thị"
									dataSource={SITE_BASE}
									placeholder="Chọn site"
									slOption={{
										name: "name",
										value: "id"
									}}
									onChange={onChangeUserType}
									showSearch
									optionFilterProp="children"
									required
								/> */}
								<FormInput
									name="page_name"
									label="Tên trang"
									type="text"
									size="large"
									placeholder="Nhập tên trang"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								{/* <FormInput name="email" label="Email" type="email" size="large" placeholder="Nhập email" required /> */}
								<FormSelect
									name="page_type"
									label="Thể loại trang"
									dataSource={PAGE_TYPE}
									placeholder="Chọn thể loại page"
									slOption={{
										name: "name",
										value: "value"
									}}
									onChange={onChangeUserType}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormInput
									name="url"
									label="URL của trang"
									type="text"
									size="large"
									placeholder="Nhập URL của trang"
									required
									// disabled
								/>
							</Col>
							{/* <Col xs={24} md={12}>
								<FormSelect
									name="user_type_id"
									label="Loại người dùng"
									dataSource={userTypes}
									placeholder="Loại người dùng"
									slOption={{
										name: "user_type_name",
										value: "id"
									}}
									onChange={onChangeUserType}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="role_id"
									label="Nhóm vai trò"
									dataSource={roles}
									placeholder="Chọn vai trò"
									slOption={{
										name: "role_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="org_code"
									label="Chi nhánh"
									dataSource={stateGetOrgs?.data}
									placeholder="Chọn chi nhánh"
									slOption={{
										name: "name",
										value: "value"
									}}
									showSearch
									optionFilterProp="children"
									// required
									// disabled
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormSelect
									name="parent_id"
									label="Cấp quản lý"
									dataSource={users}
									placeholder="Chọn quản lý"
									slOption={{
										name: "name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
								/>
							</Col> */}
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default UserModal;
