import { Player } from '@lottiefiles/react-lottie-player';
import { SpinProps } from "antd";
// import SvgLogoLoading from "src/assets/svg/SvgLogoLoading";
import SvgIconBgLoading from "src/assets/svg/SvgIconBgLoading";
import './loading.css';
import loading from "./loading.json";

interface LoadingInterfaces extends SpinProps {}

const Loading = ({ children, ...props }: LoadingInterfaces) => {
	return (
		<div className="loading">
			{props.spinning && (
				<div className="overlay">
					<div className="background-loading">
						<SvgIconBgLoading />
						<Player
							autoplay
							loop
							src={loading}
							style={{ height: '100px', width: '100px' }}
							>
						</Player>
					</div>
				</div>
				// <div className="overlay">
				//  	<div className="logo">
				//  		<SvgLogoLoading />
				//  	</div>
				// </div>
			)}
			{children}
		</div>
	);
};

export default Loading;
