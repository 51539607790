import React, { useState, useEffect } from "react";
import { Affix, Button, Card, Col, Select, DatePicker, Form, Input, Row } from "antd";
import UpdaloadImage from "src/components/upload/UploadImage";
import { _apiNotSaga, uploadFile } from "src/services/api/apiNotSaga";
import { Link, useParams } from "react-router-dom";
import { AppState, RouteParams } from "src/types";
import { notifyError, notifySuccess } from "src/components/notification";
import { CND_URL, TUYENDUNG_URL } from "src/services/api/config";

const UploadImages = () => {
	const [fileListMeta, setFileListMeta] = useState<any>([]);
	const [fileListCover, setFileListCover] = useState<any>([]);
	const [coverFile, setCoverFile] = useState<any>([]);
	const [formCreate] = Form.useForm();
	const [imageUpload, setImageUpload] = useState<{ thumbnail: string; meta_image: string } | null>(null);
	const paramsURL = useParams<RouteParams>();
	const [isLoad, setIsLoad] = useState<boolean>(false);
	const [threadDetail, setThreadDetail] = useState<any>("");

	const [resetKey, setResetKey] = useState<number>(0);

	useEffect(() => {
		const initDetails = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				// setLoading(true);
				const res: any = await _apiNotSaga.postThreadDetails(paramsURL?.id);
				// setThreadDetail(res?.data[0]);
				const mobie: any =
					// res.data?.img !== ""
					// 	? [
					// 			{
					// 				url: `${CND_URL}/${res.data?.img}`,
					// 				isNotFile: true
					// 			}
					// 	  ]
					// 	: [];
					res.data.map((data: any) => ({
						id:`${data.image_id}`,
						url: `${CND_URL}/${data?.img}`,
						isNotFile: true
					}));

				setFileListCover(mobie);
				const mobies: any =
				res.data.map((data: any) => ({
					id:`${data.image_id}`,
					url: `${CND_URL}/${data?.img}`,
					isNotFile: true
				}));
				setFileListMeta(mobies);
				// setStateContent(res?.data?.content);
				
				formCreate.setFieldsValue({
					...res?.data[0]
					
				});
				
			} catch (error: any) {
				notifyError(error.message);
			}
		};
		initDetails();
		
	}, [paramsURL?.id, isLoad]);
	const uploadImgCover = (info: any) => {
		if (info.file) {
			let file = info.file;
			setCoverFile(file);
			uploadFile(file, (response: any) => {
				if (response) {
					// return formCreate.setFieldsValue({
					// 	thumbnail: response.data[0],
					// 	meta_image: response.data[0]
					// });
					// return setImageUpload({
					// 	thumbnail: response.data[0],
					// 	meta_image: response.data[0]
					// })
					formCreate.setFieldsValue({
						thumbnail: response.data[0],
						meta_image: response.data[0]
					});
					setImageUpload({
						thumbnail: response.data[0],
						meta_image: response.data[0]
					});
				}
			});
		}
	};

	const addImage = async () => {
		const _newParams = {
			thread_id: paramsURL?.id,
			img: fileListCover?.length > 0 ? imageUpload?.thumbnail : ""
		};
		try {
			const res: any = await _apiNotSaga.addImg(_newParams);
			// setLoading(true);
			setIsLoad(!isLoad);
			setResetKey(prevKey => prevKey + 1);
			notifySuccess(res.message);
			// onSuccess();
		
		} catch (error: any) {
			// setLoading(false);
			notifyError(error.message);
		}
	};

	return (
		<div>
			<Row gutter={[8, 8]}>
				<Col md={24} xs={24} sm={24}>
					<Form.Item name="thumbnail" label="Ảnh đã tải lên">
						<UpdaloadImage
							fileList={fileListMeta}
							className=""
							// setChangeFileList={setFileListCover}
							// uploadImg={uploadImg}
							// uploadImg={(info: any) => uploadImgCover(info)}
							maxCount={1}
							width={"100%"}
						/>
					</Form.Item>
				</Col>
				<Col md={24} xs={24} sm={24}>
					<Form.Item name="thumbnail" label="Upload hình ảnh">
						<UpdaloadImage
							// fileList={fileListCover}
							className=""
							key={resetKey}
							setChangeFileList={setFileListCover}
							// uploadImg={uploadImg}
							uploadImg={(info: any) => uploadImgCover(info)}
							maxCount={1}
							width={"100%"}
						/>
					</Form.Item>
					<button
						type="button"
						onClick={addImage}
						className="p-2 border-solid border-[1px] border-[#222222f8] rounded-[8px]"
					>
						Thêm ảnh
					</button>
				</Col>
				{/* <Col md={24} xs={24} sm={24}>
											<Form.Item
												name="public_at"
												// rules={[{ required: true, message: "Không được bỏ trống!" }]}
												label="Hẹn giờ lên bài"
											>
												<DatePicker
													showTime
													disabledDate={(current) => current && current <= moment()}
													placeholder="Hẹn giờ lên bài"
													format={"DD/MM/YYYY HH:mm:ss"}
													onChange={(val) => {}}
												/>
											</Form.Item>
										</Col> */}
				{/* <Col md={24} xs={24} sm={24}>
											<Form.Item name="meta_image" label="Meta Image">
												<UpdaloadImage
													fileList={fileListMeta}
													className=""
													setChangeFileList={setFileListCover}
													// uploadImg={uploadImg}
													uploadImg={(info: any) => uploadImgCover(info)}
													maxCount={3}
													width={"100%"}
												/>
											</Form.Item>
										</Col> */}
			</Row>
		</div>
	);
};

export default UploadImages;
