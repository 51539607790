import { Button, Col, DatePicker, Form, Modal, Row, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import UpdaloadImage from "src/components/upload/UploadImage";
import { dataGender } from "src/constants";
import { _apiNotSaga, uploadFile } from "src/services/api/apiNotSaga";
import { CND_URL, TUYENDUNG_URL } from "src/services/api/config";
import { dateFormatDMY } from "src/utils/helpers/functions/date";
import { isVietnamesecccd } from "src/utils/helpers/functions/textUtils";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { localGetToken } from "src/utils/localStorage";

const UserModal = ({ visible = true, detail = {} as any, onCancel = () => {}, onSuccess = () => {} }) => {
	const [fileListCover, setFileListCover] = useState<any>([]);
	const [fileListCardF, setFileListCardF] = useState<any>([]);
	const [fileListCardB, setFileListCardB] = useState<any>([]);
	const [form] = Form.useForm();
	const [status, setStatus] = useState<boolean>(true);
	const [messageApi, contextHolder] = message.useMessage();
	const [loading, setLoading] = useState<boolean>(false);

	const isMount = useIsMount();
	const dispatch = useDispatch();
	const [urlCover, setUrlCover] = useState<any>("");
	const [urlCardF, setUrlCardF] = useState<any>("");
	const [urlCardB, setUrlCardB] = useState<any>("");

	const [provinces, setProvinces] = useState<any>(undefined);
	const [districs, setDistrics] = useState<any>(undefined);
	const [districsTemp, setDistricsTemp] = useState<any>(undefined);
	const [wards, setWards] = useState<any>(undefined);
	const [wardsTemp, setWardsTemp] = useState<any>(undefined);
	useEffect(() => {
		const getProvince = async () => {
			const res: any = await _apiNotSaga.getProvinces({});

			setProvinces(res?.data);
		};
		getProvince();
	}, []); //eslint-disable-line
	useEffect(() => {
		if (detail) {
			form.setFieldsValue({
				...detail,
				gender:  detail.gender?.toString() ,
				identity_card_location: detail?.identity_card_location !== 0 ? detail?.identity_card_location : undefined,
				dob: detail?.dob ? moment(detail?.dob) : undefined,
				identity_card_date: detail?.identity_card_date ? moment(detail?.identity_card_date) : undefined,
				// province_id: detail?.province_id,
				// district_id: detail?.district_id,
				// ward_id: detail?.ward_id,
				temp_province_id: detail?.temp_province_id !== 0 ? detail?.temp_province_id : undefined,
				temp_district_id: detail?.temp_district_id !== 0 ? detail?.temp_district_id : undefined,
				temp_ward_id: detail?.temp_ward_id !== 0 ? detail?.temp_ward_id : undefined
				// temp_district_id: detail?.temp_district_id,
				// temp_ward_id: detail?.temp_ward_id,
				// identity_card_location: detail?.identity_card_location
			});
			const getProvince = async () => {
				const district: any = await _apiNotSaga.getDistricts({ province_id: detail?.province_id });
				if (district.success) {
					setDistrics(district.data);
				}
			};
			getProvince();
			if (detail?.temp_province_id) {
				const getProvinceTemp = async () => {
					const district: any = await _apiNotSaga.getDistricts({ province_id: detail?.temp_province_id });
					if (district.success) {
						setDistricsTemp(district.data);
					}
				};
				getProvinceTemp();
			}

			const getWard = async () => {
				const ward: any = await _apiNotSaga.getWards({ district_id: detail?.district_id });
				if (ward.success) {
					setWards(ward.data);
				}
			};
			getWard();

			if (detail?.temp_district_id) {
				const getWardTemp = async () => {
					const ward: any = await _apiNotSaga.getWards({ district_id: detail?.temp_district_id });
					if (ward.success) {
						setWardsTemp(ward.data);
					}
				};
				getWardTemp();
			}
			if (detail?.portrait_url) {
				setFileListCover([
					{
						uid: "-1",
						name: "image.png",
						status: "done",
						url: CND_URL + detail?.portrait_url
					}
				]);
			}
			if (detail?.identity_card_furl) {
				setFileListCardF([
					{
						uid: "-1",
						name: "image.png",
						status: "done",
						url: CND_URL + detail?.identity_card_furl
					}
				]);
			}
			if (detail?.identity_card_burl) {
				setFileListCardB([
					{
						uid: "-1",
						name: "image.png",
						status: "done",
						url: CND_URL + detail?.identity_card_burl
					}
				]);
			}

			setUrlCover(detail?.portrait_url);
			setUrlCardF(detail?.identity_card_furl);
			setUrlCardB(detail?.identity_card_burl);
		}
	}, [detail]);
	const onSubmit = async (val: any) => {
		// //console.log(val);\
		if(val?.identity_card_number && isVietnamesecccd(val?.identity_card_number) === false){
			return notifyError('Số CCCD không hợp lệ');
		}
		form
			.validateFields()
			.then(async () => {
				delete val.status;
				const params = {
					...val,
					dob: moment(val?.dob)?.format("YYYY-MM-DD"),
					identity_card_date: moment(val?.identity_card_date)?.format("YYYY-MM-DD"),
					ward_name: wards?.find((e: any) => e.id === val.ward_id)?.ward_name,
					district_name: districs?.find((e: any) => e.id === val.district_id)?.district_name,
					province_name: provinces?.find((e: any) => e.id === val.province_id)?.province_name,
					identity_card_location_name: provinces?.find((e: any) => e.id === val.identity_card_location)?.province_name,
					temp_ward_name: wardsTemp?.find((e: any) => e.id === val.temp_ward_id)?.ward_name,
					temp_district_name: districsTemp?.find((e: any) => e.id === val.temp_district_id)?.district_name,
					temp_province_name: provinces?.find((e: any) => e.id === val.temp_province_id)?.province_name,
					portrait_url: urlCover,
					identity_card_furl: urlCardF,
					identity_card_burl: urlCardB
				};
				//console.log(params);
				let params1 = {
					candidate_id: detail.candidate_id,
					candidate_status_id: 0,
					action_name: "Cập nhật thông tin cá nhân"
				};
				try {
					const res: any = await _apiNotSaga.updateProfileCandidate(detail.id, params);
					const res1: any = await _apiNotSaga.createCandidateLog(params1);
					setLoading(false);
					notifySuccess(res.message);
					onSuccess();
				} catch (error: any) {
					setLoading(false);
					notifyError(error.message);
				}
			})
			.catch(() => {
				return notifyError("Vui lòng nhập đầy đủ thông tin");
			});
	};

	const uploadImgCover = (info: any) => {
		//console.log(info);
		if (info.file) {
			let file = info.file;
			// setCoverFile(file);
			uploadFile(file, (response: any) => {
				if (response) {
					//console.log(response);
					setUrlCover(response.data[0]);
					// return formCreate.setFieldsValue({
					// 	meta_image: response.data[0]
					// });
				}
			});
		}
	};
	const uploadImgCardF = (info: any) => {
		if (info.file) {
			let file = info.file;
			// setCoverFile(file);
			uploadFile(file, (response: any) => {
				if (response) {
					//console.log(response);
					setUrlCardF(response.data[0]);
					// return formCreate.setFieldsValue({
					// 	meta_image: response.data[0]
					// });
				}
			});
		}
	};
	const uploadImgCardB = (info: any) => {
		if (info.file) {
			let file = info.file;
			// setCoverFile(file);
			uploadFile(file, (response: any) => {
				if (response) {
					setUrlCardB(response.data[0]);
					// return formCreate.setFieldsValue({
					// 	meta_image: response.data[0]
					// });
				}
			});
		}
	};
	const onchangeProvince = async (e: any) => {
		form.setFieldsValue({ district_id: undefined, ward_id: undefined });
		const district: any = await _apiNotSaga.getDistricts({ province_id: e });
		if (district.success) {
			setDistrics(district.data);
		}
	};
	const onchangeProvinceTemp = async (e: any) => {
		form.setFieldsValue({ temp_district_id: undefined, temp_ward_id: undefined });
		const district: any = await _apiNotSaga.getDistricts({ province_id: e });
		if (district.success) {
			setDistricsTemp(district.data);
		}
	};

	const onchangeDistrict = async (e: any) => {
		form.setFieldsValue({ ward_id: undefined });
		const ward: any = await _apiNotSaga.getWards({ district_id: e });
		if (ward.success) {
			setWards(ward.data);
		}
	};

	const onchangeDistrictTemp = async (e: any) => {
		form.setFieldsValue({ temp_ward_id: undefined });
		const ward: any = await _apiNotSaga.getWards({ district_id: e });
		if (ward.success) {
			setWardsTemp(ward.data);
		}
	};
	const onFinishFailed = (errorInfo: any) => {
		//console.log("Failed:", errorInfo);
	};

	return (
		<>
			<Modal
				open={visible}
				onCancel={onCancel}
				// maskClosable={false}
				width={900}
				title={`Cập nhật thông tin cá nhân`}
				style={{
					maxWidth: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8"></div>
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm"
							onClick={() => onSubmit(form.getFieldsValue())}
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">Lưu</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[16, 16]}>
							<Col xs={12} md={8}>
								<Form.Item name="portrait_url" label="Ảnh đại diện">
									<UpdaloadImage
										fileList={fileListCover}
										className=""
										setChangeFileList={setFileListCover}
										// uploadImg={uploadImg}
										uploadImg={(info: any) => uploadImgCover(info)}
										maxCount={1}
										width={"100%"}
									/>
								</Form.Item>
							</Col>
							<Col xs={12} md={8}>
								<Form.Item name="identity_card_furl" label="Ảnh CCCD trước">
									<UpdaloadImage
										fileList={fileListCardF}
										className=""
										setChangeFileList={setFileListCardF}
										// uploadImg={uploadImg}
										uploadImg={(info: any) => uploadImgCardF(info)}
										maxCount={1}
										width={"100%"}
									/>
								</Form.Item>
							</Col>
							<Col xs={12} md={8}>
								<Form.Item name="identity_card_burl" label="Ảnh CCCD sau">
									<UpdaloadImage
										fileList={fileListCardB}
										className=""
										setChangeFileList={setFileListCardB}
										// uploadImg={uploadImg}
										uploadImg={(info: any) => uploadImgCardB(info)}
										maxCount={1}
										width={"100%"}
									/>
								</Form.Item>
							</Col>
							<Col xs={12} md={8}>
								<FormInput
									name="candidate_name"
									label="Họ tên"
									type="text"
									size="large"
									placeholder="Họ tên"
									required
									// disabled
								/>
							</Col>
							<Col xs={12} md={8}>
								<FormInput
									name="candidate_phone"
									label="Số điện thoại"
									type="phone"
									size="large"
									placeholder="Số điện thoại"
									required
									// disabled
								/>
							</Col>
							<Col xs={12} md={8}>
								<FormInput
									name="candidate_email"
									label="Email"
									type="email"
									size="large"
									placeholder="Email"
									required
									// disabled
								/>
							</Col>
							<Col xs={24} md={8}>
								<FormSelect
									name="gender"
									label="Giới tính"
									dataSource={dataGender}
									placeholder="Chọn giới tính"
									slOption={{
										name: "name",
										value: "value"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>

							<Col xs={24} md={8}>
								<Form.Item name="dob" rules={[{ required: true, message: "Không được bỏ trống!" }]} label="Ngày sinh">
									<DatePicker
										// disabledDate={(current) => current && current <= moment().startOf("day")}
										placeholder="Ngày sinh"
										format={dateFormatDMY}
										onChange={(val) => {
											// const { routing_road_id, start_time_at } = createForm.getFieldsValue([
											// 	"routing_road_id",
											// 	"start_time_at"
											// ]);
											// loadRoutingRoadPlaces(routing_road_id, val, start_time_at);
										}}
									/>
								</Form.Item>
							</Col>
							<Col xs={12} md={8}>
								<FormInput
									name="identity_card_number"
									label="Số CCCD"
									type="cccd"
									size="large"
									placeholder="Số CCCD"
									// required
									// disabled
								/>
							</Col>
							<Col xs={24} md={8}>
								<Form.Item
									name="identity_card_date"
									// rules={[{ required: true, message: "Không được bỏ trống!" }]}
									label="Ngày cấp CCCD"
								>
									<DatePicker
										// disabledDate={(current) => current && current <= moment().startOf("day")}
										placeholder="Ngày cấp CCCD"
										format={dateFormatDMY}
										onChange={(val) => {
											// const { routing_road_id, start_time_at } = createForm.getFieldsValue([
											// 	"routing_road_id",
											// 	"start_time_at"
											// ]);
											// loadRoutingRoadPlaces(routing_road_id, val, start_time_at);
										}}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} md={8}>
								<FormSelect
									name="identity_card_location"
									label="Nơi cấp CCCD"
									dataSource={provinces}
									placeholder="Nơi cấp CCCD"
									slOption={{
										name: "province_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									// required
								/>
							</Col>
							<Col xs={24} md={8}></Col>
							<Col xs={24} md={24}>
								<h2 className="font-medium">Địa chỉ thường trú</h2>
							</Col>

							<Col xs={24} md={8}>
								<FormSelect
									name="province_id"
									label="Tỉnh thành"
									dataSource={provinces}
									placeholder="Tỉnh thành"
									slOption={{
										name: "province_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
									onChange={(value, e) => onchangeProvince(value)}
								/>
							</Col>
							<Col xs={24} md={8}>
								<FormSelect
									name="district_id"
									label="Quận huyện"
									dataSource={districs}
									placeholder="Quận huyện"
									slOption={{
										name: "district_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
									onChange={(value, e) => onchangeDistrict(value)}
								/>
							</Col>
							<Col xs={24} md={8}>
								<FormSelect
									name="ward_id"
									label="Phường xã"
									dataSource={wards}
									placeholder="Phường xã"
									slOption={{
										name: "ward_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
									// onChange={(value, e) => onchangeDistrict(value)}
								/>
							</Col>
							<Col xs={24} md={24}>
								<FormInput
									name="address"
									label="Địa chỉ"
									type="text"
									size="large"
									placeholder="Địa chỉ"
									required
									// disabled
								/>
							</Col>
							<Col xs={24} md={24}>
								<h2 className="font-medium">Địa chỉ tạm trú</h2>
							</Col>
							<Col xs={24} md={8}>
								<FormSelect
									name="temp_province_id"
									label="Tỉnh thành"
									dataSource={provinces}
									placeholder="Tỉnh thành"
									slOption={{
										name: "province_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
									onChange={(value, e) => onchangeProvinceTemp(value)}
								/>
							</Col>
							<Col xs={24} md={8}>
								<FormSelect
									name="temp_district_id"
									label="Quận huyện"
									dataSource={districsTemp}
									placeholder="Quận huyện"
									slOption={{
										name: "district_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
									onChange={(value, e) => onchangeDistrictTemp(value)}
								/>
							</Col>
							<Col xs={24} md={8}>
								<FormSelect
									name="temp_ward_id"
									label="Phường xã"
									dataSource={wardsTemp}
									placeholder="Phường xã"
									slOption={{
										name: "ward_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
									// onChange={(value, e) => onchangeDistrict(value)}
								/>
							</Col>
							<Col xs={24} md={24}>
								<FormInput
									name="temp_address"
									label="Địa chỉ"
									type="text"
									size="large"
									placeholder="Địa chỉ"
									required
									// disabled
								/>
							</Col>
							<Button
								onClick={() => {
									notifySuccess('Copy link thành công');
									navigator.clipboard.writeText(
										`${TUYENDUNG_URL}capnhatthongtin?id=${detail?.candidate_id}&token=${localGetToken()}`
									);
								}}
								type="link"
							>
								Copy Link cập nhật thông tin
							</Button>
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default UserModal;
