import { Reducer } from "redux";
import categoriesTypes from "../actions/types/categories.types";
import { ICategoryState } from "../interfaces/category.interfaces";

import { ISyntheticAction } from "../interfaces/root.interfaces";
import { rootState } from "./state/root.states";

const initState: ICategoryState = {
	stateGetCategories: { ...rootState },
	stateGetCategoriesDetail: { ...rootState },
	stateGetCategoriesDetailChildren:{ ...rootState },
};

const categoryReducer: Reducer<ICategoryState, ISyntheticAction> = (
	state: ICategoryState = initState,
	action: ISyntheticAction
) => {
	const { type, response } = action;

	switch (type) {
		/*************************** START *************************/
		/*                       LOGIN ACCOUNT                     */

		case categoriesTypes.START_GET_LIST_CATEGORY: {
			const stateReducer: ICategoryState = {
				...state,
				stateGetCategories: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case categoriesTypes.GET_LIST_CATEGORY_SUCCESS: {
			const stateReducer: ICategoryState = {
				...state,
				stateGetCategories: {
					...rootState,
					isLoading: false,
					...response
					// data: response.data,
					// message: response.message,
					// success: response.success
				}
			};
			return stateReducer;
		}
		case categoriesTypes.GET_LIST_CATEGORY_FAILURE: {
			const stateReducer: ICategoryState = {
				...state,
				stateGetCategories: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}

		/**************************** END **************************/
		case categoriesTypes.START_GET_CATEGORY_BY_ID: {
			const stateReducer: ICategoryState = {
				...state,
				stateGetCategoriesDetail: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case categoriesTypes.GET_CATEGORY_BY_ID_SUCCESS: {
			const stateReducer: ICategoryState = {
				...state,
				stateGetCategoriesDetail: {
					...rootState,
					...response,
					isLoading: false,
					// data: response.data,
					// message: response.message,
					// success: response.success
				}
			};
			return stateReducer;
		}
		case categoriesTypes.GET_CATEGORY_BY_ID_FAILURE: {
			const stateReducer: ICategoryState = {
				...state,
				stateGetCategoriesDetail: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		///////////////////////////////////////////////////////////
		case categoriesTypes.START_GET_CATEGORY_BY_ID_CHILDREN: {
			const stateReducer: ICategoryState = {
				...state,
				stateGetCategoriesDetailChildren: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case categoriesTypes.GET_CATEGORY_BY_ID_CHILDREN_SUCCESS: {
			const stateReducer: ICategoryState = {
				...state,
				stateGetCategoriesDetailChildren: {
					...rootState,
					isLoading: false,
					data: response.data,
					message: response.message,
					success: response.success
				}
			};
			return stateReducer;
		}
		case categoriesTypes.GET_CATEGORY_BY_ID_CHILDREN_FAILURE: {
			const stateReducer: ICategoryState = {
				...state,
				stateGetCategoriesDetailChildren: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}
		default:
			return state;
	}
};

export default categoryReducer;
