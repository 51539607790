/*eslint-disable*/
import { Col, Form, Modal, Row, Switch, UploadFile } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import { notifyError, notifySuccess } from "src/components/notification";
import UpdaloadImage from "src/components/upload/UploadImage";
import { getModuleFunctDetail } from "src/services/actions/mouduleFunct.actioms";
import { getListPriceOffType, getPriceOffTypeById } from "src/services/actions/priceOffType.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { CND_URL } from "src/services/api/config";
import { AppState } from "src/types";
import colors from "src/utils/colors";
import { _paramsFilter } from "./data";
export const defaultStyles = {
	background: colors.neutral_color_1_8,
	height: "41px",
	border: `1px solid ${colors.neutral_color_1_5}`,
	borderRadius: "5px",
	padding: "0 12px"
};
const CreateModuleFuct = ({ visible, onCancel, setOpenTransportModal, form, isEdit, setIsEdit, data,setCallback }: any) => {
	const dispatch = useDispatch();
	const [statusId, setStatusId] = useState<boolean>(true);
	const [dataDetail, setDataDetail] = useState<any>({});
	const [fileListStart, setFileListStart] = useState<UploadFile[]>([]);
	const [fileListEnd, setFileListEnd] = useState<UploadFile[]>([]);
	const [fileListMobie, setFileListMobie] = useState<UploadFile[]>([]);
	const { stateGetModuleFunctDetail } = useSelector((state: AppState) => state.moduleFunctReducer);
	
	useEffect(() => {
		if (isEdit && data.id) {
			dispatch(getModuleFunctDetail(data.id));
		}
	}, [data]);
	useEffect(() => {
		form.setFieldsValue({
			icon_start_svg: fileListStart[0],
			activate_icon_end_svg: fileListEnd[0],
			icon_mobie:fileListMobie[0]

		});
	}, [fileListStart, fileListEnd,fileListMobie, form]);
	useEffect(() => {
		if (stateGetModuleFunctDetail.success && data.id) {
			const { success, data, message, error, isLoading } = stateGetModuleFunctDetail;
			if (success) {
				setStatusId(data.status === "A" ? true : false);
				const start:any=data?.ui_icon!=="undefined"?[{
					url: `${CND_URL}/${data?.ui_icon}`,
					isNotFile: true
				}]:[]
				setFileListStart(start);
				const end:any=data?.active_icon!=="undefined"?[{
					url: `${CND_URL}/${data?.active_icon}`,
					isNotFile: true
				}]:[]
				setFileListEnd(end);
				const mobie:any=data?.mobile_icon!=="undefined"?[{
					url: `${CND_URL}/${data?.mobile_icon}`,
					isNotFile: true
				}]:[]
				setFileListMobie(mobie);
				form.setFieldsValue({
					...data
					// funct_code: data?.funct_code,
					// funct_name: data?.funct_name,
					// ui_route: data?.ui_route,
					// mobile_route:data?.mobile_route
				});
				setDataDetail(data);
			}
		}
	}, [stateGetModuleFunctDetail]);
	const handleOpenTransportModal = () => {
		setIsEdit(false);
		setOpenTransportModal(true);
	};
	const renameFile = (originalFile: any, newName: string) => {
		return new File([originalFile], newName, {
			type: originalFile?.type,
			lastModified: originalFile?.lastModified
		});
	};
	const onSubmit = async ({ icon_start_svg, activate_icon_end_svg,icon_mobie, ...val }: any) => {
		let files: any = [];
		if (icon_start_svg && !icon_start_svg?.isNotFile)
			files.push(renameFile(icon_start_svg, `_ICON_START_SVG.${icon_start_svg.name.split('.').pop()}`));
		if (activate_icon_end_svg && !activate_icon_end_svg?.isNotFile)
			files.push(renameFile(activate_icon_end_svg, `_ACTIVATE_ICON_END_SVG.${activate_icon_end_svg.name.split('.').pop()}`));
		if (icon_mobie && !icon_mobie?.isNotFile)
			files.push(renameFile(icon_mobie, `_ICON_MOBIE_SVG.${icon_mobie.name.split('.').pop()}`));
		let params = { ...val, status: statusId ? "A" : "D", method: "GET", index: dataDetail?.index };
		
		if (!isEdit) {
			console.log(params);
			let paramss = {
				...params,
				parent_id: 0
			};
			_apiNotSaga
				.createModuleFunct(paramss)
				.then((res: any) => {
					if (res.success) {
						notifySuccess("Tạo mới thành công");
						setCallback((pre: boolean) => !pre);
						onCancel();
					}
				})
				.catch((err) => {
					notifyError(err.message);
				});
		} else {
			const delete_files: any = [];
			if(!fileListStart.length){
				delete_files.push('ICON_START_SVG')
			}
			if(!fileListEnd.length){
				delete_files.push('ACTIVATE_ICON_END_SVG')
			}
			if(!fileListMobie.length){
				delete_files.push('ICON_MOBIE_SVG')
			}
			_apiNotSaga
				.updateModuleFunct(data.id, {...params,delete_files:delete_files.join(',')})
				.then((res: any) => {
					if (res.success) {
						notifySuccess("Cập nhật thành công");
						setCallback((pre: boolean) => !pre);
						onCancel();
					}
				})
				.catch((err) => {
					notifyError(err.message);
				});
		}
	};
	const uploadImg = () => {};
	return (
		<Modal
			centered
			title={isEdit ? "Chi tiết" : "Tạo mới"}
			open={visible}
			onCancel={onCancel}
			width={"50%"}
			// maskClosable={false}
			style={{ fontSize: 20 }}
			footer={
				<div className="addStores__footer">
					<div className="flex text-[13px]">
						Hoạt động &nbsp;
						<Switch size="default" checked={statusId} onChange={() => setStatusId(!statusId)}></Switch>
					</div>
					<div style={{ display: "flex", alignItems: "center" }}></div>&nbsp;
					<button type="submit" form="myForm" className="addStores__footer__btn editBtn">
						<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">Lưu</p>
					</button>
				</div>
			}
		>
			<Form name="myForm" layout="vertical" form={form} onFinish={onSubmit}>
				<div className={`bg-white w-full mb-4`}>
					<Row className="w-full" gutter={[14, 14]}>
						<Col md={12} xs={24} sm={24}>
							<FormInput
								name="funct_name"
								label="Display name"
								required={true}
								type="numberAndText"
								size="large"
								placeholder="Nhập tên"
								classItem="mb-0"
							/>
						</Col>
						<Col md={12} xs={24} sm={24}>
							<FormInput
								name="funct_code"
								label="Function code (theo module)"
								required={true}
								type="text"
								size="large"
								placeholder="Nhập function code"
								classItem="mb-0"
							/>
						</Col>
						<Col md={12} xs={24} sm={24}>
							<FormInput
								name="ui_route"
								label="UI Route"
								required={true}
								type="text"
								size="large"
								placeholder="Nhập Ui route"
								classItem="mb-0"
							/>
						</Col>
						<Col md={12} xs={24} sm={24}>
							<FormInput
								name="api_route"
								label="API Route"
								required={true}
								type="text"
								size="large"
								placeholder="Nhập API route"
								classItem="mb-0"
							/>
						</Col>
					</Row>
					{/* <Row className="w-full" gutter={[14, 14]}>
						<Col md={6} xs={24} sm={24}>
							<Form.Item name="icon_start_svg" label="Icon">
								<UpdaloadImage
									fileList={fileListStart}
									className="icon2Upload"
									setChangeFileList={setFileListStart}
									uploadImg={uploadImg}
									maxCount={1}
									width={100}
								/>
							</Form.Item>
						</Col>
						<Col md={6} xs={24} sm={24}>
							<Form.Item name="activate_icon_end_svg" label="Activate icon">
								<UpdaloadImage
									fileList={fileListEnd}
									id="files"
									className="icon2Upload"
									setChangeFileList={setFileListEnd}
									uploadImg={uploadImg}
									maxCount={1}
								/>
							</Form.Item>
						</Col>
						<Col md={12} xs={24} sm={24}>
							<Form.Item name="icon_mobie" label="Icon Mobile">
								<UpdaloadImage
									fileList={fileListMobie}
									id="files"
									className="iconMoudle"
									setChangeFileList={setFileListMobie}
									uploadImg={uploadImg}
									maxCount={1}
								/>
							</Form.Item>
						</Col>
					</Row> */}
				</div>
			</Form>
		</Modal>
	);
};

export default CreateModuleFuct;
