const globalTypes = {
  CHANGE_THEME: 'CHANGE_THEME',
  OTHER_ACTION: 'OTHER_ACTION',
  START_PUT_SIGN_IN_APP: 'START_PUT_SIGN_IN_APP',
  PUT_SIGN_IN_APP_SUCCESS: 'PUT_SIGN_IN_APP_SUCCESS',
  START_GET_ENUMS: 'START_GET_ENUMS',
  GET_ENUMS_SUCCESS: 'GET_ENUMS_SUCCESS',
  GET_ENUMS_FAILURE: 'GET_ENUMS_FAILURE',
  START_GET_PROVINCES: 'START_GET_PROVINCES',
  START_PUT_ACTIONS: 'START_PUT_ACTIONS',
  PUT_ACTIONS_SUCCESS: 'PUT_ACTIONS_SUCCESS',
  GET_PROVINCES_SUCCESS: 'GET_PROVINCES_SUCCESS',
  GET_PROVINCES_FAILURE: 'GET_PROVINCES_FAILURE',
}

export default globalTypes;