/*eslint-disable */

import { ArrowLeftOutlined } from "@ant-design/icons";
import { Affix, Button, Card, Col, Select, DatePicker, Checkbox, Spin, Typography, Form, Input, Row } from "antd";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import { notifyError, notifySuccess } from "src/components/notification";
// import UpdaloadImage from "src/components/upload/UploadImage";
import { _apiNotSaga, uploadFile } from "src/services/api/apiNotSaga";
import { CND_URL, TUYENDUNG_URL } from "src/services/api/config";
import { AppState, RouteParams } from "src/types";
import { convertToSlug } from "src/utils/helpers/functions/rules";
import { useHistory } from "react-router-dom";
import DDEditor from "src/components/editor/index";
import UploadImages from "./UploadImages";

import { getAttribute } from "src/services/actions/attribute.actions";
import { useDispatch, useSelector } from "react-redux";
import { geneUniqueKey } from "src/utils/helpers/functions/textUtils";
import SvgIconMoveRight from "src/assets/svg/SvgIconMoveRight";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { getMessageV1 } from "src/utils/helpers/functions/getMessage";

const diacritics = require("diacritics");
import Loading from "src/components/loading/Loading";

interface Province {
	id: number;
	province_name: string;
}

interface District {
	id: number;
	district_id: number;
	district_name: string;
	province_id: number;
}

interface Ward {
	id: number;
	district_id: number;
	ward_name: string;
	ward_id: number;
}

// interface phần danh mục
interface CategoryArray {
	id: number;
	category_name: string;
	children?: CategoryArray[];
}

interface FlattenedCategory {
	id: number;
	title: string;
}

const flattenCategories = (dataCategory: CategoryArray[]): FlattenedCategory[] => {
	const flattened: FlattenedCategory[] = [];

	const flatten = (category: CategoryArray) => {
		flattened.push({ id: category.id, title: category.category_name });
		if (category.children) {
			category.children.forEach((child) => flatten(child));
		}
	};

	dataCategory.forEach((category) => flatten(category));

	return flattened;
};

const DetailsNews = (props: any) => {
	let history = useHistory();
	const paramsURL = useParams<RouteParams>();
	const formatTime = "HH:mm";

	const [formCreate] = Form.useForm();
	const editorRef = useRef<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [dataPostType, setDataPostType] = useState<any>([]);
	const [fileListCover, setFileListCover] = useState<any>([]);

	const [fileListMeta, setFileListMeta] = useState<any>([]);

	const [stateContent, setStateContent] = useState<any>("");
	const [statusNews, setStatusNews] = useState<any>("");
	const [isLoad, setIsLoad] = useState<boolean>(false);
	const [metaslug, setMetaslug] = useState<any>("");
	const [userDetail, setUserDetail] = useState<any>("");

	const [threadDetail, setThreadDetail] = useState<any>("");

	const [dataDistricts, setDataDistricts] = useState<District[]>([]);
	const [dataProvinces, setDataProvinces] = useState<Province[]>([]);
	const [dataWards, setDataWards] = useState<Ward[]>([]);

	const [selectedProvince, setSelectedProvince] = useState<number | null>(null);
	const [selectedDistrict, setSelectedDistrict] = useState<number | null>(null);
	const [selectedWard, setSelectedWard] = useState<number | null>(null);
	const [filteredDistricts, setFilteredDistricts] = useState<District[]>([]);
	const [filteredWards, setFilteredWards] = useState<Ward[]>([]);

	const [total, setTotal] = useState(0);
	const [imageUpload, setImageUpload] = useState<{ thumbnail: string; meta_image: string } | null>(null);

	const [dataCategory, setDataCategory] = useState<CategoryArray[]>([]);
	const [flattenedCategories, setFlattenedCategories] = useState<FlattenedCategory[]>([]);

	// ----------------select attribute-------------
	const [functions, setFunctions] = useState<any[]>([]);
	const { stateGetAttribute } = useSelector((e: AppState) => e.attributeReducer);
	const dispatch = useDispatch();
	const [checkStatus, setCheckStatus] = useState<any[]>([]);
	const [formUpdate] = Form.useForm();
	const [mainChecked, setMainChecked] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const isMount = useIsMount();
	const [stateAttributeThread, setStateAttributeThread] = useState<any>([]);

	const [showMore, setShowMore] = useState<boolean>(false);

	// Lấy thông tin user
	useEffect(() => {
		const init = async () => {
			try {
				const userData = await localStorage.getItem("ACCOUNT");
				if (userData) {
					setUserDetail(JSON.parse(userData).user);
				}
				setLoading(false);
				// setIsUserDataLoaded(true); // Indicate that user data has been loaded
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, []);
	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getListCategory({});
				setDataCategory(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, []);

	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getPostType({});
				setDataPostType(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
		const initDetails = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.postThreadDetails(paramsURL?.id);
				setThreadDetail(res?.data[0]);
				const mobie: any =
					// res.data?.img !== ""
					// 	? [
					// 			{
					// 				url: `${CND_URL}/${res.data?.img}`,
					// 				isNotFile: true
					// 			}
					// 	  ]
					// 	: [];
					res.data.map((data: any) => ({
						id: `${data.image_id}`,
						url: `${CND_URL}/${data?.img}`,
						isNotFile: true
					}));

				setFileListCover(mobie);
				const mobies: any = res.data.map((data: any) => ({
					id: `${data.image_id}`,
					url: `${CND_URL}/${data?.img}`,
					isNotFile: true
				}));
				setFileListMeta(mobies);
				// setStateContent(res?.data?.content);
				setStateContent(res?.data[0]?.short_desc);
				setStatusNews(res?.data?.status);
				setMetaslug(res?.data?.slug);
				setSelectedProvince(res?.data[0].province_id);
				setSelectedDistrict(res?.data[0].district_id);
				setSelectedWard(res?.data[0].ward_id);
				formCreate.setFieldsValue({
					...res?.data[0]
					// public_at: res?.data?.public_at ? moment(moment(res?.data?.public_at), "DD/MM/YYYY HH:mm:ss") : null
				});
				// setDataPostType(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initDetails();
	}, [paramsURL?.id, isLoad]);

	// Lấy thông tin danh mục

	useEffect(() => {
		setFlattenedCategories(flattenCategories(dataCategory));
	}, [dataCategory]);

	// --------------------------------------------------------------
	// Lấy thông tin địa điểm, tỉnh thành
	useEffect(() => {
		const initu = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getDistricts({ limit: 100 });
				setDataDistricts(res?.districtList);
				setTotal(res?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initu();
	}, []);

	useEffect(() => {
		const initu = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const resProvinces: any = await _apiNotSaga.getProvinces({ limit: 100 });
				setDataProvinces(resProvinces?.provinceList);
				setTotal(resProvinces?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initu();
	}, []);

	useEffect(() => {
		const initu = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const resWards: any = await _apiNotSaga.getWards({ limit: 100 });
				setDataWards(resWards?.wardList);
				setTotal(resWards?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initu();
	}, []);

	useEffect(() => {
		if (selectedProvince !== null) {
			const filtered = dataDistricts.filter((district) => district.province_id === selectedProvince);
			setFilteredDistricts(filtered);
			// setSelectedDistrict(null); // Reset selected district when province changes
			setFilteredWards([]); // Clear wards when province changes
		} else if (threadDetail.district_id !== null) {
			const filtered = dataDistricts.filter((district) => district.id === threadDetail.district_id);
			setFilteredDistricts(filtered);
		} else {
			setFilteredDistricts([]);
			setFilteredWards([]);
		}
	}, [selectedProvince, dataDistricts]);

	useEffect(() => {
		if (selectedDistrict !== null) {
			const filtered = dataWards.filter((ward) => ward.district_id === selectedDistrict);
			setFilteredWards(filtered);
		} else if (selectedDistrict === null && threadDetail.ward_id !== null) {
			const filtered = dataWards.filter((ward) => ward.id === threadDetail.ward_id);
			setFilteredWards(filtered);
		} else {
			setFilteredWards([]);
		}
	}, [selectedDistrict, dataWards]);
	//------------------------------------------------------------------------
	// Phần select thuộc tính của thread
	// Lấy danh sách thuộc tính của thread
	useEffect(() => {
		dispatch(getAttribute({}));
	}, []);

	useEffect(() => {
		const init = async () => {
			try {
				setLoading(true);
				const res: any = await _apiNotSaga.getListAttributeThreadDetail(paramsURL.id);
				setStateAttributeThread(res?.data);
				// setToal(res?.paging?.total);
				setLoading(false);
				// setIsDataLoaded(true);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, []); //eslint-disable-line

	useEffect(() => {
		if (isMount) return;
		const { data, message, success, error, isLoading } = stateGetAttribute;

		setLoading(isLoading || false);
		if (success) {
			let dataFunctions = data;
			let fakeFunctions = [...dataFunctions?.sort((a: any, b: any) => b?.children.length - a?.children.length)];
			let convertArray = [];
			let fakeCheckStatus = [];

			for (var i = 0; i < fakeFunctions?.length; i++) {
				fakeCheckStatus.push({
					id: fakeFunctions[i]?.id,
					status: true
				});
				let convertLevel1 = {
					children: [] as any,
					value: fakeFunctions[i]?.id,
					label: fakeFunctions[i]?.attribute_name,
					id: fakeFunctions[i]?.id
				};
				for (var j = 0; j < fakeFunctions[i]?.children?.length; j++) {
					let convertLevel2 = {
						children: [] as any,
						value: fakeFunctions[i]?.children[j]?.attribute_id,
						label: fakeFunctions[i]?.children[j]?.attribute_value,
						id: fakeFunctions[i]?.children[j]?.id
					};
					for (var k = 0; k < fakeFunctions[i]?.children[j]?.children?.length; k++) {
						convertLevel2.children.push({
							value: fakeFunctions[i]?.children[j]?.children[k]?.attribute_id,
							label: fakeFunctions[i]?.children[j]?.children[k]?.attribute_value,
							relative_funct_ids: fakeFunctions[i]?.children[j]?.children[k]?.relative_funct_ids
						});
					}

					convertLevel1.children.push(convertLevel2);
				}
				convertArray.push(convertLevel1);
			}
			setCheckStatus(fakeCheckStatus);
			setFunctions(convertArray);
		}
		if (success === false || error) {
			let msg = getMessageV1(message, ", ");
			return notifyError(msg);
		}
	}, [stateGetAttribute.isLoading]);

	// Checkbox thread dùng cho chức năng chỉnh sửa
	useEffect(() => {
		if (isMount) return;
		if (functions.length === 0) return;
		setLoading(false);
		if (stateAttributeThread?.length > 0) {
			let arrayChecked = [] as any;

			stateAttributeThread.forEach((thread: { val: string; label: any; id: any }) => {
				const normalizedThreadVal = thread.val ? diacritics.remove(thread.val.trim().toLowerCase()) : "";

				functions.forEach((func) => {
					const normalizedFuncLabel = func.label ? diacritics.remove(func.label.trim().toLowerCase()) : "";

					if (normalizedThreadVal === normalizedFuncLabel && func.children.length === 0) {
						if (
							!mainChecked.some(
								(x) =>
									diacritics.remove((x.val || "").trim().toLowerCase()) ===
									diacritics.remove((thread.label || "").trim().toLowerCase())
							)
						) {
							setMainChecked((prevMainChecked) => [
								...prevMainChecked,
								{
									id: thread.id,
									checkAll: true,
									ilu: false
								}
							]);
						}
					}

					func.children.forEach((child: { label: any; children: any[]; id: any }) => {
						const normalizedChildLabel = child.label ? diacritics.remove(child.label.trim().toLowerCase()) : "";

						if (normalizedThreadVal === normalizedChildLabel && child.children.length === 0) {
							const foundItem = arrayChecked.find(
								(x: any) => diacritics.remove((x.val || "").trim().toLowerCase()) === normalizedChildLabel
							);

							if (foundItem) {
								foundItem.listLabel = [];
								foundItem.value = [];
								foundItem.indeterminate = false;
								foundItem.checkAll = true;
							} else {
								arrayChecked.push({
									checkAll: true,
									id: child.id,
									thread_id: paramsURL.id,
									label: child.label,
									indeterminate: false,
									listLabel: [],
									parent_id: func.id,
									value: []
								});
							}
						}

						child.children.forEach((grandchild: { label: any }) => {
							const normalizedGrandchildLabel = grandchild.label
								? diacritics.remove(grandchild.label.trim().toLowerCase())
								: "";

							if (normalizedThreadVal === normalizedGrandchildLabel) {
								const foundItem = arrayChecked.find(
									(x: any) => diacritics.remove((x.val || "").trim().toLowerCase()) === normalizedChildLabel
								);

								if (foundItem) {
									foundItem.listLabel.push(grandchild);
									foundItem.value.push(thread.val);
									foundItem.indeterminate =
										child.children.length > foundItem.value.length && foundItem.value.length > 0;
									foundItem.checkAll = child.children.length === foundItem.value.length;
								} else {
									arrayChecked.push({
										checkAll: child.children.length === 1,
										id: child.id,
										label: child.label,
										indeterminate: child.children.length !== 1,
										listLabel: [grandchild],
										parent_id: func.id,
										value: [thread.val]
									});
								}
							}
						});
					});
				});
			});

			setCheckedList(arrayChecked);
		}
	}, [stateAttributeThread, functions]);

	const onCheckAllChange = (id: any, parent_id: any, label: any) => {
		let fakeCate = [] as any;
		let listHaveValue = [] as any[];
		let listCate = functions
			?.find((a) => a.children.find((x: any) => x.id === id))
			?.children?.find((x: any) => x.id === id)?.children;
		for (var c = 0; c < listCate.length; c++) {
			if (listCate[c].label) {
				fakeCate = [...fakeCate, ...listCate[c].label.split(",").map((e: any) => +e)];
			}
			fakeCate.push(listCate[c].value);
			listHaveValue.push(listCate[c]);
		}

		for (var i = 0; i < checkedList.length; i++) {
			if (checkedList[i].id === id) {
				let fakeList = [...checkedList];
				setCheckedList(
					fakeList.map((obj) =>
						obj.id === id
							? obj.checkAll === false
								? {
										parent_id: parent_id,
										label: label,
										id: id,
										value: fakeCate,
										listLabel: listHaveValue,
										thread_id: paramsURL.id,
										checkAll: true,
										indeterminate: false
								  }
								: {
										parent_id: parent_id,
										label: label,
										thread_id: paramsURL.id,
										id: id,
										value: [],
										checkAll: false,
										indeterminate: false
								  }
							: obj
					)
				);
				return;
			} else {
				continue;
			}
		}
		setCheckedList([
			...checkedList,
			{
				parent_id: parent_id,
				thread_id: paramsURL.id,
				id: id,
				value: fakeCate,
				label: label,
				listLabel: listHaveValue,
				checkAll: true,
				indeterminate: false
			}
		]);
	};
	const onChange = (list: any, id: any, parent_id: any) => {
		let listCate = functions
			?.find((a) => a.children.find((x: any) => x.id === id))
			?.children?.find((x: any) => x.value === id)?.children;
		let listHaveValue = [] as any[];
		let _list = [] as any[];
		for (var x = 0; x < list.length; x++) {
			const _find = listCate?.find((cate: any) => cate.value === list[x]);
			listHaveValue.push(_find);
			if (_find.label) {
				_list = [..._list, ..._find.label.split(",").map((e: any) => +e)];
			}
			_list.push(_find.value);
		}
		for (var i = 0; i < checkedList.length; i++) {
			if (checkedList[i].id === id) {
				let fakeList = [...checkedList];
				setCheckedList(
					fakeList.map((obj) =>
						obj.id === id
							? {
									parent_id: parent_id,

									id: id,
									value: _list,
									listLabel: listHaveValue,
									thread_id: paramsURL.id,

									checkAll: list.length === listCate.length,
									indeterminate: !!list.length && list.length < listCate.length
							  }
							: obj
					)
				);
				return;
			} else {
				continue;
			}
		}

		setCheckedList([
			...checkedList,
			{
				parent_id: parent_id,
				thread_id: paramsURL.id,
				id: id,
				value: _list,
				listLabel: listHaveValue,

				checkAll: list.length === listCate.length,
				indeterminate: !!list.length && list.length < listCate.length
			}
		]);
	};
	useEffect(() => {
		let arrayTest = [...mainChecked];
		const handleCheckLevel1True = (id: any) => {
			let fakeArray = [...checkedList].filter((x) => x.parent_id === id);
			let lengthArray = [...functions].find((x) => x.id === id);
			let array = arrayTest;

			if (!fakeArray.some((x) => x.checkAll === true) && !fakeArray.some((x) => x.indeterminate === true)) {
				let item = {
					checkAll: false,
					ilu: false,
					id: id
				};
				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			} else if (fakeArray.some((x) => x.indeterminate === true)) {
				let item = {
					checkAll: false,
					ilu: true,
					id: id
				};
				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			} else if (
				(fakeArray && fakeArray.length === 0) ||
				(fakeArray.length === 1 && fakeArray[0].value.length === 0 && !fakeArray[0].checkAll)
			) {
				let item = {
					checkAll: false,
					ilu: false,
					id: id
				};

				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			} else if (
				fakeArray.filter((x) => x.checkAll === false).length > 0 ||
				fakeArray.length < lengthArray?.children?.length
			) {
				let item = {
					checkAll: false,
					ilu: true,
					id: id
				};
				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			} else {
				for (var i = 0; i < fakeArray.length; i++) {
					if (!fakeArray[i].checkAll) {
						let item = {
							checkAll: false,
							ilu: true,
							id: id
						};

						if (array.find((x) => x.id === id)) {
							array = array.map((x) => (x.id === id ? item : x));
						} else {
							array.push(item);
						}
						return;
					}
				}
				let item = {
					checkAll: true,
					ilu: false,
					id: id
				};
				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			}
			arrayTest = array;
		};
		for (var i = 0; i < functions.length; i++) {
			handleCheckLevel1True(functions[i].id);
		}
		setMainChecked(arrayTest);
	}, [checkedList]);

	const handleCheckLevel1 = (e: any, id: any) => {
		let lengthArray = [...functions].find((x) => x.id === id);
		let convertChecked = [...checkedList];
		if (e) {
			for (var i = 0; i < lengthArray.children.length; i++) {
				let value = [] as any;
				let listLabel = [] as any;
				for (var j = 0; j < lengthArray.children[i].children.length; j++) {
					const _item = lengthArray.children[i].children[j];
					if (_item.label) {
						value = [...value, ..._item.label.split(",").map((e: any) => +e)];
					}
					value.push(_item.value);
					listLabel.push(_item);
				}
				let item = {
					parent_id: id,
					id: lengthArray.children[i].id,
					value: value,
					listLabel: listLabel,
					checkAll: true,
					indeterminate: false
				};
				convertChecked = convertChecked.map((x) => (x.id === item.id ? item : x));
				if (!convertChecked.find((x) => x.id === item.id)) {
					convertChecked.push(item);
				}
				setCheckedList(convertChecked);
			}
			let item2 = {
				checkAll: true,
				ilu: false,
				id: id
			};
			if (mainChecked.find((x) => x.id === id)) {
				let fake = [...mainChecked].map((x) => (x.id === id ? item2 : x));
				setMainChecked(fake);
			} else {
				setMainChecked([...mainChecked, item2]);
			}
		} else {
			for (var i = 0; i < lengthArray.children.length; i++) {
				convertChecked = convertChecked.filter((x) => x.id !== lengthArray.children[i].id);
			}
			setCheckedList(convertChecked);
			setMainChecked(mainChecked.filter((x) => x.id !== id));
		}
	};

	// ---------------------------------------------------------------------
	const onSubmitForm = async (val: any) => {
		let arrayId = [] as any[];

		for (let i = 0; i < checkedList.length; i++) {
			if (checkedList[i].value.length > 0 || checkedList[i].checkAll) {
				arrayId.push({
					thread_id: checkedList[i].thread_id,
					attribute_id: checkedList[i].parent_id,
					val: checkedList[i].label
				});
			}
		}

		const _newParams = {
			...val,
			province_id: selectedProvince,
			district_id: selectedDistrict,
			ward_id: selectedWard,
			thread_id: paramsURL?.id,
			thread_action: "PUT",
			note: `User ${userDetail?.email} update Thread have id ${paramsURL?.id}`,
			short_desc: editorRef?.current?.getContent(),
			attribute_ids: [...new Set(arrayId)],
			img: fileListCover?.length > 0 ? val?.thumbnail : ""
		};

		try {
			const res: any = await _apiNotSaga.updatePostThread(paramsURL?.id, _newParams);
			setLoading(false);
			setIsLoad(!isLoad);
			notifySuccess(res.message);
			// onSuccess();
			history.push(`/list-new`);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const showMoreAttribute = async () => {
		setShowMore(!showMore);
	};

	return (
		<div className="p-8 trip-detail">
			<Link to={`/list-new`} style={{ cursor: "pointer" }}>
				<div className="flex items-center my-4 text-[#070707]">
					<ArrowLeftOutlined className="-mt-[1px]" />
					<span className="ml-1">Quay lại danh sách</span>
				</div>
			</Link>
			<Form name="myForm" layout="vertical" form={formCreate} onFinish={onSubmitForm}>
				<Card className="p-0 rounded-md shadow-sm border border-neutral-300">
					<>
						<div className={`m-4`}>
							<Row gutter={[24, 8]}>
								<Col className={``} md={16} xs={24} sm={24}>
									<Title level={4}>Thông tin chung</Title>
									{/* <p className="text-16 font-medium mt-5 mb-1">Mô tả</p> */}
									<Row gutter={[24, 8]}>
										<Col className={``} md={24} xs={24} sm={24}>
											<FormInput
												id="form-carGroup_name"
												name="thread_name"
												label="Tiêu đề"
												type="text"
												required
												size="large"
												placeholder="Nhập tiêu đề "
												onChange={(e) => {
													formCreate.setFieldsValue({
														slug: convertToSlug(e.target.value),
														meta_title: e.target.value
													});
												}}
												classItem="mb-0"
											/>
										</Col>
										<Col className={``} md={24} xs={24} sm={24}>
											<FormInput
												id="form-carGroup_name"
												name="price"
												label="Giá"
												type="justNumber"
												required
												size="large"
												placeholder="Nhập giá sản phẩm "
												onChange={(e) => {
													formCreate.setFieldsValue({
														price: e.target.value
													});
												}}
												classItem="mb-0"
											/>
										</Col>
									</Row>
									{/* select attribute thread */}
									<button
										type="button"
										className={"my-3 border-[1px] border-solid border-[#222] rounded-[8px] p-3"}
										onClick={showMoreAttribute}
									>
										{showMore ? <span>Thu gọn </span> : <span>Chọn thuộc tính </span>}
									</button>
									{showMore ? (
										<Row className={`my-4`} gutter={[24, 8]}>
											<Col className={``} md={24} xs={24} sm={24}>
												<div className="contentSection">
													<Loading spinning={loading}>
														<div className="mainPages contentSection-filter">
															{/* <Form
																name="formUpdate"
																layout="vertical"
																// form={formUpdate}
																// onFinish={btnCreatePrivilege}
																// onFinishFailed={onFinishFailed}
															> */}
															{false ? (
																<div className="h-full w-full flex justify-center">
																	<div style={{ marginTop: "20%" }}>
																		<Spin />
																	</div>
																</div>
															) : (
																<div className="contentSection" style={{ margin: "0" }}>
																	<div
																		style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
																	>
																		<Typography.Title level={5}>Chọn thuộc tính</Typography.Title>
																		{/* <div onClick={() => formUpdate.submit()} className="defaultButton border-none">
										<SvgIconStorage style={{ transform: "scale(0.7)" }} />
										&nbsp;Lưu
									</div> */}
																	</div>

																	<div
																		style={{
																			display: "flex",
																			justifyContent: "space-between",

																			marginTop: "4px"
																		}}
																	>
																		<div
																			style={{
																				width: "calc(50% - 4px)"
																			}}
																		>
																			{functions &&
																				functions.length > 0 &&
																				functions.map(
																					(functionLevel1, index) =>
																						index % 2 === 0 && (
																							<div
																								style={{
																									width: "100%",
																									marginBottom: "8px",

																									borderRadius: "5px",
																									overflow: "hidden",
																									height: checkStatus.find((x: any) => x.id === functionLevel1.id)
																										?.status
																										? "auto"
																										: "32px"
																								}}
																								key={geneUniqueKey()}
																							>
																								<div
																									style={{
																										background: "rgb(212,212,212)",
																										display: "flex",
																										alignItems: "center",
																										justifyContent: "space-between",
																										padding: "4px 8px "
																									}}
																								>
																									<div
																										style={{
																											fontSize: "14px",
																											fontWeight: "600"
																										}}
																									>
																										{functionLevel1?.label}
																									</div>

																									<SvgIconMoveRight
																										className={`${
																											checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																												? "rotateIconActive"
																												: "rotateIcon"
																										}`}
																										onClick={() => {
																											let fakeCheckStatus = [...checkStatus];
																											fakeCheckStatus = fakeCheckStatus.map((x: any) =>
																												x.id === functionLevel1.id ? { ...x, status: !x.status } : x
																											);
																											setCheckStatus(fakeCheckStatus);
																										}}
																									/>
																								</div>
																								<div
																									className={`${
																										checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																											? "listContentActive"
																											: "listContent"
																									}`}
																								>
																									{functionLevel1?.children &&
																										functionLevel1?.children.map((functionLevel2: any) => (
																											<div
																												key={geneUniqueKey()}
																												style={{ padding: "4px 8px " }}
																												className="listContentChild"
																											>
																												<Checkbox
																													key={geneUniqueKey()}
																													indeterminate={
																														checkedList.find(
																															(element) => element.id === functionLevel2?.id
																														)?.indeterminate
																													}
																													onChange={() =>
																														onCheckAllChange(
																															functionLevel2?.id,
																															functionLevel1.id,
																															functionLevel2.label
																														)
																													}
																													checked={
																														checkedList.find(
																															(element) => element.id === functionLevel2?.id
																														)?.checkAll
																													}
																													className="checkboxUserGroup"
																												>
																													<div
																														key={geneUniqueKey()}
																														style={{
																															fontSize: "14px",
																															fontWeight: "500"
																														}}
																													>
																														{functionLevel2?.label}
																													</div>
																													{functionLevel2?.children?.length > 0 && (
																														<Checkbox.Group
																															className="endChild"
																															key={geneUniqueKey()}
																															options={functionLevel2?.children}
																															value={
																																checkedList.find(
																																	(element) => element.id === functionLevel2?.id
																																)?.value
																															}
																															onChange={(e: any) =>
																																onChange(e, functionLevel2?.id, functionLevel1?.id)
																															}
																														/>
																													)}
																												</Checkbox>
																											</div>
																										))}
																								</div>
																							</div>
																						)
																				)}
																		</div>
																		<div
																			style={{
																				width: "calc(50% - 4px)"
																			}}
																		>
																			{functions &&
																				functions.length > 0 &&
																				functions.map(
																					(functionLevel1, index) =>
																						index % 2 === 1 && (
																							<div
																								style={{
																									width: "100%",
																									marginBottom: "8px",

																									borderRadius: "5px",
																									overflow: "hidden",
																									height: checkStatus.find((x: any) => x.id === functionLevel1.id)
																										?.status
																										? "auto"
																										: "32px"
																								}}
																								key={geneUniqueKey()}
																							>
																								<div
																									style={{
																										background: "rgb(212,212,212)",
																										display: "flex",
																										alignItems: "center",
																										justifyContent: "space-between",
																										padding: "4px 8px "
																									}}
																								>
																									<div
																										style={{
																											fontSize: "14px",
																											fontWeight: "600"
																										}}
																									>
																										{functionLevel1?.label}
																									</div>

																									<SvgIconMoveRight
																										className={`${
																											checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																												? "rotateIconActive"
																												: "rotateIcon"
																										}`}
																										onClick={() => {
																											let fakeCheckStatus = [...checkStatus];
																											fakeCheckStatus = fakeCheckStatus.map((x: any) =>
																												x.id === functionLevel1.id ? { ...x, status: !x.status } : x
																											);
																											setCheckStatus(fakeCheckStatus);
																										}}
																									/>
																								</div>
																								<div
																									className={`${
																										checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																											? "listContentActive"
																											: "listContent"
																									}`}
																								>
																									{functionLevel1?.children &&
																										functionLevel1?.children.map((functionLevel2: any) => (
																											<div
																												key={geneUniqueKey()}
																												className="listContentChild"
																												style={{ padding: "4px 8px " }}
																											>
																												<Checkbox
																													key={geneUniqueKey()}
																													indeterminate={
																														checkedList.find(
																															(element) => element.id === functionLevel2?.id
																														)?.indeterminate
																													}
																													onChange={() =>
																														onCheckAllChange(
																															functionLevel2?.id,
																															functionLevel1.id,
																															functionLevel2.label
																														)
																													}
																													checked={
																														checkedList.find(
																															(element) => element.id === functionLevel2?.id
																														)?.checkAll
																													}
																													className="checkboxUserGroup"
																												>
																													<div
																														key={geneUniqueKey()}
																														style={{
																															fontSize: "14px",
																															fontWeight: "500"
																														}}
																													>
																														{functionLevel2?.label}
																													</div>
																													{functionLevel2?.children?.length > 0 && (
																														<Checkbox.Group
																															className="endChild"
																															key={geneUniqueKey()}
																															options={functionLevel2?.children}
																															value={
																																checkedList.find(
																																	(element) => element.id === functionLevel2?.id
																																)?.value
																															}
																															onChange={(e: any) =>
																																onChange(e, functionLevel2?.id, functionLevel1?.id)
																															}
																														/>
																													)}
																												</Checkbox>
																											</div>
																										))}
																								</div>
																							</div>
																						)
																				)}
																		</div>
																	</div>
																</div>
															)}
															{/* </Form> */}
														</div>
													</Loading>
												</div>
											</Col>
										</Row>
									) : (
										""
									)}

									<Row className={`my-4`} gutter={[24, 8]}>
										<Col className={``} md={8} xs={24} sm={24}>
											<select
												className={"border border-solid border-[#060606f2] rounded-[10px] p-2"}
												value={selectedProvince ?? threadDetail.province_id}
												onChange={(e) => setSelectedProvince(Number(e.target.value))}
											>
												<option value="">Chọn tỉnh thành</option>
												{dataProvinces.map((province) => (
													<option key={province.id} value={province.id}>
														{province.province_name}
													</option>
												))}
											</select>
										</Col>
										<Col className={``} md={8} xs={24} sm={24}>
											<select
												className={"border border-solid border-[#060606f2] rounded-[10px] p-2"}
												value={selectedDistrict ?? threadDetail.district_id}
												onChange={(e) => setSelectedDistrict(Number(e.target.value))}
												disabled={filteredDistricts.length === 0}
											>
												<option value="">Chọn quận / huyện</option>
												{filteredDistricts.map((district) => (
													<option key={district.id} value={district.id}>
														{district.district_name}
													</option>
												))}
											</select>
										</Col>
										<Col className={``} md={8} xs={24} sm={24}>
											<select
												className={"border border-solid border-[#060606f2] rounded-[10px] p-2"}
												value={selectedWard ?? threadDetail.ward_id}
												onChange={(e) => setSelectedWard(Number(e.target.value))} // Update this to handle ward selection
												disabled={filteredWards.length === 0}
											>
												<option value="">Chọn phường xã</option>
												{filteredWards.map((ward) => (
													<option key={ward.id} value={ward.id}>
														{ward.ward_name}
													</option>
												))}
											</select>
										</Col>
									</Row>
									<Row gutter={[24, 8]}>
										<Col className={``} md={24} xs={24} sm={24}>
											<FormInput
												id="form-carGroup_name"
												name="address"
												label="Địa chỉ"
												type="text"
												required
												size="large"
												placeholder="Thêm địa chỉ"
												onChange={(e) => {
													formCreate.setFieldsValue({
														// slug: convertToSlug(e.target.value),
														address: e.target.value
													});
												}}
												classItem="mb-0"
											/>
										</Col>
									</Row>
									<Row gutter={[24, 8]}>
										<Col className={``} md={24} xs={24} sm={24}>
											<FormSelect
												name="category_id"
												label="Chọn danh mục"
												dataSource={flattenedCategories}
												required
												placeholder="Chọn danh mục"
												slOption={{
													name: "title",
													value: "id"
												}}
												showSearch
												optionFilterProp="children"
											/>
										</Col>
									</Row>

									<div className="text-black text-opacity-90 text-sm font-normal mt-2 leading-snug">Mô tả ngắn:</div>

									<DDEditor
										refEditor={editorRef}
										textareaName="short_desc"
										onInit={(evt: any, editor: any) => (editorRef.current = editor)}
										// initialValue={stateContent || ""}
										initialValue={stateContent || "Nội dung"}
									/>
								</Col>
								<Col className={``} md={8} xs={24} sm={24}>
									<Title level={4}>SEO</Title>
									<div className="flex justify-between items-center">
										<div className="w-[83px] text-black text-opacity-90 text-sm font-normal   leading-snug">
											Meta Title
										</div>
										<div className="w-[148px] text-right">
											<span className="text-black text-opacity-90 text-xs font-light   leading-snug">
												Số ký tự đã dùng:
											</span>
											<span className="text-black text-opacity-90 text-xs font-normal   leading-snug"> 4/70</span>
										</div>
									</div>
									<FormInput
										id="form-carGroup_name"
										name="meta_title"
										// label="Giờ làm việc"
										type="text"
										required
										size="large"
										placeholder="Nhập tiêu đề "
										classItem="mb-0"
									/>
									<div className="flex justify-between items-center mt-[20px]">
										<FormInput
											id="form-carGroup_name"
											name="slug"
											label="Slug"
											type="text"
											required
											size="large"
											placeholder="Nhập slug "
											classItem="mb-0"
											className="w-full"
										/>
									</div>
									<div className="flex justify-between items-center mt-[20px]">
										<div className=" text-black text-opacity-90 text-sm font-normal   leading-snug">
											Meta Descriptions
										</div>
										<div className="w-[148px] text-right">
											<span className="text-black text-opacity-90 text-xs font-light   leading-snug">
												Số ký tự đã dùng:
											</span>
											<span className="text-black text-opacity-90 text-xs font-normal   leading-snug"> 4/320</span>
										</div>
									</div>
									<Form.Item name={"meta_desc"}>
										<Input.TextArea placeholder="Meta Descriptions" />
									</Form.Item>

									<div className="w-[133px] mt-[20px] text-black text-opacity-90 text-sm font-normal   leading-snug">
										Meta Keywords
									</div>
									<Form.Item name={"meta_keyword"} required>
										<Input.TextArea placeholder="Meta Keywords" />
									</Form.Item>
									{/*  */}
									<UploadImages />
								</Col>
							</Row>
						</div>
					</>
					<Affix offsetBottom={2}>
						<div className="bg-[#404040] h-[60px] flex justify-end items-center w-full shadow-2xl border border-[#404040]">
							{statusNews === "A" && (
								<Button
									size="large"
									className="mr-[20px] border-[#FFFFB8] border-1 hover:bg-[#404040] bg-[#404040] text-[#FFFFB8] hover:text-[#FFFFB8]font-bold"
								>
									<a
										href={`${TUYENDUNG_URL}tin-tuc/${metaslug}`}
										target="_blank"
										className="text-[15px] text-[#FFFFB8] hover:text-[#FFFFB8] font-bold"
									>
										Xem Tin
									</a>
								</Button>
							)}
							<Button
								size="large"
								// htmlType="submit"
								onClick={() => {
									onSubmitForm(formCreate.getFieldsValue());
									// searchVehilce();
								}}
								// form="myForm"
								className="mr-[20px] bg-[#FADB14] hover:bg-[#FADB14] text-[#404040] font-bold"
							>
								<p className="text-[15px] text-[#404040] hover:text-[#404040]">Cập nhật</p>
							</Button>
						</div>
					</Affix>
				</Card>
			</Form>
		</div>
	);
};

export default DetailsNews;
