import { Button, Col, DatePicker, Form, Modal, Row, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import UpdaloadImage from "src/components/upload/UploadImage";
import { dataGender } from "src/constants";
import { _apiNotSaga, uploadFile } from "src/services/api/apiNotSaga";
import { CND_URL } from "src/services/api/config";
import { dateFormatDMY } from "src/utils/helpers/functions/date";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

const ModalEmail = ({
	visible = true,
	detail = {} as any,
	onCancel = () => {},
	onSuccess = () => {},
	contentEmail = "" as any,
	titleEmail = "" as any
}) => {
	const [editor, setEditor] = useState<any>(EditorState.createEmpty());
	const [fileListCover, setFileListCover] = useState<any>([]);
	const [fileListCardF, setFileListCardF] = useState<any>([]);
	const [fileListCardB, setFileListCardB] = useState<any>([]);
	const [form] = Form.useForm();
	const [status, setStatus] = useState<boolean>(true);
	const [messageApi, contextHolder] = message.useMessage();
	const [loading, setLoading] = useState<boolean>(false);

	const isMount = useIsMount();
	const dispatch = useDispatch();
	const [urlCover, setUrlCover] = useState<any>("");
	const [urlCardF, setUrlCardF] = useState<any>("");
	const [urlCardB, setUrlCardB] = useState<any>("");

	const [provinces, setProvinces] = useState<any>(undefined);
	const [districs, setDistrics] = useState<any>(undefined);
	const [districsTemp, setDistricsTemp] = useState<any>(undefined);
	const [wards, setWards] = useState<any>(undefined);
	const [wardsTemp, setWardsTemp] = useState<any>(undefined);
	const getEditor = (html: any) => {
		// let contentState = stateFromHTML(html);
		const contentBlock = htmlToDraft(html);
		const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
		const editor = EditorState.createWithContent(contentState);

		return editor;
	};
	useEffect(() => {
		setEditor(getEditor(contentEmail));
	}, [contentEmail]); //eslint-disable-line
	useEffect(() => {
		if (detail) {
			form.setFieldsValue({
				...detail,
				gender: detail.gender !== 0 ? detail.gender?.toString() : null,
				identity_card_location: detail?.identity_card_location !== 0 ? detail?.identity_card_location : undefined,
				dob: detail?.dob ? moment(detail?.dob) : undefined,
				identity_card_date: detail?.identity_card_date ? moment(detail?.identity_card_date) : undefined,
				// province_id: detail?.province_id,
				// district_id: detail?.district_id,
				// ward_id: detail?.ward_id,
				temp_province_id: detail?.temp_province_id !== 0 ? detail?.temp_province_id : undefined,
				temp_district_id: detail?.temp_district_id !== 0 ? detail?.temp_district_id : undefined,
				temp_ward_id: detail?.temp_ward_id !== 0 ? detail?.temp_ward_id : undefined
				// temp_district_id: detail?.temp_district_id,
				// temp_ward_id: detail?.temp_ward_id,
				// identity_card_location: detail?.identity_card_location
			});
			const getProvince = async () => {
				const district: any = await _apiNotSaga.getDistricts({ province_id: detail?.province_id });
				if (district.success) {
					setDistrics(district.data);
				}
			};
			getProvince();
			if (detail?.temp_province_id) {
				const getProvinceTemp = async () => {
					const district: any = await _apiNotSaga.getDistricts({ province_id: detail?.temp_province_id });
					if (district.success) {
						setDistricsTemp(district.data);
					}
				};
				getProvinceTemp();
			}

			const getWard = async () => {
				const ward: any = await _apiNotSaga.getWards({ district_id: detail?.district_id });
				if (ward.success) {
					setWards(ward.data);
				}
			};
			getWard();

			if (detail?.temp_district_id) {
				const getWardTemp = async () => {
					const ward: any = await _apiNotSaga.getWards({ district_id: detail?.temp_district_id });
					if (ward.success) {
						setWardsTemp(ward.data);
					}
				};
				getWardTemp();
			}
			if (detail?.portrait_url) {
				setFileListCover([
					{
						uid: "-1",
						name: "image.png",
						status: "done",
						url: CND_URL + detail?.portrait_url
					}
				]);
			}
			if (detail?.identity_card_furl) {
				setFileListCardF([
					{
						uid: "-1",
						name: "image.png",
						status: "done",
						url: CND_URL + detail?.identity_card_furl
					}
				]);
			}
			if (detail?.identity_card_burl) {
				setFileListCardB([
					{
						uid: "-1",
						name: "image.png",
						status: "done",
						url: CND_URL + detail?.identity_card_burl
					}
				]);
			}

			setUrlCover(detail?.portrait_url);
			setUrlCardF(detail?.identity_card_furl);
			setUrlCardB(detail?.identity_card_burl);
		}
	}, [detail]);
	const onSubmit = async (val: any) => {
		console.log(val);
		const content = draftToHtml(convertToRaw(editor?.getCurrentContent()));

		let paramsss = {
			content: content,
			email: detail?.candidate_email,
			title: titleEmail
		};
		try {
			// const res1: any = await _apiNotSaga.updateCandidate(dataProfile.candidate_id, params);
			const res2: any = await _apiNotSaga.sendEmail(paramsss);
			setLoading(false);
			notifySuccess(res2.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	return (
		<>
			<Modal
				open={visible}
				onCancel={onCancel}
				// maskClosable={false}
				width={900}
				title={titleEmail}
				style={{
					maxWidth: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8"></div>
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm"
							onClick={() => onSubmit(form.getFieldsValue())}
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">Gửi Email</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<div className="rdw-storybook-root">
						<Editor
							// readOnly={!editEnable}
							placeholder="nhập nội dung..."
							wrapperClassName="rich-editor demo-wrapper"
							editorClassName="demo-editor"
							editorState={editor}
							onEditorStateChange={(editor) => setEditor(editor)}
						/>
					</div>
				</Loading>
			</Modal>
		</>
	);
};

export default ModalEmail;
