import { combineReducers } from "redux";
import { IRootReducers } from "../interfaces/root.interfaces";
import accountReducer from "./account.reducer";
import customerReducer from "./customer.reducer";
import pageHeaderReducer from "./pageHeader.reducer";
import waitingBookReducer from "./waitingBook.reducer";
import vehicleGroupReducer from "./vehicleGroup.reducer"
import repairManagementReducer from "./repairManagement.reducer"
import repairRegulationReducer from "./repairRegulation.reducer";
import suppliesReducer from './supplies.reducer';
import vehicleTypeReducer from './vehicleType.reducer';
import vehicleReducer from './vehicle.reducer';
import globalReducer from './global.reducer';
import routingGroupReducer  from "./routingGroup.reducer";
import driversReducer  from "./drivers.reducer";
import routesReducer  from "./routes.reducer";
import placesReducer  from "./places.reducer";
import priceOffTypeReducer  from "./priceOffType.reducer";
import orgReducer  from "./org.reducer";
import rolesReducer  from "./roles.reducer";
import moduleFunctReducer  from "./moduleFunct.reducer";
import userTypesReducer  from "./userTypes.reducer";
import repairPartnerReducer  from "./repairPartner.reducer";
import exportReducer  from "./export.reducer";
import importReducer  from "./import.reducer";
import transportTripsReducer  from "./transportTrips.reducer";
import transportAutoConfigsReducer  from "./transportAutoConfigs.reducer";
import userReducer  from "./user.reducer";
import categoryReducer from "./categories.reducer";
import attributeReducer from './attribute.reducer'

const rootReducers = combineReducers<IRootReducers>({
  accountReducer,
  customerReducer,
  pageHeaderReducer,
  waitingBookReducer,
  vehicleGroupReducer,
  repairManagementReducer,
  suppliesReducer,
  repairRegulationReducer,
  vehicleTypeReducer,
  globalReducer,
  vehicleReducer,
  routingGroupReducer,
  driversReducer,
  routesReducer,
  placesReducer,
  priceOffTypeReducer,
  orgReducer,
  rolesReducer,
  moduleFunctReducer,
  userTypesReducer,
  repairPartnerReducer,
  exportReducer,
  importReducer,
  transportTripsReducer,
  transportAutoConfigsReducer,
  userReducer,
  categoryReducer,
  attributeReducer,
});

export default rootReducers;
