/*eslint-disable */

import { Affix, Button, Card, Col, Form, Row, Tag } from "antd";
import FormItem from "antd/es/form/FormItem";
import { EditorState, Modifier, convertToRaw } from "draft-js";
// import "draft-js/dist/Draft.css";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import FormInput from "src/components/form/FormInput";
import { notifyError, notifySuccess } from "src/components/notification";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const CreateEmail = (props: any) => {
	const formatTime = "HH:mm";
	const [isUpload, setIsUpload] = useState<boolean>(false);
	const [dataEmailField, setDataEmailField] = useState<any>([]);
	const [formCreate] = Form.useForm();
	const [loading, setLoading] = useState<boolean>(false);
	const [editEnable, setEditEnable] = useState<boolean>(true);
	const [editor, setEditor] = useState(EditorState.createEmpty());
	const editorRef = useRef<any>(null);
	const [isDirty, setIsDirty] = useState(false);
	const onSubmitForm = async (val: any) => {
		const content = draftToHtml(convertToRaw(editor.getCurrentContent()));
		if (content === "<p></p>") {
			notifyError("Vui lòng nhập nội dung email");
			return;
		}
		const _newParams = {
			...val,
			content: content
			// status: changeStatus ? "A" : "D"
		};
		try {
			const res: any = await _apiNotSaga.createEmailTemplate(_newParams);
			setLoading(false);
			notifySuccess(res.message);
			// onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	useEffect(() => {
		const initpage = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const ress: any = await _apiNotSaga.getEmailField({});
				setDataEmailField(ress?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initpage();
	}, []);

	const insertText = (text: any, editorState: any) => {
		const currentContent = editorState.getCurrentContent(),
			currentSelection = editorState.getSelection();

		const newContent = Modifier.replaceText(currentContent, currentSelection, text);

		const newEditorState = EditorState.push(editorState, newContent, "insert-characters");
		return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
	};

	const sendTextToEditor = (text: any) => {
		setEditor(insertText(text, editor));
		focusEditor();
	};

	const focusEditor = () => {
		if (editorRef.current) {
			editorRef.current.focus();
			//console.log("1. Editor has the focus now");
		}
	};
	return (
		<div className="p-8 trip-detail">
			<Form name="myForm" layout="vertical" form={formCreate} onFinish={onSubmitForm}>
				<Card className="p-0 rounded-md shadow-sm border border-neutral-300">
					<>
						<div className={`m-4`}>
							<Row gutter={[24, 8]}>
								<Col className={``} md={6} xs={24} sm={24}>
									<FormInput
										name="email_template_code"
										label="Tên email"
										type="text"
										size="large"
										required
										placeholder="Nhập tên mẫu"
										classItem="mb-0"
									/>
								</Col>
								<Col className={``} md={18} xs={24} sm={24}>
									<FormInput
										name="email_template_name"
										label="Tiêu đề"
										type="text"
										size="large"
										required
										placeholder="Nhập tiêu đề"
										classItem="mb-0"
									/>
								</Col>
							</Row>
							<Row gutter={[24, 8]}>
								<Col className={``} md={16} xs={24} sm={24}>
									<FormItem label="Nội dung">
										<div className="rdw-storybook-root">
											<Editor
												readOnly={!editEnable}
												placeholder="nhập nội dung..."
												wrapperClassName="rich-editor demo-wrapper"
												editorClassName="demo-editor"
												editorState={editor}
												onEditorStateChange={(newEditor) => {
													setEditor(newEditor);
													setIsDirty(true);
												}}
												ref={editorRef}
											/>
										</div>
									</FormItem>
								</Col>
								<Col className={``} md={8} xs={24} sm={24}>
									<div className="text-black text-opacity-90 text-sm font-normal mt-2 leading-snug">Trường dữ liệu</div>
									<div className="flex flex-col overflow-auto w-full h-[300px] border border-zinc-300 rounded-xl p-2">
										{dataEmailField.map((item: any, index: any) => (
											<Tag
												key={index}
												style={{ margin: 5 }}
												color="geekblue"
												className="cursor-pointer"
												onClick={() => sendTextToEditor(`[${item.key}]`)}
											>
												{item.value}
											</Tag>
										))}
									</div>
								</Col>
							</Row>
						</div>
					</>
					<Affix offsetBottom={2}>
						<div className="bg-black h-[60px] flex justify-end items-center w-full shadow-2xl border border-black">
							{/* <div className="text-center text-white  text-[16px] leading-normal">Xem trước</div>
						<Button
							size="middle"
							block={true}
							type="link"
							className="mx-[20px]"
							onClick={() => {
								// searchVehilce();
							}}
							style={{
								backgroundColor: "#fff",
								height: "40px",
								width: "100px",
								border: "1px solid black",
								borderRadius: 5
								// marginTop: 10
							}}
						>
							<span style={{ marginLeft: "5px", fontSize: "14", fontWeight: "500", color: "black" }}>Lưu nháp</span>
						</Button> */}
							<Button
								size="middle"
								block={true}
								type="link"
								className="mx-[20px]"
								htmlType="submit"
								form="myForm"
								style={{
									backgroundColor: "yellow",
									height: "40px",
									width: "100px",
									border: "1px solid black",
									borderRadius: 5
									// marginTop: 10
								}}
							>
								<span style={{ marginLeft: "5px", fontSize: "14", fontWeight: "500", color: "black" }}>Tạo mới</span>
							</Button>
						</div>
					</Affix>
				</Card>
			</Form>
		</div>
	);
};

export default CreateEmail;
