/* eslint-disable */
import { Checkbox, Form, Menu, Button, Spin, Typography } from "antd";
import { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import { notifyError, notifySuccess } from "src/components/notification";
import { AppState, RouteParams } from "src/types";
import routerNames from "src/utils/data/routerName";
import { getMessageV1 } from "src/utils/helpers/functions/getMessage";
import { geneUniqueKey } from "src/utils/helpers/functions/textUtils";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { ArrowLeftOutlined } from "@ant-design/icons";
import SvgIconMoveRight from "src/assets/svg/SvgIconMoveRight";
import { getCategories } from "src/services/actions/category.actions";
import { updatePageHeader } from "src/services/actions/pageHeader.action";
import { getRoleDetail } from "src/services/actions/roles.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import SvgPen from "src/assets/svg/SvgPen";
import "./role.scss";
import Loading from "src/components/loading/Loading";
import UserModal from "./UserModal";

import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";
import { getAttribute } from "src/services/actions/attribute.actions";
const AttributeList = ({}: any) => {
	
	const [formUpdate] = Form.useForm();
	const [mainChecked, setMainChecked] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [checkStatus, setCheckStatus] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [callback, setCallback] = useState<boolean>(false);
	const dispatch = useDispatch();
	const isMount = useIsMount();
	const paramsURL = useParams<RouteParams>();
	const [functions, setFunctions] = useState<any[]>([]);
	// const { stateRoleDetail } = useSelector((e: AppState) => e.rolesReducer);
	const [stateRoleDetail, setStateRoleDetail] = useState<any>([]);

	const [data, setData] = useState<any>([]);
	const [detail, setDetail] = useState<any>({});
	const [total, setToal] = useState<number>(0);
	const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);

	const [attributeIDLv1, setAttributeIDLv1] = useState<any>({});
	const [attributeIDLv2, setAttributeIDLv2] = useState<any>({});
	const { stateGetAttribute } = useSelector((e: AppState) => e.attributeReducer);

	const [visible, setVisible] = useState<boolean>(false);
	const [dataAttribute, setDataAttribute] = useState<any>([]);
	const [isDataLoaded, setIsDataLoaded] = useState(false);

	/****************************START**************************/
	/*                         Life Cycle                      */
	// const [roles, setRoles] = useState<any>([]);
	// const pathName = useHistory().location.pathname.slice(1, 12);
	// useEffect(() => {
	// 	let _dataUser = JSON.parse(localStorage.getItem("ACCOUNT") || "");
	// 	let fakeRoles = [];
	// 	if (_dataUser?.menu) {
	// 		for (let i = 0; i < _dataUser.menu.length; i++) {
	// 			for (let j = 0; j < _dataUser.menu[i].children.length; j++) {
	// 				if (_dataUser.menu[i].children[j].funct_code === pathName.toString()) {
	// 					for (let k = 0; k < _dataUser.menu[i].children[j].children.length; k++) {
	// 						if (_dataUser.menu[i].children[j].children[k].funct_code === "update-user-group") {
	// 							fakeRoles.push("update-user-group");
	// 						}
	// 					}
	// 					break;
	// 				}
	// 			}
	// 		}
	// 		setRoles(fakeRoles);
	// 	}
	// }, [localStorage.getItem("ACCOUNT")]);
	useEffect(() => {
		let _params = [
			{
				path: "/",
				name: "Trang chủ"
			},
			{
				name: "Thiết lập hệ thống"
			},
			{
				path: routerNames.CATEGORY,
				name: "Quản lý thuộc tính"
			},
			{
				path: "/",
				name: "Chi tiết"
			}
		];
		dispatch(updatePageHeader(_params));
	}, [dispatch]);
 
	useEffect(() => {
		dispatch(getAttribute({}));
	}, []);
	// Lấy thông tin user
	// useEffect(() => {
	// 	const init = async () => {
	// 		try {
	// 			const userData = await localStorage.getItem("ACCOUNT");
	// 			if (userData) {
	// 				setDetail(JSON.parse(userData).user);
	// 			}
	// 			setLoading(false);
	// 			setIsUserDataLoaded(true); // Indicate that user data has been loaded
	// 		} catch (error: any) {
	// 			setLoading(false);
	// 			notifyError(error.message);
	// 		}
	// 	};
	// 	init();
	// }, []);

	// // console.log("detail", detail);
	// useEffect(() => {
	// 	const init = async () => {
	// 		if (!isUserDataLoaded) return; // Wait until user data is loaded
	// 		try {
	// 			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
	// 			setLoading(true);
	// 			const res: any = await _apiNotSaga.userDetail(detail.id);
	// 			setData(res?.data);
	// 			setToal(res?.paging?.total);
	// 			setLoading(false);
	// 		} catch (error: any) {
	// 			setLoading(false);
	// 			notifyError(error.message);  
	// 		}
	// 	};
	// 	init();
	// }, [isUserDataLoaded, detail]); //eslint-disable-line

	useEffect(() => {
		const init = async () => {
			// if (!isUserDataLoaded) return; // Wait until user data is loaded
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getListAttribute({});
				setDataAttribute(res?.data); 
				setToal(res?.paging?.total);
				setLoading(false);
				setIsDataLoaded(true);
			} catch (error: any) {
				setLoading(false); 
				notifyError(error.message);
			}
		};
		init();
	}, []); //eslint-disable-line
	// console.log("attribute", dataAttribute)
	// useEffect(() => {
	// 	// dispatch(getRoleDetail(paramsURL?.id));
	// 	try {
	// 		setLoading(true);
	// 		const res: any = _apiNotSaga
	// 			.getListCategory({})
	// 			.then((res: any) => {
	// 				if (res.success) {
	// 					setStateRoleDetail(res.data);
	// 				}
	// 			})
	// 			.catch((err) => {
	// 				notifyError(err.message);
	// 			});

	// 		// setStateRoleDetail(res.data);
	// 		notifySuccess(res.message);
	// 		setCallback(!callback);
	// 		setLoading(false);
	// 	} catch (error: any) {
	// 		setLoading(false);
	// 		notifyError(error.message);
	// 	}
	// }, [paramsURL.id]);

	useEffect(() => {
		if (isMount) return;
		const { data, message, success, error, isLoading } = stateGetAttribute;

		setLoading(isLoading || false);
		if (success) {
			let dataFunctions = data;
			let fakeFunctions = [...dataFunctions?.sort((a: any, b: any) => b?.children.length - a?.children.length)];
			let convertArray = [];
			let fakeCheckStatus = [];

			for (var i = 0; i < fakeFunctions?.length; i++) {
				fakeCheckStatus.push({
					id: fakeFunctions[i]?.id,
					status: true
				});
				let convertLevel1 = {
					children: [] as any,
					value: fakeFunctions[i]?.id,   
					label: fakeFunctions[i]?.attribute_name,
					status: fakeFunctions[i]?.status,
					id: fakeFunctions[i]?.id
				};
				for (var j = 0; j < fakeFunctions[i]?.children?.length; j++) {
					let convertLevel2 = {
						children: [] as any,
						value: fakeFunctions[i]?.children[j]?.id,
						label: fakeFunctions[i]?.children[j]?.attribute_value,
						status: fakeFunctions[i]?.children[j]?.status,
						id: fakeFunctions[i]?.children[j]?.id
					};
					// for (var k = 0; k < fakeFunctions[i]?.children[j]?.children?.length; k++) {
					// 	convertLevel2.children.push({
					// 		value: fakeFunctions[i]?.children[j]?.children[k]?.id,
					// 		label: fakeFunctions[i]?.children[j]?.children[k]?.category_name,
					// 		status: fakeFunctions[i]?.children[j]?.children[k]?.status,
					// 		relative_funct_ids: fakeFunctions[i]?.children[j]?.children[k]?.relative_funct_ids
					// 	});
					// }

					convertLevel1.children.push(convertLevel2);
				}
				convertArray.push(convertLevel1);
			}
			setCheckStatus(fakeCheckStatus);
			setFunctions(convertArray);
		}
		if (success === false || error) {
			let msg = getMessageV1(message, ", ");
			return notifyError(msg);
		}
	}, [stateGetAttribute.isLoading,]);


	const onSuccessModal = () => {
		setCallback(!callback); 
		setVisible(false);
		window.location.reload();
	};
	const cancelModal = () => {
		setAttributeIDLv1('')
		setVisible(false);
	};

	const getDetail = async (id: number, options:any) => { 
		
		let idlv1 = options.idlv1
		setAttributeIDLv1({idlv1, id }); 
		// setAttributeIDLv1({"getDetailV1": id });
		setVisible(true);
	};
	// console.log("attributeIDLv1", attributeIDLv1)
	// const getDetailLv2 = async (id: number) => {
	// 	setAttributeIDLv2({idlv1: false, id }); 
	// 	console.log("getDetailV2", id)
	// 	setVisible(true);
	// };
  

	const handleCreateCategory = ()=>{  
		setVisible(true);
	}
	const onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};

	/**************************** END **************************/
	return (
		<>
			{visible && <UserModal visible={visible} attributeIDLv1={attributeIDLv1} onCancel={cancelModal} onSuccess={onSuccessModal} />}
			<div className="contentSection">
				<Link to={`/roles`} style={{ cursor: "pointer" }}>
					<div className="flex items-center mb-5">
						<ArrowLeftOutlined className="-mt-[1px] text-[#222]" />
						<span className="ml-1 text-[#222]">Quay lại</span>
					</div>
				</Link>
				<Loading spinning={loading}>
					<div className="mainPages contentSection-filter"> 
						<Form
							name="formUpdate"
							layout="vertical"
							form={formUpdate}
							// onFinish={btnCreatePrivilege}
							onFinishFailed={onFinishFailed}
						>
							{false ? (
								<div className="h-full w-full flex justify-center">
									<div style={{ marginTop: "20%" }}>
										<Spin />
									</div>
								</div>
							) : (
								<div className="contentSection" style={{ margin: "0" }}>
									<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
										<Typography.Title level={5}>Quản lý thuộc tính</Typography.Title>						
										<div onClick={() => handleCreateCategory()} className="defaultButton border-none">
											<SvgIconStorage style={{ transform: "scale(0.7)" }} />
											&nbsp;Tạo mới
										</div>
									</div>

									<div
										style={{
											display: "flex",
											justifyContent: "space-between",

											marginTop: "4px"
										}}
									>
										<div
											style={{
												width: "calc(50% - 4px)"
											}}
										>
											{functions &&
												functions.length > 0 &&
												functions.map(
													(functionLevel1, index) =>
														index % 2 === 0 && (
															<div
																style={{
																	width: "100%",
																	marginBottom: "8px",

																	borderRadius: "5px",
																	overflow: "hidden",
																	height: checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																		? "auto"
																		: "32px"
																}}
																key={geneUniqueKey()}
															>
																<div
																	style={{
																		background: "rgb(212,212,212)",
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "space-between",
																		padding: "4px 8px "
																	}}
																>													
																	<div
																		style={{
																			fontSize: "14px",
																			fontWeight: "600",
																			flex:"1"
																		}}
																	>
																		{functionLevel1?.label}
																	</div>

																	<Button onClick={() => getDetail(functionLevel1.id, { idlv1: true })}>
																		<SvgPen fill="black" />
																		<span>Chỉnh sửa</span>
																	</Button>

																	<SvgIconMoveRight
																		className={`${
																			checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																				? "rotateIconActive"
																				: "rotateIcon"
																		}`}
																		onClick={() => {
																			let fakeCheckStatus = [...checkStatus];
																			fakeCheckStatus = fakeCheckStatus.map((x: any) =>
																				x.id === functionLevel1.id ? { ...x, status: !x.status } : x
																			);
																			setCheckStatus(fakeCheckStatus);
																		}}
																	/>
																</div>
																<div
																	className={`${
																		checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																			? "listContentActive"
																			: "listContent"
																	}`}
																>
																	{functionLevel1?.children &&
																		functionLevel1?.children.map((functionLevel2: any) => (
																			<div
																				key={geneUniqueKey()}
																				style={{ padding: "4px 8px " }}
																				className="listContentChild flex border-solid border-x-[1px] border-t-[0px] border-b-[1px] border-[#222222c2]"
																			><div className="flex-1">	{functionLevel2?.label}</div>
																		
																				<Button className="bg-[#2222]" onClick={() => getDetail(functionLevel2?.id, { idlv1: false })}>
																					<SvgPen fill="black" />
																				
																					
																						
																					
																				</Button>

																				{functionLevel2?.children?.length > 0
																					? functionLevel2?.children?.map((child: any) => (
																						
																							<Button key={child.id} onClick={() => getDetail(child.value, { idlv1: false })}>
																								<SvgPen fill="black" />
																								
																								{child.label}
																							</Button>
																					  ))
																					: ""}
																				{/* </Checkbox> */}
																			</div>
																		))}
																</div>
															</div>
														)
												)}
										</div>
										<div
											style={{
												width: "calc(50% - 4px)"
											}}
										>
											{functions &&
												functions.length > 0 &&
												functions.map(
													(functionLevel1, index) =>
														index % 2 === 1 && (
															<div
																style={{
																	width: "100%",
																	marginBottom: "8px",

																	borderRadius: "5px",
																	overflow: "hidden",
																	height: checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																		? "auto"
																		: "32px"
																}}
																key={geneUniqueKey()}
															>
																<div
																	style={{
																		background: "rgb(212,212,212)",
																		display: "flex",
																		alignItems: "center",
																		justifyContent: "space-between",
																		padding: "4px 8px "
																	}}
																>
																	<div
																			style={{
																				fontSize: "14px",
																				fontWeight: "600",
																				flex:"1"
																			}}
																		>
																			{functionLevel1?.label}
																		</div>
																
																	<Button onClick={() => getDetail(functionLevel1?.id, { idlv1: true })}>
																		<SvgPen fill="black" />
																		<span>Chỉnh sửa</span>
																		
																	</Button>

																	{/* </Checkbox> */}
																	<SvgIconMoveRight
																		className={`${
																			checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																				? "rotateIconActive"
																				: "rotateIcon"
																		}`}
																		onClick={() => {
																			let fakeCheckStatus = [...checkStatus];
																			fakeCheckStatus = fakeCheckStatus.map((x: any) =>
																				x.id === functionLevel1.id ? { ...x, status: !x.status } : x
																			);
																			setCheckStatus(fakeCheckStatus);
																		}}
																	/>
																</div>
																<div
																	className={`${
																		checkStatus.find((x: any) => x.id === functionLevel1.id)?.status
																			? "listContentActive"
																			: "listContent"
																	}`}
																>
																	{functionLevel1?.children &&
																		functionLevel1?.children.map((functionLevel2: any) => (
																			<div
																				key={geneUniqueKey()}
																				className="listContentChild flex border-solid border-x-[1px] border-t-[0px] border-b-[1px] border-[#222222c2]"
																				style={{ padding: "4px 8px " }}
																			>
																					<div
																						style={{
																							fontSize: "14px",
																							fontWeight: "600",
																							flex:"1"
																						}}
																					>
																						{functionLevel2?.label}
																					</div>
																			
																				<Button className="bg-[#2222]" onClick={() => getDetail(functionLevel2?.id, { idlv1: false })}>
																					<SvgPen fill="black" />
																				
																				
																				</Button>
 
																				{functionLevel2?.children?.length > 0
																					? functionLevel2?.children?.map((child: any) => (
																							<Button key={child.id} onClick={() => getDetail(child.value, { idlv1: false })}>
																								<SvgPen fill="black" />
																								
																								{child.label}
																							</Button>
																					  ))
																					: ""}
																			</div>
																		))}
																</div>
															</div>
														)
												)}
										</div>
									</div>
								</div>
							)}
						</Form>
					</div>
				</Loading>
			</div>
		</>
	);
};

export default AttributeList;
/* eslint-disable */
