
import { IAction } from '../interfaces/root.interfaces';
import attributeTypes from './types/attribute.types';

export const getAttribute = (params?: any): IAction => {
  const action: IAction = {
    type: attributeTypes.START_GET_LIST_ATTRIBUTE,
    payload: { params },
  }
  return action;
}

// export const getAttributeDetail = (id?: any): IAction => {
//   const action: IAction = {
//     type: attributeTypes.START_GET_ATTRIBUTE_BY_ID,
//     payload: { id },
//   }
//   return action;
// }
// export const getAttributeDetailChildren = (id?: any): IAction => {
//   const action: IAction = {
//     type: attributeTypes.START_GET_ATTRIBUTE_BY_ID_CHILDREN,
//     payload: { id },
//   }
//   return action;
// }