import { call, put, takeLatest } from "redux-saga/effects";
import { API_ERP } from "src/services/api/url.index";
import { api } from "../../api/api.index";
import { API_URL } from "../../api/config";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces"; /*eslint-disable-line*/
import { actionFailure, actionSuccess } from "../root.actions";
import orgTypes from "../types/org.types";

function* getOrgs(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/orgs`, payload?.params);
		});
		yield put(actionSuccess(orgTypes.ORG_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(orgTypes.ORG_FAILURE, err));
	}
}

const orgSagas = [
	takeLatest(orgTypes.START_ORG, getOrgs),
];
export default orgSagas;
