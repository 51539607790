import * as React from "react";

const SvgIconExpand = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="21" height="17" viewBox="0 0 21 17" fill="#F4F4F4">
		<path
			d="M1.64581 3.81249H19.3542C20.2173 3.81249 20.9167 3.11313 20.9167 2.25C20.9167 1.38686 20.2173 0.6875 19.3542 0.6875H1.64581C0.782674 0.6875 0.083313 1.38686 0.083313 2.25C0.083313 3.11313 0.782674 3.81249 1.64581 3.81249Z"
			fill="#F4F4F4"
		/>
		<path
			d="M19.3542 6.9375H1.64581C0.782674 6.9375 0.083313 7.63686 0.083313 8.5C0.083313 9.36314 0.782674 10.0625 1.64581 10.0625H19.3542C20.2173 10.0625 20.9167 9.36314 20.9167 8.5C20.9167 7.63686 20.2173 6.9375 19.3542 6.9375Z"
			fill="#F4F4F4"
		/>
		<path
			d="M19.3542 13.1875H1.64581C0.782674 13.1875 0.083313 13.8869 0.083313 14.75C0.083313 15.6131 0.782674 16.3125 1.64581 16.3125H19.3542C20.2173 16.3125 20.9167 15.6131 20.9167 14.75C20.9167 13.8869 20.2173 13.1875 19.3542 13.1875Z"
			fill="#F4F4F4"
		/>
	</svg>
);

export default SvgIconExpand;
