// import { Switch } from "antd";
import { Button, Dropdown, Menu, Switch, Tag } from "antd";
import { Link } from "react-router-dom";
import { createUser } from "src/components/commonRender/renderData";
import DIcon from "src/components/icons/DIcon";
import routerNames from "src/utils/data/routerName";
import styled from "styled-components";

import Svg3Dot from "src/assets/svg/Svg3Dot";
import SvgPen from "src/assets/svg/SvgPen";

const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
export const columnsData = ({ updateStatus, getDetail }: any) => {
	const menu = (r: any) => (
	
		
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Button onClick={() => getDetail(r?.id)}>
							<SvgPen fill="black" />
							<span>Chỉnh sửa</span>
						</Button>
					)
				}
			]}
		/>
	);
	return [
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			width: 80,
			render: (v: number, r: any) => (
				<span className="text-blue-500 cursor-pointer pr-8" onClick={() => getDetail(v)}>
					{v}
				</span>
			)
		},
		{
			title: "Tên nhóm vai trò",
			dataIndex: "role_name",
			key: "role_name"
		},
		{
			title: "Ngày tạo",
			dataIndex: "created_at",
			key: "created_at",
			render: (created_at: any, record: any) => createUser(record?.created_by, created_at, record?.updated_at)
		},
		{
			title: "Trạng thái",
			dataIndex: "status",
			key: "status",
			align: "center",
			width: 100,
			render: (v: string, r: any) => (
				<div className="flex justify-center">
					<Switch checked={v === "A"} onChange={(status) => updateStatus(r, status)} />
				</div>
			)
		},
		{
			title: "Thao tác",
			dataIndex: "action",
			kye: "action",
			align: "center",
			width: 100,
			render: (v: string, r: any) => {
				return (
					<Link to={`/roles/${r?.id}`}>
						<DIcon
							icon="pen_box"
							className="flex align-center justify-center"
							// onClick={() => getDetail(r?.id)}
						/>
					</Link>
				);
			}
		},
		{
			title: "Sửa thông tin",
			dataIndex: "action",
			key: "action",
			align: "center",
			width: 100,
			fixed: "right",
			render: (v: string, r: any) => {
				return (
					<div className="flex justify-center">
						<Dropdown overlay={() => menu(r)} trigger={["hover"]} placement="bottomRight" arrow>
							{/* <Dropdown 
						menu={{ items: items(r) }} 
						overlayClassName="dropdown-css" trigger={["hover"]} placement="bottomRight" arrow> */}
							<Button icon={<Svg3Dot />}></Button>
						</Dropdown>
					</div>
				);
			}
		}
	];
};
