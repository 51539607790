/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { DEFAULT_FILTER, FLEX_FILTER_ELEMENTS, SITE_BASE, STATUS_BASE, STATUS_NEWS, THREAD_STATUS } from "src/constants";
import "./style.scss";
import { _paramsFilter, columnsData, columnsDataDetail } from "./data";
import TableStyled from "src/components/table/TableStyled";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { notifyError, notifySuccess } from "src/components/notification";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { Form, Modal, Switch, Typography } from "antd";
import SvgIconPlus from "src/assets/svg/SvgIconPlus";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import FormText from "src/components/form/FormText";
import { useHistory } from "react-router-dom";
interface DataType {
	key: React.ReactNode;
	name: string;
	age: number;
	address: string;
	children?: DataType[];
}
const ListNew = () => {
	let history = useHistory();
	const [form] = Form.useForm();
	const [data, setData] = useState<any>([]);
	const [paramsFilter, setParamsFilter] = useState<any>({ ..._paramsFilter });
	const [visible, setVisible] = useState<any>(false);
	const [params, setParams] = useState<any>(DEFAULT_FILTER);
	const [loading, setLoading] = useState<any>(false);
	const [total, setTotal] = useState(0);
	const [dataPostType, setDataPostType] = useState<any>([]);

	const [isEdit, setIsEdit] = useState<any>(false);
	const [idEdit, setIdEdit] = useState<any>();
	const [visibleCancel, setVisibleCancel] = useState<any>(false);
	const [paramsCancel, setParamsCancel] = useState<any>({});
	const [account, setAccount] = useState<any>({});
	const [detailCreate, setDetailCreate] = useState<any>({});
	const [detail, setDetail] = useState<any>({});


		// Lấy thông tin user
		useEffect(() => {
			const init = async () => {
				try {
					const userData = await localStorage.getItem("ACCOUNT");
					if (userData) {
						setDetailCreate(JSON.parse(userData).user);
					}
					setLoading(false);
					// setIsUserDataLoaded(true); // Indicate that user data has been loaded
				} catch (error: any) {
					setLoading(false);
					notifyError(error.message);
				}
			};
			init();
		}, []);

	const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	const onEdit = (id: any) => {
		setIsEdit(true);
		setVisible(true);
		setIdEdit(id);
	};
	const onCancel = (id: any, isCancel: boolean) => {
		if (isCancel) {
			// updateStatementTripCost(id, { status_cancel: "C" });
		} else {
			setVisibleCancel(true);
			paramsCancel.id = id;
			paramsCancel.status = "R";
			setParamsCancel({ ...paramsCancel });
		}
	};

	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getListNews(params);	
				setData(res?.threadList);
				setTotal(res?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, [params]);//eslint-disable-line

	const onSuccess = () => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getListNews({});
				setData(res?.threadList);
				setTotal(res?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			} 
		};
		init();
	};
	const onCreateThread = async (val: any) => {
		const _params = {
			...val,
			// status: status ? "A" : "D"
			user_id: detailCreate.id
		};
		try {
			const res: any = !detail?.id
				? await _apiNotSaga.createThread(_params)
				: await _apiNotSaga.updateRole(detail.id, _params);
			notifySuccess(res.message);
			setVisible(false);
			setParams({
				...params,
				page: 1
			});
		} catch (error: any) {
			notifyError(error.message);
		}		
	};
	const refuseHandle = async (id: number) => {
		let _params = {
			status_id: "2",
			reject_id: detailCreate.id,
			approved_id: detailCreate.id,
			thread_id:id,
			thread_action:"PUT",
			note:`User ${detailCreate?.email} update status "Tin từ chối" on Thread have id: ${id}`,
		};
		try {
			const res: any = await _apiNotSaga.updatePostThread(id, _params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	}
	const violationHandle = async (id: number) => {
		let _params = {
			status_id: "5",
			approved_id: detailCreate.id,
			thread_id:id,
			thread_action:"PUT",
			note:`User ${detailCreate?.email} update status "Tin vi phạm" on Thread have id: ${id}`,
		
		};
		try {
			const res: any = await _apiNotSaga.updatePostThread(id, _params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	}
	const newsExpiredHandle = async (id: number) => {
		let _params = {
			status_id: "4",
			approved_id: detailCreate.id,
			thread_id:id,
			thread_action:"PUT",
			note:`User ${detailCreate?.email} update status "Đã hết hạn" on Thread have id: ${id}`,
		
		};
		try {
			const res: any = await _apiNotSaga.updatePostThread(id, _params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	}
	
	const pushUpApproval = async (id: number, userId: number, isPush: boolean) => {
		let _params = {
			status_id: "3",
			approved_id: detailCreate.id,
			thread_id:id,
			thread_action:"PUT",
			note:`User ${detailCreate?.email} update status "Phê duyệt" on Thread have id: ${id}`,

		};
		try {
			const res: any = await _apiNotSaga.updatePostThread(id, _params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const deleteHandle = async (id: number) => {
		try {
			const res: any = await _apiNotSaga.deleteNewsThread(id);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	}
	const onCreateTicket = (id: number) => {
		let _params = {
			ticket_id: id
		};
		// updateStatementTripCost(id, _params);
	};
	const onCreate = async () => {
		// setDetail({});
		// setVisible(true);
		history.push(`/create-new-thread`);
	};
	
	const onSearch: (val: any) => void = (val) => {
		const { params: flexParams } = val;
		const newParams = removeIdentity({
			...params,
			...flexParams,
			page: 1
		});
		setParams(newParams);
	};
	const handleActions: any = {
		// btnAdd: onCreate,
		btnSearch: onSearch
	};
	const actionsFilter = (val: any) => {
		const { idBtn } = val;
		handleActions[idBtn](val);
	};
	const cancelModal = () => {
		setVisible(false);
		form.resetFields();
	};

	return (
		<>
		{visible && (
				<Modal
					open={visible}
					// maskClosable={false}
					onCancel={cancelModal}
					footer={
						<div className="addStores__footer">
							<div style={{ display: "flex", alignItems: "center" }}></div>&nbsp;						
							<div style={{ display: "flex", alignItems: "center" }}></div>&nbsp;
							<button type="submit" form="roleForm" className="addStores__footer__btn editBtn">
								<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!detail.id ? "Tạo mới" : "Cập nhật"}</p>
							</button>
						</div>
					}
					title={<Typography.Title level={3}>{`${!detail.id ? "Tạo mới" : "Cập nhật"} bài đăng`}</Typography.Title>}
				>
					<Form form={form} name="roleForm" layout="vertical" onFinish={onCreateThread}>
					{!detail.id ? (
						<>
						<FormInput
							id="form-name"
							name="thread_name"
							label="Tên bài đăng:"
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Nhập tên bài đăng"
							required
							className="w-full"
						/>
							<FormInput
							id="form-name"
							name="price"
							label="Giá (Nếu có):"
							type="justNumber"
							size="large"
							placeholder="Thêm giá cho sản phẩm (nếu có)"
							className="w-full"
						/>			
						<FormInput
							id="form-name"
							name="short_desc"
							label="Mô tả ngắn:"
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Thêm mô tả ngắn"
							className="w-full"
						/>
						<FormInput
							id="form-name"
							name="address"
							label="Địa chỉ (Nếu có):"
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Thêm địa chỉ (nếu có)"
							className="w-full"
						/>
						
						</>
					) : (
						<>
						
					
						<FormInput
							id="form-name"
							name="role_name"
							label=""
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Nhập tên nhóm vai trò"
							required
							className="w-full"
						/>
						<FormSelect
									name="status_id"
									label="Chọn trạng thái tin"
									dataSource={THREAD_STATUS}
									placeholder="Chọn trạng thái tin "
									slOption={{
										name: "name",
										value: "id"
									}}
									// onChange={onChangeSite}
									showSearch
									optionFilterProp="children"
									required
								/>
						</>
					)}
					</Form>
				</Modal>
			)}
		<Loading spinning={loading}>
			<div className="contentSection">
				<div className="top-0 contentSection-filter flex flex-row justify-between">
					<FlexFilter
						onSearch={(v) => actionsFilter(v)}
						manipulation={[
							{
								paramsName: "btnAdd",
								icon: <PlusOutlined />,
								text: "Tạo mới"
							}		
						]}
						elements={[
							{
								...FLEX_FILTER_ELEMENTS.input,
								placeholder: "Nhập tên bài đăng cần tìm",
								label: "Tiêu đề",
								paramsName: "search"
							},
							
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Trạng thái",
								placeholder: "Chọn trạng thái",
								width: 160,
								options: {
									data: THREAD_STATUS,
									keyOption: "id",
									valueOption: "id",
									nameOption: "name"
								},
								paramsName: "status"
							},
							{
								...FLEX_FILTER_ELEMENTS.btnSearch,
								icon: <SearchOutlined />
							},
							
						
						]}
						
					/>
					<button className="flex border-[1px] border-solid border-[#0c0c0c] rounded-[8px] p-1 items-center" type="button" onClick={onCreate} ><SvgIconPlus fill="black" /> Tạo mới</button>
				</div>
				
				<hr className=" mb-[10px] border-neutral_color_1_2" />
				<div className="contentSection-main">
					<TableStyled
						rowKey="id"
						dataSource={data}
						bordered
						pagination={{
							locale: {
								items_per_page: "/Trang"
							},
							current: params.page,
							showSizeChanger: true,
							defaultPageSize: params.limit,
							total: total || 0,
							showTotal: (total: any, range: any) => (
								<div className="flex gap-2">
									<strong>
										{range[0]} - {range[1]}
									</strong>
									trong tổng
									<strong>{total}</strong>
								</div>
							),
							onChange: onChangePaging
						}}
						// scroll={{
						// 	x: data.length > 0 ? (columnsData({})?.length - 1) * 100 : null,
						// 	y: "calc(70vh - 80px)"
						// }}
						scroll={{
							x: (data && data.length > 0 && columnsData && columnsData.length > 0) 
								? (columnsData.length - 1) * 100 
								: null,
							y: "calc(70vh - 80px)"
						  }}
						isRowLight={true}
						columns={columnsData({ onEdit,violationHandle,newsExpiredHandle, deleteHandle, onCancel, pushUpApproval,refuseHandle, onCreateTicket, user: account }) as any}
						
					/>
				</div>
			</div>
		</Loading>
		</>
	);
};

export default ListNew;
