
import { IAction } from '../interfaces/root.interfaces';
import categoriesTypes from './types/categories.types';

export const getCategories = (params?: any): IAction => {
  const action: IAction = {
    type: categoriesTypes.START_GET_LIST_CATEGORY,
    payload: { params },
  }
  return action;
}

export const getCategoriesDetail = (id?: any): IAction => {
  const action: IAction = {
    type: categoriesTypes.START_GET_CATEGORY_BY_ID,
    payload: { id },
  }
  return action;
}
export const getCategoriesDetailChildren = (id?: any): IAction => {
  const action: IAction = {
    type: categoriesTypes.START_GET_CATEGORY_BY_ID_CHILDREN,
    payload: { id },
  }
  return action;
}