import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces";
import { actionFailure, actionSuccess } from "../root.actions";

import globalTypes from "../types/global.types";

function* putSignIn(action: IAction) {
	const { payload } = action;
	yield put({
		type: globalTypes.PUT_SIGN_IN_APP_SUCCESS,
		payload: { data: payload.data }
	});
}

function* getEnum(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getEnum(payload?.params)
		});
		yield put(actionSuccess(globalTypes.GET_ENUMS_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(globalTypes.GET_ENUMS_SUCCESS, err));
	}
}

function* getProvinces(action: IAction) {
	try {
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return _apiNotSaga.getProvinces(payload?.params)
		});
		yield put(actionSuccess(globalTypes.GET_PROVINCES_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(globalTypes.GET_PROVINCES_FAILURE, err));
	}
}

function* putAction(action: IAction) {
	const { payload } = action;
	yield put({
		type: globalTypes.PUT_ACTIONS_SUCCESS,
		payload: { data: payload.data.data }
	});
}

const globalSagas = [
	takeLeading(globalTypes.START_PUT_SIGN_IN_APP, putSignIn),
	takeLatest(globalTypes.START_GET_ENUMS, getEnum),
	takeLatest(globalTypes.START_GET_PROVINCES, getProvinces),
	takeLatest(globalTypes.START_PUT_ACTIONS, putAction),
];
export default globalSagas;
