import { IAction } from '../interfaces/root.interfaces';
import globalTypes from './types/global.types';

// export const changeTheme = (theme: IThemeName) => ({
//   type: globalTypes.CHANGE_THEME,
//   payload: theme,
// });

export const otherAction = (params: any): IAction => ({
  type: globalTypes.CHANGE_THEME,
  payload: { params }
});

export const getEnum = (params: any): IAction => ({
  type: globalTypes.START_GET_ENUMS,
  payload: { params }
});

export const getProvinces = (params?: any): IAction => ({
  type: globalTypes.START_GET_PROVINCES,
  payload: { params }
});

export const putAction = (data: any): IAction => ({
  type: globalTypes.START_PUT_ACTIONS,
  payload: { data }
});

export const putSignIn = (data: any): IAction => ({
  type: globalTypes.START_PUT_SIGN_IN_APP,
  payload: { data }
});
