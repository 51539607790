import { DownloadOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgMoreOutLined from "src/components/Svg/SvgMoreOutLined";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import PanigationAntStyled from "src/components/panigation/PanigationAntStyled";
import TableStyled from "src/components/table/TableStyled";
import { DEFAULT_FILTER, FLEX_FILTER_ELEMENTS, STATUS_BASE } from "src/constants";
import { exportReportUser } from "src/services/actions/export.actions";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { useScrollYTable } from "src/utils/helpers/functions/useScrollYTable";
import UserModal from "./UserModal";
import { columnsData } from "./data";

const Pages = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [params, setParams] = useState<any>(DEFAULT_FILTER);
	const [data, setData] = useState<any>([]);
	const [detail, setDetail] = useState<any>({});
	const [total, setToal] = useState<number>(0);
	const [userTypes, setUserTypes] = useState<any>([]);
	const isMount = useIsMount();
	const [callback, setCallback] = useState<boolean>(false);
	const dispatch = useDispatch();
	const [visible, setVisible] = useState<boolean>(false);
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const { stateGetRoles } = useSelector((e: AppState) => e.rolesReducer);
	const scrollY = useScrollYTable();
	const [dataBanner, setDataBanner] = useState<any>([]);

	useEffect(() => {
		// if (stateGetUserTypes?.data) {
		// 	setUserTypes(stateGetUserTypes?.data?.data);
		// } else {
		// 	dispatch(getUserTypes());
		// }
		// if(!stateGetOrgs?.data) {
		// 	dispatch(getOrgs({}));
		// }
		// if(!stateGetRoles?.data) {
		// 	dispatch(getRoles({status:"A"}));
		// }
	}, [dispatch]); //eslint-disable-line

	// useEffect(() => {
	// 	if (isMount) return;
	// 	const { success, data, error } = stateGetUserTypes;
	// 	if (success) {
	// 		setUserTypes(data?.data);
	// 		return;
	// 	}
	// 	if (success === false || error) {
	// 		return notifyError(`${error}`);
	// 	}
	// }, [stateGetUserTypes.isLoading]);//eslint-disable-line
	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getPage(params);
				setData(res?.data);
				setToal(res?.paging?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
			const initpage = async () => {
				try {
					// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
					setLoading(true);
					// const ress: any = await _apiNotSaga.getPage({ site: res?.data?.site });
					const ress: any = await _apiNotSaga.getBanner({});
					setDataBanner(ress?.data);
					// const ress: any = await _apiNotSaga.getPage({ });

					// setTotal(res?.paging?.total);
					setLoading(false);
				} catch (error: any) {
					setLoading(false);
					notifyError(error.message);
				}
			};
			initpage();
		};
		init();
	}, [params, callback, stateGetOrgs.isLoading, stateGetRoles.isLoading]); //eslint-disable-line
	console.log("data", data);
	const onCreate: () => void = () => {
		setDetail({});
		setVisible(true);
	};
	const onSearch: (val: any) => void = (val) => {
		const { params: flexParams } = val;
		const newParams = removeIdentity({
			...params,
			...flexParams,
			page: 1
		});
		setParams(newParams);
	};
	const onExport: () => void = () => {
		dispatch(exportReportUser(params));
	};

	const handleActions: any = {
		btnAdd: onCreate,
		btnSearch: onSearch,
		btnExport: onExport
	};
	const actionsFilter = (val: any) => {
		const { idBtn } = val;
		handleActions[idBtn](val);
	};
	const updateStatus = async (r: any, status: number, type: string) => {
		const mapStatus: any = {
			status: status ? "A" : "D",
			locked: status ? "Y" : "N"
		};
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.updateUser(r?.id, { [type]: mapStatus[type] });
			notifySuccess(res.message);
			setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	const cancelModal = () => {
		setVisible(false);
	};
	const getDetail = async (id: number) => {
		setDetail({ id });
		setVisible(true);
	};
	const onSuccessModal = () => {
		setCallback(!callback);
		setVisible(false);
	};
	return (
		<>
			{visible && <UserModal visible={visible} detail={detail} onCancel={cancelModal} onSuccess={onSuccessModal} />}
			<div className="contentSection">
				<Loading spinning={loading}>
					<div className="top-0 contentSection-filter">
						<FlexFilter
							onSearch={(v) => actionsFilter(v)}
							elements={[
								// {
								// 	...FLEX_FILTER_ELEMENTS.input,
								// 	paramsName: "search",
								// 	placeholder: "Mã ticket",
								// 	width: 150,
								// },
								{
									...FLEX_FILTER_ELEMENTS.select,
									label: "Trang hiển thị",
									placeholder: "Chọn Trang hiển thị",
									paramsName: "id",
									width: 150,
									options: {
										data: data,
										keyOption: "id",
										valueOption: "id",
										nameOption: "page_name"
									}
								},
								// {
								// 	...FLEX_FILTER_ELEMENTS.select,
								// 	label: "Loại banner",
								// 	placeholder: "Chọn Loại banner",
								// 	paramsName: "pos",
								// 	width: 150,
								// 	options: {
								// 		data: dataBanner,
								// 		keyOption: "pos",
								// 		valueOption: "pos",
								// 		nameOption: "pos"
								// 	}
								// },
								{
									...FLEX_FILTER_ELEMENTS.select,
									label: "Trạng thái",
									placeholder: "Chọn trạng thái",
									paramsName: "status",
									width: 150,
									options: {
										data: STATUS_BASE,
										keyOption: "id",
										valueOption: "id",
										nameOption: "name"
									}
								},
								// {
								// 	...FLEX_FILTER_ELEMENTS.input,
								// 	paramsName: "search",
								// 	placeholder: "Tên banner",
								// 	width: 150
								// },
								// {
								// 	...FLEX_FILTER_ELEMENTS.select,
								// 	label: "TT hoạt động",
								// 	placeholder: "Chọn TT hoạt động",
								// 	paramsName: "user_type_id",
								// 	width: 180,
								// 	options: {
								// 		data: userTypes,
								// 		keyOption: "id",
								// 		valueOption: "id",
								// 		nameOption: "user_type_name"
								// 	}
								// },
								// {
								// 	...FLEX_FILTER_ELEMENTS.select,
								// 	label: "Ngành nghề",
								// 	placeholder: "Chọn ngành nghề",
								// 	paramsName: "user_type_id",
								// 	width: 180,
								// 	options: {
								// 		data: userTypes,
								// 		keyOption: "id",
								// 		valueOption: "id",
								// 		nameOption: "user_type_name"
								// 	}
								// },
								// {
								// 	...FLEX_FILTER_ELEMENTS.select,
								// 	label: "Chức danh",
								// 	placeholder: "Chọn chức danh",
								// 	paramsName: "user_type_id",
								// 	width: 180,
								// 	options: {
								// 		data: userTypes,
								// 		keyOption: "id",
								// 		valueOption: "id",
								// 		nameOption: "user_type_name"
								// 	}
								// },
								// {
								// 	...FLEX_FILTER_ELEMENTS.createdRange,
								// 	initValue: FLEX_FILTER_CONFIGS.initTime.optional.result,
								// 	initSelectDate: FLEX_FILTER_CONFIGS.initTime.optional.title,
								// 	width: 250
								// },
								{
									...FLEX_FILTER_ELEMENTS.btnSearch,
									icon: <SearchOutlined />,
									width: 128
								},
								{ ...FLEX_FILTER_ELEMENTS.btnAction, icon: <SvgMoreOutLined />, width: 120 }
								// {
								// 	...FLEX_FILTER_ELEMENTS.btn,
								// 	icon: <SvgIconPlus fill="black" />,
								// 	width: 128,
								// 	text: "Tạo mới",
								// 	paramsName: "btnAdd",
								// 	style: {
								// 		color: "black",
								// 		fontWeight: 600
								// 	}
								// }
							]}
							manipulation={[
								{
									paramsName: "btnAdd",
									icon: <PlusOutlined />,
									text: "Tạo mới"
								},
								{
									paramsName: "btnExport",
									icon: <DownloadOutlined />,
									text: "Tải báo cáo"
								}
							]}
						/>
					</div>
					<hr className=" mb-[10px] border-neutral_color_1_2" />
					<div className="contentSection-main">
						<TableStyled
							rowKey="id"
							dataSource={data}
							bordered
							pagination={false}
							isRowLight={true}
							columns={columnsData({ updateStatus, getDetail }) as any}
							scroll={{
								x: data?.length > 0 ? (columnsData({}).length - 1) * 100 : null,
								y: scrollY
							}}
						/>
						<PanigationAntStyled
							style={{ marginTop: "8px" }}
							current={params.page}
							pageSize={params.limit}
							showSizeChanger
							onChange={onChangePaging}
							total={total}
						/>
					</div>
				</Loading>
			</div>
		</>
	);
};

export default Pages;
