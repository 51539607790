// import SvgIconBell from "src/assets/svg/SvgIconBell";
import { useDispatch, useSelector } from "react-redux";
import IconAvatar from "src/assets/images/avatar.png";
import SvgIconExpand from "src/assets/svg/SvgIconExpand";
import colors from "src/utils/colors";
import { localGetAccount } from "src/utils/localStorage";
// import SvgGroup from "src/assets/svg/layout/SvgGroup";
import SignOutIcon from "../../assets/images/SignOut.svg";
import UserIcon from "../../assets/images/UserIcon.svg";

// import SvgWheel from "src/assets/svg/layout/SvgWheel";
import { ONESIGNAL_KEY } from "src/services/api/config";

import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation  } from "react-router-dom";
import { signOut } from "src/services/actions/account.actions";
import SvgLogoNTL from "../Svg/SvgLogoNTL";
import { AppState } from "src/types";
import routerNames from "src/utils/data/routerName";
import { putAction } from "src/services/actions/global.actions";
import OneSignal from "react-onesignal";
import SvgDowload from "../Svg/SvgDowload";
import SvgNoti from "../Svg/SvgNoti";
import { Badge, Modal, Popover } from "antd";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import SvgDot from "../Svg/SvgDot";
import Icon2102 from "../Svg/Icon2102";
import Icon2103 from "../Svg/Icon2103";
import SvgErrApi from "../Svg/SvgErrApi";
import { renderCell } from "../commonRender/renderData";
import TableStyled from "../table/TableStyled";
import SvgSearch from "../Svg/SvgSearch";
import SvgSearchHeader from "../Svg/SvgSearchHeader";
import { notifyError } from "../notification";

type SetStateAction<S> = S | ((prevState: S) => S);
type Dispatch<A> = (value: A) => void;
interface ITypes {
	onOpenMenu: Dispatch<SetStateAction<any>>;
	submitSearch?: (val: any) => void;
	isOpenMenu?: boolean;
}

const Header = (props: ITypes) => {
	
	const { onOpenMenu, submitSearch, isOpenMenu } = props;
	const [visiblePopover, setVisiblePopover] = useState(false);
	const [routes, setRoutes] = useState<any>([]);
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const [user, setUser] = useState<any>({});
	const [menu, setMenu] = useState<any>([]);
	const [count, setCount] = useState<any>(0);
	const [dataNoti, setDataNoti] = useState<any>([]);
	const [params, setParams] = useState<any>({ page: 1, limit: 10 });
	const [limit, setLimit] = useState<any>(10);
	const [visible, setVisible] = useState<any>(false);
	const [dataLogAPI, setDataLogAPI] = useState<any>([]);
	const history = useHistory();
	const { stateUpdatePageHeader } = useSelector((e: AppState) => e.pageHeaderReducer);
	let { pathname } = useLocation();
	pathname = pathname.replace("/", "");
	const wrapperRef = useRef<any>(null);
	useEffect(() => {
		let USER_INFO: any = localGetAccount();
		initPushNoti(JSON.parse(USER_INFO)?.userId, JSON.parse(USER_INFO)?.userCode);
		getListLog();
	}, []);
	const getListLog = () => {
		_apiNotSaga.getListLogPartner({ page: 1, limit: 20 }).then((result: any) => {
			if (result.success && result?.data) {
				setDataLogAPI([...result?.data?.data]);
			}
		});
	};
// console.log(stateUpdatePageHeader)
	const initPushNoti = (userId: any, user_erp_code: any) => {
		OneSignal.init({
			appId: ONESIGNAL_KEY,
			allowLocalhostAsSecureOrigin: true,
			autoRegister: true,
			autoResubscribe: true
		});
		OneSignal.showSlidedownPrompt();
		setTimeout(() => {
			OneSignal.isPushNotificationsEnabled().then((enable) => {
				if (!enable) {
				}
				OneSignal.registerForPushNotifications({
					// modalPrompt: true,
					httpPermissionRequest: true,
					autoAccept: true
				}).then((res) => console.log("registerForPushNotifications", res));
				OneSignal.getUserId().then((playerId) => {
					playerId &&
						OneSignal.sendTags({
							APP: "TMS_NTL",
							user_id: userId,
							user_erp_code: user_erp_code
						}).then((res) => console.log("sendTags", res));
					OneSignal.setSubscription(true).then((res) => console.log("setSubscription", res));
				});
				OneSignal.on("notificationDisplay", function (notificationDisplay) {});
				OneSignal.on("notificationDismiss", function (notificationDismiss) {
					// console.log("notificationDismiss", notificationDismiss);
				});
			});
		}, 3000);
	};
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: Event) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setShow(false);
			}
		}
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef]);

	useEffect(() => {
		if (!pathname) {
			setRoutes([]);
		} else if (menu && menu.length) {
			let _routes: any = [
				{
					path: "/",
					name: "Trang chủ"
				}
			];
			pathname = pathname.split("/")[0];
			const _find = menu.find((e: any) => e.children?.find((x: any) => pathname === x?.ui_route?.split("/")[1]));
			// console.log("menu", menu);
			// console.log("pathname", pathname);
			// console.log("_find", _find);
			if (_find) {
				const _findChild = _find.children.find((e: any) => pathname === e?.ui_route?.split("/")[1]);
				// const _action = _findChild.children.filter((e: any) => e.action).map((e: any) => e.funct_code);
				// dispatch(putAction({ data: _action }));
				_routes = [
					..._routes,
					{
						name: _find.funct_name
					},
					{
						path: _findChild.ui_route,
						name: _findChild.funct_name
					}
				];
			} else {
				const check = checkSpecialPath(pathname)
				if(!check){
				  history.push({
				    pathname: 'not-found',
				  });
				}
				// history.push({
				// 	pathname: "not-found"
				// });
			}
			setRoutes(_routes);
		}
	}, [pathname, menu]);

	useEffect(() => {
		getNoti(params);
	}, [params]);
	const getNoti = (param: any) => {
		let USER_INFO: any = localGetAccount();
		_apiNotSaga
			.getListNotifications({ ...param, user_id: JSON.parse(USER_INFO)?.userId, object_type: "TMS" })
			.then((result: any) => {
				if (result.success && result?.data) {
					setCount(result?.data?.page?.total || 0);
					setDataNoti([...result?.data?.data]);
				}
			});
	};

	const readNoti = (id: number) => {
		_apiNotSaga.updateNoti(id, { read: 1 }).then((result: any) => {
			if (result.success) {
				getNoti(params);
			}
		});
	};

	// const checkSpecialPath = (path: string) => {/* đối với các path lv3 (các trang chi tiết) => thì phải cấu hình ở đây để tránh bị
	//   đẩy vào trang not-found vì dưới api chỉ cấu hình menu đến lv2*/
	//   const result = [
	//     routerNames.DRIVER,routerNames.MAINTENANCE,routerNames.MOUDULE_FUNCT,routerNames.ROLES,
	//     routerNames.TRIPS_MANAGEMENT,routerNames.VEHICLE_MANAGEMENT_EDIT, routerNames.REPORTPRODUCTIVITY
	//   ].some((val) => {
	//     const find = `/${path}/`.indexOf(val);
	//     if(find !== -1) return true;
	//     return false
	//   })

	//   return result;
	// }

	const checkSpecialPath = (path: string) => {/* đối với các path lv3 (các trang chi tiết) => thì phải cấu hình ở đây để tránh bị
	  đẩy vào trang not-found vì dưới api chỉ cấu hình menu đến lv2*/
	  const result = [
	    routerNames.DRIVER,routerNames.MAINTENANCE,routerNames.MOUDULE_FUNCT,routerNames.ROLES,
	    routerNames.TRIPS_MANAGEMENT,routerNames.VEHICLE_MANAGEMENT_EDIT, routerNames.REPORTPRODUCTIVITY,routerNames.LOCATION_LIST,
		routerNames.CREATEPROVINCE,routerNames.WARD_LIST,routerNames.CREATE_WARD,routerNames.CREATE_DISTRICT, routerNames.PROFILE,
		routerNames.CREATE_THREAD
	  ].some((val) => {
	    const find = `/${path}/`.indexOf(val);
	    if(find !== -1) return true;
	    return false
	  })

	  return result;
	}

	useEffect(() => {
		setRoutes(stateUpdatePageHeader.data);
	}, [stateUpdatePageHeader]);

	useEffect(() => {
		const _account = localGetAccount();
		if (_account) {
			const { features, menu: _menu, ..._user } = JSON.parse(_account);
			setMenu(_menu);
			setUser(_user);
		}
	}, [localGetAccount]);

	const btnSignOut = () => {
		setShow(false);
		const initcareer = async () => {
			// try {
			// 	// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
			// 	// setLoading(true);
			// 	const res: any = await _apiNotSaga.logOut({});
			// 	// setDataCareer(res?.data);
			// 	dispatch(signOut({ history }));
			// 	// setLoading(false);
			// } catch (error: any) {
			// 	// setLoading(false);
			// 	notifyError(error.message);
			// }
			try {
				localStorage.removeItem('ACCOUNT');
				localStorage.removeItem('TOKEN');
				localStorage.removeItem('ACCOUNT_LOGIN');
				// Xóa các dữ liệu khác nếu cần
				localStorage.clear(); // Xóa tất cả các mục trong localStorage (tùy chọn)
		
				// Điều hướng người dùng đến trang đăng nhập hoặc trang chủ
				history.push('/sign-in'); // Điều hướng đến trang đăng nhập
			} catch (error: any) {
				// setLoading(false);
				notifyError(error.message);
			}
		};
		initcareer();
		
	};
	const btnProfile = () => {
	  setShow(false);
	  history.push(`/profile`);
	};
	const content = (
		<div style={{ width: 300, maxHeight: 300, overflow: "auto", padding: "0 5px" }}>
			{dataNoti &&
				dataNoti.length > 0 &&
				dataNoti.map((item: any) => (
					<Link
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							padding: "5px",
							border: `1px solid ${item.read === 0 ? "#F4CA64" : "#F2F2F2"}`,
							marginBottom: "10px",
							borderRadius: "10px"
						}}
						to={`/${item.deeplink}`}
						onClick={() => {
							readNoti(item.id);
						}}
					>
						{item.read === 0 ? (
							<div style={{ background: "#FDF4D7", padding: "5px", borderRadius: "5px" }}>
								<Icon2102 />
							</div>
						) : (
							<div style={{ background: "#F2F2F2", padding: "5px", borderRadius: "5px" }}>
								<Icon2103 />
							</div>
						)}
						<span style={{ fontSize: "15px", color: "#808A94", marginLeft: "10px", fontWeight: "400" }}>
							{item.content}
						</span>
					</Link>
				))}
			<span
				style={{
					fontSize: "15px",
					color: "#00A3FF",
					fontWeight: "500",
					display: "flex",
					justifyContent: "center",
					cursor: "pointer"
				}}
				onClick={() => {
					_handleDataNoti();
				}}
			>
				Xem thêm
			</span>
		</div>
	);
	const _handleDataNoti = () => {
		setParams({ page: 1, limit: limit + 10 });
		setLimit(limit + 10);
	};
	const [billCode, setBillCode] = useState<any>("");
	const _renderProfile = () => {
		// const _name = user?.user?.full_name || "Tài khoản";
		const _name = user?.user?.email || "Tài khoản";
		// console.log(user)
		return (
			<div className="flex text-white">
				<div className="mt-0 ml-[-20%] flex text-white">
					{/* <div className="flex bg-white input-header">
						<input
							placeholder="Mã vận đơn"
							onChange={(e:any) => {
								setBillCode(e.target.value);
							}}
							onKeyDown={(e:any) => {
								if(e.key === 'Enter'){
									history.push(`/bill-journey/${billCode}`)
								}
							}}
						/>
						<span onClick={() => history.push(`/bill-journey/${billCode}`)} style={{cursor:'pointer'}}>
							<SvgSearchHeader />
						</span>
					</div>
					<div
						style={{ marginRight: "10px" }}
						onClick={() => {
							getListLog();
							setVisible(true);
						}}
					>
						<SvgErrApi />
					</div> */}

					<Popover
						placement="bottom"
						content={content}
						trigger="click"
						onOpenChange={() => {
							getNoti(params);
						}}
					>
						<Badge count={count}>
							<SvgNoti />
						</Badge>
					</Popover>
				</div>
				<div className="flex flex-row justify-evenly px-2 ">
					{/* <div
            className="flex flex-row items-center rounded-md justify-center px-3 py-1 cursor-pointer"
            style={{ backgroundColor: colors.primary_color_2_1 }}
          >
            <div>
              <SvgWheel />
            </div>
            &nbsp;&nbsp;
            <div className="operationManual">Hướng dẫn sử dụng</div>
          </div>

          <div className="flex items-center ml-6">
            <SvgIconBell className="cursor-pointer" />
          </div> */}
					<div className="flex text-white items-center justify-around ml-4">
						<button
							className="flex items-center ml-2"
							style={{ position: "relative" }}
							onClick={() => setShow((pre) => !pre)}
							ref={wrapperRef}
						>
							<img src={IconAvatar} className="mr-2" alt="avatarUser" />
							<p className="text-16 text-white text-neutral_color_1_8">{_name}</p>
							{show && (
								<div
									className="flex flex-col"
									style={{
										position: "absolute",

										top: "calc(100%)",
										right: "0"
									}}
								>
									<div
										style={{
											background: "#fff",
											padding: "8px 16px",
											width: "200px",
											marginTop: "12px",
											
										}}
									>
										{/* <button
          onClick={btnChangePass}
          className="flex items-center justify-start my-1"
        >
          <SvgIconKey />
          <p className="text-14 px-2">Đổi mật khẩu</p>
        </button> */}
										<button
											onClick={btnProfile}
											className="flex items-center justify-start my-1 "
										>
											<img src={UserIcon} alt="" />
											<p className="text-14 px-2">{_name}</p>
										</button>
										<button onClick={btnSignOut} className="flex items-center justify-start my-1">
											<img src={SignOutIcon} alt="" />
											<p className="text-14 px-2">Đăng xuất</p>
										</button>
									</div>
								</div>
							)}
						</button>

						{/* <div className="userName ml-1">Admin</div> */}
					</div>
				</div>
			</div>
		);
	};
	const columnsDataAPI = ({}: any) => {
		return [
			{
				title: "Partner_GPS",
				dataIndex: "partner",
				key: "partner",
				render: (val: string) => {
					return renderCell([
						{
							content: val
						}
					]);
				}
			},
			{
				title: "API",
				dataIndex: "api_name",
				key: "api_name",
				render: (val: string) => {
					return renderCell([
						{
							content: val
						}
					]);
				}
			},
			{
				title: "Biển số xe",
				dataIndex: "object_name",
				key: "object_name",
				render: (val: string) => {
					return renderCell([
						{
							content: val
						}
					]);
				}
			},
			{
				title: "Http-Code",
				dataIndex: "http_code",
				key: "http_code",
				render: (val: string) => {
					return renderCell([
						{
							content: val
						}
					]);
				}
			},
			{
				title: "Message",
				dataIndex: "message",
				key: "message",
				render: (val: string) => {
					return renderCell([
						{
							content: val
						}
					]);
				}
			},
			{
				title: "Date",
				dataIndex: "created_at",
				key: "created_at",
				render: (val: string) => {
					return renderCell([
						{
							content: val,
							type: "dateTime"
						}
					]);
				}
			}
		];
	};

	return (
		<div
			className="fixed w-full z-50 flex items-center justify-between top-0 left-0 right-0 border-b border-border px-2"
			style={{ height: 55, background: colors.primary_color_1_8, zIndex: "10000", color:"#ffff" }}
		>
			<div className="flex items-center h-full">
				<div className="contentHeader-left">
					<div
						className="flex items-center cursor-pointer"
						onClick={() => {
							onOpenMenu((prev: boolean) => !prev);
						}}
					>
						<SvgIconExpand />
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</div>
					<img src='/logo1.svg'  alt="logo" title="logo" />
					<div className="site-page-header sm:flex ml-10 text-white">
						{routes?.length > 0 &&
							routes?.map((x: any, index: any) => (
								<span className="flex text-white" key={index}>
									{index > 0 && <span>&nbsp;/</span>}&nbsp;
									{x.path ? (
										<Link to={`${x.path}`}>{index === routes?.length - 1 ? <b className="text-white">{x.name}</b> : <>{x.name}</>}</Link>
									) : (
										<div className="text-white">{x.name}</div>
									)}
								</span>
							))}
					</div>
					<div style={{ transform: "scale(0.5)", marginLeft: "-3rem" }}></div>
				</div>

				{/* <SvgGroup className="pl-6 pt-2 " /> */}
			</div>

			{_renderProfile()}
			{visible && (
				<Modal
					open={visible}
					centered
					title="Thông báo từ API đối tác"
					onCancel={() => setVisible(false)}
					footer={<div className="addStores__footer mr-5"></div>}
					className="tms-not-footer"
					width={1000}
				>
					<TableStyled
						rowKey="id"
						dataSource={dataLogAPI}
						bordered
						pagination={false}
						columns={columnsDataAPI({}) as any}
						scroll={{ y: "calc(65vh)" }}
					/>
				</Modal>
			)}
		</div>
	);
};

export default Header;
