import { Avatar, Button, Dropdown, Popconfirm, Popover, Progress, Switch, Tag } from "antd";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import SvgIcon00060 from "src/assets/svg/SvgIcon00060";
import SvgIcon00061 from "src/assets/svg/SvgIcon00061";
import SvgIcon00139 from "src/assets/svg/SvgIcon00139";
import SvgIcon00178 from "src/assets/svg/SvgIcon00178";
import { renderCell } from "src/components/commonRender/renderData";
import DIcon from "src/components/icons/DIcon";
import { TICKET_URL } from "src/services/api/config";
import colors from "src/utils/colors";
import { numberWithCommas } from "src/utils/helpers/functions/textUtils";
import "./style.scss";
import { Link } from "react-router-dom";
import moment from "moment";
import { STATUS_COLOR_NEW } from "src/constants";
export const columnsData = ({
	setOpenModalLoadReport,
	setItems,
	onEdit,
	onCancel,
	pushUpApproval,
	refuseHandle,
	onCreateTicket,
	user,
	violationHandle,
	newsExpiredHandle,
	deleteHandle
}: any) => {
	const menu = (record: any) => (
		<div style={{ background: "#DFE2E4", padding: "5px 10px", minWidth: "200px", borderRadius: "5px" }}>
			{/* {["O", "R"].includes(record?.status) ? ( */}
			{record.status_id =='2' || record.status_id == '3'? (
			""
			):(<div>
				<Popconfirm
					title="Bạn có muốn từ chối tin này hay không?"
					style={{ borderRadius: "10px" }}
					okButtonProps={{
						style: {
							background: "black",
							color: "#fdd800"
						}
					}}
					cancelButtonProps={{
						style: {
							background: "#dfe2e4",
							color: "black"
						}
					}}
					onConfirm={() => {
						refuseHandle(record?.id);
					}}
					okText="Đồng ý"
					cancelText="Hủy"
				>
					<div className="menu-item">
						<SvgIcon00139 />
						<div className="menu-item-content">
							<p>Từ chối</p>
						</div>
					</div>
				</Popconfirm>
			</div>)}
			{/* ) : null} */}
			{/* {record?.status === "P" && record?.approval_id === user?.userId ? ( */}
			{record.status_id =='3' || record.status_id =='2' ? (
			""
			):(
				<div>
				<Popconfirm
					title="Bạn có muốn phê duyệt hay không?"
					style={{ borderRadius: "10px", zIndex: 10000 }}
					okButtonProps={{
						style: {
							background: "black",
							color: "#fdd800"
						}
					}}
					cancelButtonProps={{
						style: {
							background: "#dfe2e4",
							color: "black"
						}
					}}
					onConfirm={() => {
						pushUpApproval(record.id, record.approval_id, false);
					}}
					okText="Đồng ý"
					cancelText="Hủy"
				>
					<div className="menu-item">
						<SvgIcon00061 />
						<div className="menu-item-content">
							<p>Phê duyệt</p>
						</div>
					</div>
				</Popconfirm>
				
				
			</div>
			)}
			<div>
				<Popconfirm
					title="Bạn có muốn báo cáo tin tức này vi phạm hay không?"
					style={{ borderRadius: "10px" }}
					okButtonProps={{
						style: {
							background: "black",
							color: "#fdd800"
						}
					}}
					cancelButtonProps={{
						style: {
							background: "#dfe2e4",
							color: "black"
						}
					}}
					onConfirm={() => {
						violationHandle(record?.id);
					}}
					okText="Đồng ý"
					cancelText="Hủy"
				>
					<div className="menu-item">
						<SvgIcon00139 />
						<div className="menu-item-content">
							<p>Tin tức vi phạm</p>
						</div>
					</div>
				</Popconfirm>
			</div>
			<div>
				<Popconfirm
					title="Tin tức này đã hết hạn ?"
					style={{ borderRadius: "10px", zIndex: 10000 }}
					okButtonProps={{
						style: {
							background: "black",
							color: "#fdd800"
						}
					}}
					cancelButtonProps={{
						style: {
							background: "#dfe2e4",
							color: "black"
						}
					}}
					onConfirm={() => {
						newsExpiredHandle(record?.id);
					}}
					okText="Đồng ý"
					cancelText="Hủy"
				>
					<div className="menu-item">
						<SvgIcon00061 />
						<div className="menu-item-content">
							<p>Tin tức hết hạn</p>
						</div>
					</div>
				</Popconfirm>
			</div>
			<div>
				<Popconfirm
					title="Bạn có muốn xóa tin này hay không?"
					style={{ borderRadius: "10px", zIndex: 10000 }}
					okButtonProps={{
						style: {
							background: "black",
							color: "#fdd800"
						}
					}}
					cancelButtonProps={{
						style: {
							background: "#dfe2e4",
							color: "black"
						}
					}}
					onConfirm={() => {
						deleteHandle(record?.id);
					}}
					okText="Đồng ý"
					cancelText="Hủy"
				>
					<div className="menu-item">
						{/* <SvgIcon00061 /> */}
						<div className="menu-item-content">
							<p>Xóa tin </p>
						</div>
					</div>
				</Popconfirm>
				
			</div>
			{/* ) : null} */}
		</div>
	);
	return [
		{
			title: "Tiêu đề",
			align: "left",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<Link to={`/list-new/${record?.id}`}>
							<p className="text-sky-500 text-[13px] font-normal underline leading-snug">{record?.thread_name}</p>
						</Link>
						<Tag color={STATUS_COLOR_NEW[record?.status_id]}>
							{record?.status_id == "1"
								? "Tin mới"
								: record?.status_id == "2"
								? "Từ chối"
								: record?.status_id == "3"
								? "Đã duyệt"
								: record?.status_id == "4"
								? "Đã hết hạn"
								: "Tin vi phạm"} 
							
						</Tag>
						{record?.is_pin === '1' && <img src="/pin.svg" style={{ width: 20, height: 20 }} />}
					</div>
				);
			}
		},
		{
			title: "Thông tin",
			width: "15%",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start px-2">
						{/* <div className="text-black text-opacity-90 text-[13px] font-normal ">{record?.thread}</div> */}
						<div>
							<span className="text-black text-opacity-40 text-[13px] font-normal   leading-snug">Ngày đăng:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{moment(record?.created_at).format("DD/MM/YYYY")}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-40 text-[13px] font-normal   leading-snug">Loại tin:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{record?.slug}
							</span>
						</div>
					</div>
				);
			}
		},
		{
			title: "Mô tả ngắn",
			// width: "25%",
			render: (record: any, index: number) => {
				return <p className="text-black text-opacity-90 text-[13px] font-normal  ">{record?.short_desc}</p>;
			}
		},
		{
			title: "Giá",
			width: "7%",
			render: (record: any, index: number) => {
				return <p className="text-black text-opacity-90 text-[13px] font-normal  ">{record?.price}</p>;
			}
		},

		{
			title: "Người tạo",
			width: "20%",
			align: "left",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start px-2">
						<div className="text-black text-opacity-90 text-[13px] font-normal ">Người tạo: {record?.user_email}</div>
						<div>
							<span className="text-black text-opacity-40 text-[13px] font-normal   leading-snug">Ngày tạo:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{moment(record?.created_at).format("DD/MM/YYYY")}
							</span>
						</div>
						<div>
							<span className="text-black text-opacity-40 text-[13px] font-normal   leading-snug">Ngày cập nhật:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
								{" "}
								{moment(record?.updated_at).format("DD/MM/YYYY")}
							</span>
						</div>
					</div>
				);
			}
		},
		{
			title: "Người duyệt",
			align: "center",
			fixed: "right",
			// width: (record: any) => (record?.reject_id == '0' ? "0%" : "0%"),
			width:"10%",
		
			render: (record: any, index: number) => {
				return (
					// <Switch
					// 	checked={record?.approved_id}
					// 	// onChange={(value: boolean) => {
					// 	// 	onChangeStatus(record, value);
					// 	// }}
					// ></Switch>
					<div className="text-black text-opacity-90 text-[13px] font-normal ">{record?.approved_email}</div>
				);
			}
		},
		
		{
			title: "Người từ chối",
			align: "center",
			fixed: "right",
			// width: (record: any) => (record?.reject_id == '0' ? "0%" : "0%"),
			width:"10%",
		
			render: (record: any, index: number) => {
				return (
					// <Switch
					// 	checked={record?.approved_id}
					// 	// onChange={(value: boolean) => {
					// 	// 	onChangeStatus(record, value);
					// 	// }}
					// ></Switch>
					<div className="text-black text-opacity-90 text-[13px] font-normal ">{record?.reject_email}</div>
				);
			}
		},
		{
			title: "Thao tác",
			align: "center",
			fixed: "right",
			width: "5%",
			render: (text: string, record: any, index: number) => {
				return (
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Dropdown overlay={() => menu(record)} trigger={["hover"]} placement="bottomCenter">
							<Button icon={<Svg3Dot />}></Button>
						</Dropdown>
					</div>
				);
			}
		}
	];
};
export const columnsTransportTripExpenseData = ({ setOpenModalLoadReport, setItems }: any) => {
	return [
		{
			title: "Mã chuyến xe",
			render: (record: any, index: number) => {
				return <div>{record?.transport_trip?.transport_trip_code}</div>;
			}
		},
		{
			title: "Tuyến",
			render: (record: any, index: number) => {
				return <div>{record?.transport_trip?.routing_road?.routing_road_name}</div>;
			}
		},
		{
			title: "Biển số xe",
			render: (record: any, index: number) => {
				return <div>{record?.transport_trip?.vehicle?.license_plate_short}</div>;
			}
		},
		{
			title: "Thời gian",
			render: (record: any, index: number) => {
				return renderCell([
					{
						title: "Ngày bắt đầu",
						content: record?.transport_trip?.start_at || "",
						type: "dateTime"
					},
					{
						title: "Ngày kết thúc",
						content: record?.transport_trip?.end_at || "",
						type: "dateTime"
					}
				]);
			}
		},
		{
			title: "Tài xế phụ trách",
			render: (record: any, index: number) => {
				return renderCell([
					{
						title: "Tài xế chính",
						content: record?.transport_trip?.main_user?.profile?.full_name || ""
					},
					{
						title: "Tài xế phụ",
						content: record?.transport_trip?.sub_user?.profile?.full_name || ""
					}
				]);
			}
		},
		
		{
			title: "Tổng chi phí phát sinh (Vnđ)",
			align: "right",
			render: (record: any, index: number) => {
				return renderCell([
					{
						content: record?.costs_incurred || 0,
						type: "money"
					}
				]);
			}
		}
	];
};

export const columnsDataRow = ({ setOpenModalLoadReport, setItems, _handleImgBillClick }: any) => {
	return [
		{
			title: "Số HĐ/PT",
			align: "center",
			render: (record: any, index: number) => {
				return <div>{record?.invoice_number || "-"}</div>;
			}
		},
		{
			title: "MST NCC",
			render: (record: any, index: number) => {
				return <div>{record?.tax_code || "-"}</div>;
			}
		},
		{
			title: "Nhóm phí",
			render: (record: any, index: number) => {
				return <div>{record?.cost_category_name}</div>;
			}
		},
		{
			title: "Loại phí",
			render: (record: any, index: number) => {
				return <div>{record?.Cost_Type?.cost_type_name}</div>;
			}
		},
		{
			title: "Chi phí (Vnđ)",
			align: "center",
			render: (record: any, index: number) => {
				return renderCell([
					{
						content: record?.amount || 0,
						type: "money"
					}
				]);
			}
		},
		{
			title: "Hình ảnh hóa đơn",

			render: (record: any, index: number) => {
				return (
					<div className="grid-container-add">
						<u
							style={{ color: "#2F80ED", cursor: "pointer" }}
							onClick={() => {
								_handleImgBillClick(record?.imgs);
							}}
						>
							Xem ảnh
						</u>
						{/* {record?.imgs &&
							record?.imgs.length > 0 &&
							record?.imgs.map((img: any) => (
								<Image
									src={`${CND_URL}/${img.image_url}`}
									style={{ cursor: "pointer", height: "100px", width: "100px" }}
								/>
							))} */}
					</div>
				);
			}
		}
	];
};
export const columnsDataDetail = () => {
	return [
		{
			title: "Nhân viên",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div>
							<span className="text-black text-opacity-90 text-[13px] font-normal  ">
								{record?.mvn} {record?.name}
								<br />
							</span>
							<span className="text-black text-opacity-25 text-[13px] font-normal  ">Chức vụ:</span>
							<span className="text-black text-opacity-90 text-[13px] font-normal  "> {record?.chucvu}</span>
						</div>
					</div>
				);
			}
		},
		{
			title: "Thời gian",
			render: (record: any, index: number) => {
				return <div>{record?.time}</div>;
			}
		},

		{
			title: "Nội dung cập nhật",
			render: (record: any, index: number) => {
				return <div>{record?.noidung}</div>;
			}
		}
	];
};
export const columnsDataDetailEdit = ({ setOpenModalLoadReport, setItems, onDelete }: any) => {
	return [
		{
			title: "Mã chuyến xe",
			render: (record: any, index: number) => {
				return <div>{record?.transportTrip?.transport_trip_code}</div>;
			}
		},
		{
			title: "Tuyến",
			render: (record: any, index: number) => {
				return <div>{record?.transportTrip?.routing_road?.routing_road_name}</div>;
			}
		},
		{
			title: "Biển số xe",
			width: "7%",
			render: (record: any, index: number) => {
				return <div>{record?.transportTrip?.vehicle?.license_plate_short}</div>;
			}
		},
		{
			title: "Thời gian",
			render: (record: any, index: number) => {
				return renderCell([
					{
						title: "Ngày bắt đầu",
						content: record?.transportTrip?.start_at || "",
						type: "dateTime"
					},
					{
						title: "Ngày kết thúc",
						content: record?.transportTrip?.end_at || "",
						type: "dateTime"
					}
				]);
			}
		},
		{
			title: "Tài xế phụ trách",
			render: (record: any, index: number) => {
				return renderCell([
					{
						title: "Tài xế chính",
						content: record?.transportTrip?.main_user?.profile?.full_name || ""
					},
					{
						title: "Tài xế phụ",
						content: record?.transportTrip?.sub_user?.profile?.full_name || ""
					}
				]);
			}
		},
		// {
		// 	title: "Lương chuyến (Vnđ)",
		// 	width: "10%",
		// 	align: "right",
		// 	render: (record: any, index: number) => {
		// 		return renderCell([
		// 			{
		// 				content:
		// 					(record?.transportTrip?.expense &&
		// 						record?.transportTrip?.expense.length > 0 &&
		// 						record?.transportTrip?.expense[0]?.transport_trip_cost) ||
		// 					0,
		// 				type: "money"
		// 			}
		// 		]);
		// 	}
		// },
		// {
		// 	title: "Chi phí thưởng / phạt (Vnđ)",
		// 	width: "13%",
		// 	align: "right",
		// 	render: (record: any, index: number) => {
		// 		return renderCell([
		// 			{
		// 				title: "Thưởng",
		// 				content:
		// 					(record?.transportTrip?.expense &&
		// 						record?.transportTrip?.expense.length > 0 &&
		// 						record?.transportTrip?.expense[0]?.transport_bobus) ||
		// 					0,
		// 				type: "money"
		// 			},
		// 			{
		// 				title: "Phạt",
		// 				content:
		// 					(record?.transportTrip?.expense &&
		// 						record?.transportTrip?.expense?.length > 0 &&
		// 						record?.transportTrip?.expense[0]?.transport_penalty) ||
		// 					0,
		// 				type: "money"
		// 			}
		// 		]);
		// 	}
		// },
		{
			title: "Tổng chi phí phát sinh (Vnđ)",
			width: "13%",
			align: "right",
			render: (record: any, index: number) => {
				return renderCell([
					{
						content:
							(record?.transportTrip?.expense &&
								record?.transportTrip?.expense?.length > 0 &&
								record?.transportTrip?.expense[0]?.costs_incurred) ||
							0,
						type: "money"
					}
				]);
			}
		},
		{
			title: "Thao tác",
			dataIndex: "tt",
			key: "action",
			align: "center",
			fixed: "right",
			width: 120,
			render: (text: string, record: any, index: number) => {
				return (
					<div>
						<Popconfirm
							title="Bạn có muốn xóa hay không?"
							style={{ borderRadius: "10px" }}
							okButtonProps={{
								style: {
									background: "black",
									color: "#fdd800"
								}
							}}
							cancelButtonProps={{
								style: {
									background: "#dfe2e4",
									color: "black"
								}
							}}
							onConfirm={() => {
								onDelete(record.id);
							}}
							okText="Đồng ý"
							cancelText="Hủy"
						>
							<DIcon
								// onClick={() => {
								// 	onDelete(record.id);
								// }}
								icon="recycle_bin"
								className="flex align-center justify-center"
							/>
						</Popconfirm>
					</div>
				);
			}
		}
	];
};
export const columnsCostIncurred = ({ setOpenModalLoadReport, setItems, _handleImgBillClick }: any) => {
	return [
		{
			title: "Mã chuyến xe",
			render: (record: any, index: number) => {
				return <div>{record?.Transport_Trip?.transport_trip_code || "-"}</div>;
			}
		},
		{
			title: "Chi phí",
			render: (record: any, index: number) => {
				return <div>{record?.Cost_Category?.cost_name || "-"}</div>;
			}
		},
		{
			title: "Loại chi phí",
			render: (record: any, index: number) => {
				return <div>{record?.Cost_Type?.cost_type_name || "-"}</div>;
			}
		},
		{
			title: "Mã số thuế",
			render: (record: any, index: number) => {
				return <div>{record?.tax_code || "-"}</div>;
			}
		},
		{
			title: "Số hóa đơn",
			render: (record: any, index: number) => {
				return <div>{record?.invoice_number || "-"}</div>;
			}
		},
		{
			title: "Chi phí phát sinh (Vnđ)",
			align: "right",
			render: (record: any, index: number) => {
				return renderCell([
					{
						content: record?.amount || 0,
						type: "money"
					}
				]);
			}
		},
		{
			title: "Hình ảnh hóa đơn",
			render: (record: any, index: number) => {
				return (
					<div className="grid-container">
						<u
							style={{ color: "#2F80ED", cursor: "pointer" }}
							onClick={() => {
								_handleImgBillClick(record?.imgs);
							}}
						>
							Xem ảnh
						</u>

						{/* {record?.imgs &&
							record?.imgs.length > 0 &&
							record?.imgs.map((img: any) => (
								<Image
									src={`${CND_URL}/${img.image_url}`}
									style={{ cursor: "pointer", height: "100px", width: "100px" }}
								/>
							))} */}
					</div>
				);
			}
		}
	];
};
export const _paramsFilter: any = {
	q: undefined,
	status: null,
	created_at_start: undefined,
	created_at_end: undefined,
	page: 1,
	limit: 10
};
