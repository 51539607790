/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Form, Modal, Switch, Typography } from "antd";
import React, { useEffect, useState } from "react";
import SvgMoreOutLined from "src/components/Svg/SvgMoreOutLined";
import SvgIconPlus from "src/assets/svg/SvgIconPlus";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import {
	DEFAULT_FILTER,
	FLEX_FILTER_ELEMENTS,
	ISHOT,
	REASON_BASE,
	STATUS_POST_BASE,
	WORK_FROM_BASE,
	STATUS_LOCATION_BASE
} from "src/constants";
import "./style.scss";
import { _paramsFilter, columnsData, columnsDataDetail } from "./data";
import TableStyled from "src/components/table/TableStyled";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { notifyError, notifySuccess } from "src/components/notification";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "src/types";
import { getOrgs } from "src/services/actions/org.action";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { updatePageHeader } from "src/services/actions/pageHeader.action";
import routerNames from "src/utils/data/routerName";

import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
// interface DataType {
// 	key: React.ReactNode;
// 	name: string;
// 	age: number;
// 	address: string;
// 	children?: DataType[];
// }
const ListProvince = () => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [data, setData] = useState<any>([]);
	const [params, setParams] = useState<any>(DEFAULT_FILTER);
	const [paramsFilter, setParamsFilter] = useState<any>({ ..._paramsFilter }); 
	const [visible, setVisible] = useState<any>(false);

	const [dataEducation, setDataEducation] = useState<any>([]);
	const [dataPosition, setDataPosition] = useState<any>([]);
	const [loading, setLoading] = useState<any>(false);
	const [total, setTotal] = useState(0);

	const [dataSyscTicket, setDataSyscTicket] = useState<any>([]);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [idEdit, setIdEdit] = useState<any>();
	const [idCostsIncurred, setIdCostsIncurred] = useState<any>();
	const [visibleCostsIncurred, setVisibleCostsIncurred] = useState<any>(false);
	const [statusStatements, setStatusStatements] = useState<any>([]);
	const [statusPayment, setStatusPayment] = useState<any>([]);
	const [visibleCancel, setVisibleCancel] = useState<any>(false);
	const [paramsCancel, setParamsCancel] = useState<any>({});
	const [dataNews, setDataNews] = useState<any>([]);
	const [account, setAccount] = useState<any>({});
	const dispatch = useDispatch();
	const [dataZone, setDataZone] = useState<any>([]);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [dataCareer, setDataCareer] = useState<any>([]);
	const [dataDepartment, setDataDepartment] = useState<any>([]);

	const [callback, setCallback] = useState<boolean>(false);
	const [detail, setDetail] = useState<any>({});
	// useEffect(() => {
	// 	if (!stateGetOrgs?.data) {
	// 		dispatch(getOrgs({}));
	// 	}
	// 	const initcareer = async () => {
	// 		try {
	// 			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
	// 			setLoading(true);
	// 			const res: any = await _apiNotSaga.getCareer({ limit: 100, status: "A" });
	// 			setDataCareer(res?.data);

	// 			setLoading(false);
	// 		} catch (error: any) {
	// 			setLoading(false);
	// 			notifyError(error.message);
	// 		}
	// 	};
	// 	initcareer();
	// 	const initp = async () => {
	// 		try {
	// 			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
	// 			setLoading(true);
	// 			const ress: any = await _apiNotSaga.getPositions({
	// 				limit: 100,
	// 				status: "A"
	// 				// department_id: res?.data?.department_id
	// 			});
	// 			setDataPosition(ress?.data);
	// 			setLoading(false);
	// 		} catch (error: any) {
	// 			setLoading(false);
	// 			notifyError(error.message);
	// 		}
	// 	};
	// 	initp();
	// 	const initd = async () => {
	// 		try {
	// 			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
	// 			setLoading(true);
	// 			const res: any = await _apiNotSaga.getDepartment([]);
	// 			setDataDepartment(res?.data);
	// 			setLoading(false);
	// 		} catch (error: any) {
	// 			setLoading(false);
	// 			notifyError(error.message);
	// 		}
	// 	};
	// 	initd();
	// 	const inite = async () => {
	// 		try {
	// 			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
	// 			setLoading(true);
	// 			const res: any = await _apiNotSaga.getEducation([]);
	// 			setDataEducation(res?.data);
	// 			setLoading(false);
	// 		} catch (error: any) {
	// 			setLoading(false);
	// 			notifyError(error.message);
	// 		}
	// 	};
	// 	inite();
	// 	const initz = async () => {
	// 		try {
	// 			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
	// 			setLoading(true);
	// 			const res: any = await _apiNotSaga.getZones([]);
	// 			setDataZone(res?.data);
	// 			setLoading(false);
	// 		} catch (error: any) {
	// 			setLoading(false);
	// 			notifyError(error.message);
	// 		}
	// 	};
	// 	initz();
	// }, [dispatch]); //eslint-disable-line

	// useEffect(() => {
	// 	let _params = [
	// 		{
	// 			path: "/",
	// 			name: "Trang chủ"
	// 		},
	// 		{
	// 			name: "Danh mục hệ thống"
	// 		},
	// 		{
	// 			path: routerNames.LOCATION_LIST,
	// 			name: "Quản lý tỉnh thành"
	// 		},
	// 		{
	// 			path: "/",
	// 			name: "Chi tiết"
	// 		}
	// 	];
	// 	dispatch(updatePageHeader(_params));

	// }, [dispatch]);


	useEffect(() => {
		const initu = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getProvinces(params);
                setDataNews(res?.provinceList)
				setTotal(res?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initu();
	
	}, [params,callback]);
  
	const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	// const onEdit = (id: any) => {
	// 	setIsEdit(true);
	// 	setVisible(true);
	// 	setIdEdit(id);
	// };
	// const onCancel = (id: any, isCancel: boolean) => {
	// 	if (isCancel) {
	// 		// updateStatementTripCost(id, { status_cancel: "C" });
	// 	} else {
	// 		setVisibleCancel(true);
	// 		paramsCancel.id = id;
	// 		paramsCancel.status = "R";
	// 		setParamsCancel({ ...paramsCancel });
	// 	}
	// };
	// const pushUpApproval = (id: number, userId: number, isPush: boolean) => {
	// 	let _paramsPush = {
	// 		user_id: userId
	// 	};
	// 	let _params = {
	// 		approval_id: userId
	// 	};
	// 	// updateStatementTripCost(id, isPush ? _paramsPush : _params);
	// };
	// const onCreateTicket = (id: number) => {
	// 	let _params = {
	// 		id: id
	// 	};
	// 	// updateStatementTripCost(id, _params);
	// };
	const costsIncurredDetail = (id: any) => {
		setIdCostsIncurred(id);
		setVisibleCostsIncurred(true);
	};
	const getDetail = async (id: number) => {
		history.push(`/create-province/${id}`);

		
	};
	const updateStatus = async (r: any, status: number, type: string) => {
		const mapStatus: any = {
			status: status ? "A" : "D",
			locked: status ? "Y" : "N"
		};
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.updateProvinceDetail(r?.id, { [type]: mapStatus[type] });
			notifySuccess(res.message);
			setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const cancelModal = () => {
		setVisible(false);
		form.resetFields();
	};
	const onCreate: () => void = () => {
		setDetail({});
		setVisible(true);
	};
	const onCreateProvince = async (val: any) => {
		const _params = {
			...val,
			// status: status ? "A" : "D"
		};
		try {
			const res: any = !detail?.id
				? await _apiNotSaga.appProvince(_params)
				: "";
			notifySuccess(res.message);
			setVisible(false);
			setParams({
				...params,
				page: 1
			});
		} catch (error: any) {
			notifyError(error.message);
		}
		
	};

	const onSearch: (val: any) => void = (val) => {
		
		const { params: flexParams } = val;
		const newParams = removeIdentity({
			...params,
			...flexParams,
			page: 1
		});
		setParams(newParams);
	};

	const handleActions: any = {
		btnAdd: onCreate,
		btnSearch: onSearch
	};
	const actionsFilter = (val: any) => {
		const { idBtn } = val;
		handleActions[idBtn](val);
	};

	return (
		<>
		{visible && (
				<Modal
					open={visible}
					// maskClosable={false}
					onCancel={cancelModal}
					footer={
						<div className="addStores__footer">
							{/* <div className="flex text-[13px]">
								Hoạt động &nbsp;
								<Switch size="default" checked={status} onChange={() => setStatus(!status)}></Switch>
							</div> */}
							<div style={{ display: "flex", alignItems: "center" }}></div>&nbsp;
							{/* <button onClick={deleteRole} className="addStores__footer__btn editBtn">
								<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">Xóa vai trò</p>
							</button> */}
							<div style={{ display: "flex", alignItems: "center" }}></div>&nbsp;
							<button type="submit" form="roleForm" className="addStores__footer__btn editBtn">
								<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!detail.id ? "Tạo mới" : "Cập nhật"}</p>
							</button>
						</div>
					}
					title={<Typography.Title level={3}>{`${!detail.id ? "Tạo mới" : "Cập nhật"} tỉnh / thành phố`}</Typography.Title>}
				>
					<Form form={form} name="roleForm" layout="vertical" onFinish={onCreateProvince}>
					{!detail.id ? (
						<>
						<FormInput
							id="form-name"
							name="province_code"
							label="Mã tỉnh"
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Mã tỉnh"
							required
							className="w-full"
						/>
						<FormInput
							id="form-name"
							name="province_name"
							label="Tên tỉnh"
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Nhập tên tỉnh"
							required
							className="w-full"
						/>
						{/* <FormInput
							id="form-name"
							name="role_code"
							label=""
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Nhập mã nhóm vai trò"
							required
							className="w-full"
						/> */}
						</>
					) : (
						<>
						
					
						<FormInput
							id="form-name"
							name="role_name"
							label=""
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Nhập tên nhóm vai trò"
							required
							className="w-full"
						/>
						<FormInput
							id="form-name"
							name="role_code"
							label=""
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Nhập mã nhóm vai trò"
							required
							className="w-full"
						/>
						</>
					)}
					
						{/* <FormSelect
							name="user_type_id"
							label="Loại người dùng"
							dataSource={stateGetUserTypes?.data?.data}
							placeholder="Chọn loại người dùng"
							slOption={{
								name: "user_type_name",
								value: "id"
							}}
							required
						/> */}
					</Form>
				</Modal>
			)}
		<Loading spinning={loading}>
			<div className="contentSection">
				<div className="top-0 contentSection-filter">
					<FlexFilter
						onSearch={(v) => actionsFilter(v)}
						manipulation={[
							{
								paramsName: "btnAdd",
								icon: <PlusOutlined />,
								text: "Tạo mới"
							}
							// {
							// 	paramsName: "btnSysc",
							// 	icon: <TransactionOutlined />,
							// 	text: "Tạo ticket thanh toán"
							// }
						]}
						elements={[
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Tên tỉnh",
								placeholder: "Nhập tên tỉnh cần tìm",
								width: 160,
								options: {
									data: dataNews,
									keyOption: "id",
									valueOption: "province_name",
									nameOption: "province_name"
								},
								paramsName: "search"
							},
							// {
							// 	...FLEX_FILTER_ELEMENTS.input,
							// 	placeholder: "Nhập tên tỉnh cần tìm",
                            //     label: "Tên tỉnh",
							// 	paramsName: "search"
							// },
							// {
							// 	...FLEX_FILTER_ELEMENTS.select,
							// 	label: "Chi nhánh",
							// 	placeholder: "Chọn Chi nhánh",
							// 	paramsName: "org_id",
							// 	width: 150,
							// 	options: {
							// 		data: stateGetOrgs?.data,
							// 		keyOption: "id",
							// 		valueOption: "id",
							// 		nameOption: "org_name"
							// 	}
							// },
							// {
							// 	...FLEX_FILTER_ELEMENTS.select,
							// 	label: "Ngành nghề",
							// 	placeholder: "Chọn ngành nghề",
							// 	paramsName: "career_id",
							// 	width: 180,
							// 	options: {
							// 		data: dataCareer,
							// 		keyOption: "id",
							// 		valueOption: "id",
							// 		nameOption: "career_name"
							// 	}
							// },
                            // {
							// 	...FLEX_FILTER_ELEMENTS.select,
							// 	label: "Trạng thái",
							// 	placeholder: "Chọn trạng thái",
							// 	width: 160,
							// 	options: {
							// 		data: STATUS_POST_BASE,
							// 		keyOption: "id",
							// 		valueOption: "id",
							// 		nameOption: "name"
							// 	},
							// 	paramsName: "status"
							// },
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Trạng thái",
								placeholder: "Chọn trạng thái",
								width: 160,
								options: {
									data: STATUS_LOCATION_BASE,
									keyOption: "value",
									valueOption: "value",
									nameOption: "name"
								},
								paramsName: "status"
							},
							// {
							// 	...FLEX_FILTER_ELEMENTS.btnFilterMore
							// },
							// {
							// 	...FLEX_FILTER_ELEMENTS.createdRange,
							// 	initValue: FLEX_FILTER_CONFIGS.initTime.optional.result,
							// 	initSelectDate: FLEX_FILTER_CONFIGS.initTime.optional.title,
							// 	width: 250
							// },
							{
								...FLEX_FILTER_ELEMENTS.btnSearch,
								icon: <SearchOutlined />
							},
							{
									...FLEX_FILTER_ELEMENTS.btn,
									icon: <SvgIconPlus fill="black" />,
									width: 128,
									text: "Tạo mới",
									paramsName: "btnAdd",
									style: {
										color: "black",
										fontWeight: 600
									}
								}
							// { ...FLEX_FILTER_ELEMENTS.btnAction, icon: <SvgMoreOutLined /> }
						]}
						// elementsMore={[
						// 	{
						// 		...FLEX_FILTER_ELEMENTS.select,
						// 		label: "Chức danh",
						// 		placeholder: "Chức danh",
						// 		width: 160,
						// 		options: {
						// 			data: dataPosition,
						// 			keyOption: "id",
						// 			valueOption: "id",
						// 			nameOption: "position_name"
						// 		},
						// 		paramsName: "position_id"
						// 	},
						// 	{
						// 		...FLEX_FILTER_ELEMENTS.select,
						// 		label: "Phòng ban",
						// 		placeholder: "Phòng ban",
						// 		width: 160,
						// 		options: {
						// 			data: dataDepartment,
						// 			keyOption: "id",
						// 			valueOption: "id",
						// 			nameOption: "department_name"
						// 		},
						// 		paramsName: "department_id",
						// 	},
						// 	{
						// 		...FLEX_FILTER_ELEMENTS.select,
						// 		label: "Bằng cấp",
						// 		placeholder: "Bằng cấp",
						// 		width: 160,
						// 		options: {
						// 			data: dataEducation,
						// 			keyOption: "id",
						// 			valueOption: "id",
						// 			nameOption: "education_name"
						// 		},
						// 		paramsName: "education_id",
						// 	},
						// 	{
						// 		...FLEX_FILTER_ELEMENTS.select,
						// 		label: "Loại hình công việc",
						// 		placeholder: "Loại hình công việc",
						// 		width: 160,
						// 		options: {
						// 			data: WORK_FROM_BASE,
						// 			keyOption: "id",
						// 			valueOption: "id",
						// 			nameOption: "name"
						// 		},
						// 		paramsName: "form_work",
						// 	},
						// 	{
						// 		...FLEX_FILTER_ELEMENTS.select,
						// 		label: "Tin hot",
						// 		placeholder: "Tin hot",
						// 		width: 160,
						// 		options: {
						// 			data: ISHOT,
						// 			keyOption: "id",
						// 			valueOption: "id",
						// 			nameOption: "name"
						// 		},
						// 		paramsName: "is_hot",
						// 	},
						// 	{
						// 		...FLEX_FILTER_ELEMENTS.select,
						// 		label: "Tin Popup",
						// 		placeholder: "Tin Popup",
						// 		width: 160,
						// 		options: {
						// 			data: ISHOT,
						// 			keyOption: "id",
						// 			valueOption: "id",
						// 			nameOption: "name"
						// 		},
						// 		paramsName: "is_popup",
						// 	},
						// 	{
						// 		...FLEX_FILTER_ELEMENTS.select,
						// 		label: "Vùng miền",
						// 		placeholder: "Vùng miền",
						// 		width: 160,
						// 		options: {
						// 			data: dataZone,
						// 			keyOption: "id",
						// 			valueOption: "id",
						// 			nameOption: "zone_name"
						// 		},
						// 		paramsName: "zone_id",
						// 	},
						// 	// {
						// 	// 	...FLEX_FILTER_ELEMENTS.select,
						// 	// 	label: "Chi nhánh",
						// 	// 	placeholder: "Chi nhánh",
						// 	// 	width: 160,
						// 	// 	options: {
						// 	// 		data: stateGetOrgs?.data,
						// 	// 		keyOption: "id",
						// 	// 		valueOption: "id",
						// 	// 		nameOption: "org_name"
						// 	// 	},
						// 	// 	paramsName: "org_id",
						// 	// },
						// 	{
						// 		...FLEX_FILTER_ELEMENTS.select,
						// 		label: "Lý do tuyển",
						// 		placeholder: "Lý do tuyển",
						// 		width: 160,
						// 		options: {
						// 			data: REASON_BASE,
						// 			keyOption: "id",
						// 			valueOption: "id",
						// 			nameOption: "name"
						// 		},
						// 		paramsName: "reason",
						// 	},
						// 	{
						// 		...FLEX_FILTER_ELEMENTS.createdRange,
						// 		label: "Ngày tạo",
						// 		initValue: FLEX_FILTER_CONFIGS.initTime.optional.result,
						// 		initSelectDate: FLEX_FILTER_CONFIGS.initTime.optional.title,
						// 		width: 250
						// 	},
						// ]}
					/>
				</div>
				<hr className=" mb-[10px] border-neutral_color_1_2" />
				<div className="contentSection-main">
					<TableStyled
						rowKey="id"
						dataSource={dataNews}
						bordered
						pagination={{
							locale: {
								items_per_page: "/Trang"
							},
							current: params.page,
							showSizeChanger: true,
							defaultPageSize: params.limit,
							total: total || 0,
							showTotal: (total: any, range: any) => (
								<div className="flex gap-2">
									<strong>
										{range[0]} - {range[1]}
									</strong>
									trong tổng
									<strong>{total}</strong>
								</div>
							),
							onChange: onChangePaging
						}}
						scroll={{
							// x: 1444,
							y: "calc(70vh - 80px)"
						}}
						isRowLight={true}
						columns={columnsData({ getDetail, updateStatus  }) as any}
						// expandable={{
						// 	expandedRowRender: (record: any, i: number) => (
						// 		<div className="w-full bg-[#fffdf5]">
						// 			<TableStyled
						// 				rowKey="id"
						// 				dataSource={record?.details || []}
						// 				bordered
						// 				pagination={false}
						// 				isRowLight={true}
						// 				backgroundHeader={"#dfe2e4"}
						// 				columns={columnsDataDetail({ costsIncurredDetail }) as any}
						// 				scroll={{
						// 					y: "calc(40vh - 50px)"
						// 				}}
						// 			/>
						// 		</div>
						// 	),
						// 	rowExpandable: (record: any) => record.name !== "Not Expandable"
						// }}
					/>
				</div>
			</div>
		</Loading></>
		
	);
};

export default ListProvince;
