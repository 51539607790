import { Button, Col, Form, Modal, Row, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { getOrgs } from "src/services/actions/org.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
import { columnsDataOrg } from "./data";

const UserModal = ({ visible = true, detail = {} as any, onCancel = () => {}, onSuccess = () => {} }) => {
	const isCreate = detail?.id ? false : true;
	const [form] = Form.useForm();
	const [data, setData] = useState<any>([]);
	const [status, setStatus] = useState<boolean>(true);
	const [isSupper, setIsSupper] = useState<boolean>(false);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [roles, setRoles] = useState<any>([]);
	const [userTypes, setUserTypes] = useState<any>([]);
	const isMount = useIsMount();
	const dispatch = useDispatch();
	const [orgId, setOrgId] = useState<any>("");
	// const { stateGetRoles } = useSelector((e: AppState) => e.rolesReducer);
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [users, setUsers] = useState<any>([]);
	const [orgCodeAccess, setOrgCodeAccess] = useState<any>([]);
	const [dataDepartments, setDataDepartments] = useState<any>([]);
	const [dataRole, setDataRole] = useState<any>([]);
	// useEffect(() => {
	// 	// if (stateGetUserTypes?.data) {
	// 	// 	setUserTypes(stateGetUserTypes?.data?.data);
	// 	// } else {
	// 	// 	dispatch(getUserTypes());
	// 	// }
	// 	if (!stateGetOrgs?.data) {
	// 		dispatch(getOrgs({}));
	// 	}
	// }, []); //eslint-disable-line

	useEffect(() => {
		if (isMount) return;
		const { success, data, error } = stateGetUserTypes;
		if (success) {
			setUserTypes(data?.data);
			return;
		}
		if (success === false || error) {
			return notifyError(`${error}`);
		}
	}, [stateGetUserTypes.isLoading]); //eslint-disable-line
	useEffect(() => {
		const getUsers = async () => {
			const res: any = await _apiNotSaga.getUsers({});
			
			const _users = res?.data?.data?.map((e: any) => ({
				id: e.id,
				// name: `${e?.profile?.user_code} - ${e?.profile?.full_name}`
				email: `${e?.email} `,
				phone: `${e?.phone}`
			}));
			setUsers(_users);
			
		};
		getUsers();

		const initDepart = async () => {
			try {
				const res: any = await _apiNotSaga.getDepartment({ limit: 100, status: "A" });
				setDataDepartments(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initDepart();
		const init = async () => {
			try {
				const res: any = await _apiNotSaga.getRoles({ limit: 100, status: "A" });
				setDataRole(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, []);

	const loadDataOrgUser = async() => {
		try {
			const res: any = await _apiNotSaga.getUserOrg({ user_id: detail.id });
			setLoading(false);
			setData(res.data)
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	}
	useEffect(() => {
		const init = async () => {
			try {
				// if (users.length === 0 || !stateGetOrgs?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.userDetail(detail.id);
				form.setFieldsValue({
					...res?.data,
					passwd: undefined
					// full_name: res?.data?.profile?.full_name,
					// user_code: res?.data?.profile?.user_code,
					// role_id: res?.data?.role?.id,
					// phone: res?.data?.phone,
					// email: res?.data?.email,
					// user_type_id: res?.data?.profile?.user_type_id,
					// parent_id: res?.data?.profile?.parent_id ? res.data.profile.parent_id : undefined,
					// org_code: res?.data?.profile?.org_code
				});
				// if (res?.data?.profile?.orgcode_access) {
				// 	let orgcode_access = JSON.parse(res?.data?.profile?.orgcode_access);
				// 	let orgcode_access_temp: any = [];
				// 	stateGetOrgs?.data?.map((item: any) => {
				// 		if (orgcode_access?.find((items: any) => items === item.value)) {
				// 			return orgcode_access_temp.push(item);
				// 		}
				// 	});
				// 	setOrgCodeAccess([...orgcode_access_temp]);
				// }
				setStatus(res?.data?.status === "A");
				// setIsSupper(res?.data?.is_supper === "Y");
				setLock(res?.data?.locked === "Y");
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		if (isCreate) {
			form.resetFields();
		} else {
			init();
			loadDataOrgUser()
		}
	}, [users?.length]); //eslint-disable-line

	const checkUserCodeOnERP = async (e: any) => {
		const userCode = e.target.value.trim();
		if (!userCode) return;
		setLoading(true);
		try {
			const res: any = await _apiNotSaga.getUserByCode(userCode);
			setLoading(false);
			form.setFieldsValue({
				full_name: res?.data?.name,
				phone: res?.data?.phone,
				email: res?.data?.email,
				org_code: res?.data?.orgValue
			});
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const onSubmit = async (val: any) => {
		
		if(isCreate) {
			if(val?.passwd.indexOf(" ") !== -1) return notifyError("Mật khẩu không được chứa khoảng trắng")
		}
		const params = {
			...val,
			// status: status ? "A" : "D",
			// is_supper: isSupper ? "Y" : "N",
			// org_name: stateGetOrgs?.data?.find((e: any) => e.org_code === val.org_code)?.org_name,
			// department_name: dataDepartments?.find((e: any) => e.id === val.department_id)?.department_name,
			// role_name: dataRole?.find((e: any) => e.id === val.role_id)?.role_name
		};
	
		setLoading(true);
		try {
			const res: any = isCreate
				? await _apiNotSaga.createUser(params)
				: await _apiNotSaga.updateUser(detail.id, params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const handleChangeOrg = (e: any) => {
		setOrgId(e);
	};
	// const handleAddOrg = async () => {
	// 	if(!orgId) return notifyError("Vui lòng chọn chi nhánh")
	// 	let params = {
	// 		user_id: detail.id,
	// 		org_id: orgId,
	// 		// org_name: stateGetOrgs?.data?.find((e: any) => e.id === orgId)?.org_name,
	// 		status: "A"
	// 	};
		
	// 	try {
	// 		setLoading(true);
	// 		const res: any = await _apiNotSaga.createUserOrg(params);
	// 		notifySuccess(res.message);
	// 		loadDataOrgUser()
	// 		// setCallback(!callback);
	// 		setLoading(false);
	// 	} catch (error: any) {
	// 		setLoading(false);
	// 		notifyError(error.message);
	// 	}
	// };
	
	const updateStatus = async (r: any, status: number, type: string) => {
		const mapStatus: any = {
			status: status ? "A" : "D",
		};
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.updateUserOrg(r?.id, { [type]: mapStatus[type] });
			notifySuccess(res.message);
			loadDataOrgUser()
			// setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const getDetail = async (id: number) => {
		// setDetail({ id });
		// setVisible(true);
	};

	return (
		<>
			<Modal
				open={visible}
				width={800}
				onCancel={onCancel}
				// maskClosable={false}
				title={`${isCreate ? "Tạo mới" : "Cập nhật"} người dùng`}
				style={{
					maxWidth: "90vw",
					width: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div>
							<div className="flex text-[13px] mb-4 md:mb-0">
								Supper Admin
								<Switch disabled={loading} size="default" checked={isSupper} onChange={(e) => setIsSupper(e)}></Switch>
							</div>
						</div>
						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!isCreate ? "Cập nhật" : "Tạo mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[16, 16]}>
							{/* <Col xs={24} md={12}>
								<FormInput
									name="user_erp"
									label="Mã nhân viên"
									type="justNumber"
									
									size="large"
									placeholder="Nhập mã nhân viên"
									required
									onBlur={checkUserCodeOnERP}
									disabled={!isCreate}
								/>
							</Col> */}
							{/* <Col xs={24} md={12}>
								<FormInput
									name="full_name"
									label="Họ tên"
									type="text"
									size="large"
									placeholder="Nhập họ tên"
									required
									disabled={!isCreate}
									// disabled
								/>
							</Col> */}
							<Col xs={24} md={12}>
								<FormInput name="email" label="Email" type="email" size="large" placeholder="Nhập email" required disabled={!isCreate} />
							</Col>
							<Col xs={24} md={12}>
								<FormInput
									name="phone"
									label="Số điện thoại"
									type="phone"
									size="large"
									placeholder="Nhập số điện thoại"
									required
									disabled={!isCreate}
									// disabled
								/>
							</Col>
							{/* <Col xs={24} md={12}>
								<FormSelect
									name="department_id"
									label="Phòng ban"
									dataSource={dataDepartments}
									placeholder="Chọn phòng ban"
									slOption={{
										name: "department_name",
										value: "id"
									}}
									disabled={!isCreate}
									// onChange={onChangeUserType}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col> */}
							<Col xs={24} md={12}>
								<FormSelect
									name="role_id"
									label="Nhóm vai trò"
									dataSource={dataRole}
									placeholder="Chọn vai trò"
									slOption={{
										name: "role_name",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col>
							{/* <Col xs={24} md={12}>
								<FormSelect
									name="org_code"
									label="Chi nhánh"
									disabled={!isCreate}
									dataSource={stateGetOrgs?.data}
									placeholder="Chọn chi nhánh"
									slOption={{
										name: "org_name",
										value: "org_code"
									}}
									showSearch
									optionFilterProp="children"
									required
									// disabled
								/>
							</Col> */}
							{isCreate && (
								<Col xs={24} md={12}>
									<FormInput
										name="passwd"
										label="Mật khẩu"
										type="text"
										size="large"
										placeholder="Nhập mật khẩu"
										required
										// disabled
									/>
								</Col>
							)}
							{!isCreate && (
								<Col xs={24} md={24}>
									{/* <Row gutter={[16, 16]}>
										<Col xs={8} md={8}>
											<p>Chi nhánh phụ trách tuyển dụng</p>
										</Col>

										<Col xs={8} md={8}>
											<FormSelect
												// name="org_code"
												// label="Chi nhánh"
												dataSource={stateGetOrgs?.data}
												onChange={(e) => handleChangeOrg(e)}
												placeholder="Chọn chi nhánh"
												slOption={{
													name: "org_name",
													value: "id"
												}}
												showSearch
												optionFilterProp="children"
												required
												// disabled
											/>
										</Col>
										<Col xs={4} md={4}>
											<Button type="default" onClick={() => handleAddOrg()} className="bg-black">
												<p className="text-[#fdd800]">Thêm</p>
											</Button>
										</Col>
									</Row> */}
									{/* <div className="contentSection-main">
										<TableStyled
											rowKey="id"
											dataSource={data}
											bordered
											pagination={false}
											isRowLight={true}
											columns={columnsDataOrg({ updateStatus, getDetail }) as any}
											scroll={{
												x: data?.length > 0 ? (columnsDataOrg({}).length - 1) * 100 : null,
												y: 200
											}}
										/>
									</div> */}
								</Col>
							)}
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default UserModal;
