import { Button, Col, Form, Modal, Row, Select, Typography, Spin, Switch, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { getOrgs } from "src/services/actions/org.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState, RouteParams } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
// import { columnsDataOrg } from "./data";
import { useHistory, useParams } from "react-router-dom";

import { getAttribute } from "src/services/actions/attribute.actions";

import { geneUniqueKey } from "src/utils/helpers/functions/textUtils";
import SvgIconMoveRight from "src/assets/svg/SvgIconMoveRight";
import { getMessageV1 } from "src/utils/helpers/functions/getMessage";


const UserModal = ({ visible = true, categoryId = {} as any, onCancel = () => {}, onSuccess = () => {} }) => {
	const paramsURL = useParams<RouteParams>();
	const isCreate = categoryId?.id ? false : true;
	const [form] = Form.useForm();
	const [data, setData] = useState<any>([]);
	const [status, setStatus] = useState<boolean>(true);
	const [statusAttribute, setStatusAttribute] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [categoryDetails, setCategoryDetails] = useState<any>([]);
	const [userTypes, setUserTypes] = useState<any>([]);
	const isMount = useIsMount();
	const dispatch = useDispatch();
	// const { stateGetRoles } = useSelector((e: AppState) => e.rolesReducer);
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);
	const [level, setLevel] = useState<number | null>(null);

	// ----------------select attribute-------------
	const [functions, setFunctions] = useState<any[]>([]);
	const { stateGetAttribute } = useSelector((e: AppState) => e.attributeReducer);
	const [checkStatus, setCheckStatus] = useState<any[]>([]);
	const [formUpdate] = Form.useForm();
	const [mainChecked, setMainChecked] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);

	const [stateAttributeCategory, setStateAttributeCategory] = useState<any>([]);
	const [showMore, setShowMore] = useState<boolean>(false);
	const [shouldUpdate, setShouldUpdate] = useState(false);

	interface Option {
		id: number;
		title: string;
	}

	const [titleCategory, setTitleCategory] = useState<{ id: any; title: any }[]>([]);
	// useEffect(() => {
	// 	// if (stateGetUserTypes?.data) {
	// 	// 	setUserTypes(stateGetUserTypes?.data?.data);
	// 	// } else {
	// 	// 	dispatch(getUserTypes());
	// 	// }
	// 	if (!stateGetOrgs?.data) {
	// 		dispatch(getOrgs({}));
	// 	}
	// }, []); //eslint-disable-line
	useEffect(() => {
		const initpage = async () => {
			try {
				setLoading(true);
				const ress: any = await _apiNotSaga.getListCategory({});
				setCategoryDetails(ress?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initpage();
	}, []);

	const levelArray: Option[] = [
		{ id: 0, title: "level 0" },
		{ id: 1, title: "level 1" },
		{ id: 2, title: "level 2" },
		{ id: 3, title: "level 3" }
	];

	useEffect(() => {
		if (isMount) return;
		const { success, data, error } = stateGetUserTypes;
		if (success) {
			setUserTypes(data?.data);
			return;
		}
		if (success === false || error) {
			return notifyError(`${error}`);
		}
	}, [stateGetUserTypes.isLoading]); //eslint-disable-line
	useEffect(() => {
		const init = async () => {
			try {
				setLoading(true);
				const res: any = await _apiNotSaga.getCategoryById(categoryId.id);

				setCategoryDetails(res?.data[0]);
				setLevel(res?.data[0].level);
				form.setFieldsValue({
					...res?.data[0]
				});
				setStatus(res?.data[0].status === "A");
				setLoading(false);
				// setStatusAttribute(true)
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		if (isCreate) {
			form.resetFields();
		} else {
			init();
		}
	}, [categoryId.id]);

	useEffect(() => {
		const fetchData = async () => {
			// Giả sử bạn lấy dữ liệu từ API và cập nhật state
			setLoading(true);
			const ress: any = await _apiNotSaga.getListCategory({});
			// setCategoryDetails(ress?.data);
			// setToal(res?.paging?.total);
			setLoading(false);
			// Sau khi setCategoryDetails, cập nhật titleCategory

			const newTitleCategory = ress.data.map((item: { id: any; category_name: any }) => ({
				id: item.id,
				title: item.category_name
			}));
			newTitleCategory.unshift({ id: 0, title: "Danh mục bậc 1" });
			setTitleCategory(newTitleCategory);
		};

		fetchData();
	}, []);

	//------------------------------------------------------------------------
	// Phần select thuộc tính của thread
	// Lấy danh sách thuộc tính của thread
	useEffect(() => {
		dispatch(getAttribute({}));
	}, []);

	useEffect(() => {
		const init = async () => {
			try {
				setLoading(true);
				const res: any = await _apiNotSaga.getListAttributeCategoryDetail(categoryId.id);
				setStateAttributeCategory(res?.data);
				// setToal(res?.paging?.total);
				setLoading(false);
				// setIsDataLoaded(true);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, []); //eslint-disable-line

	useEffect(() => {
		if (isMount) return;
		const { data, message, success, error, isLoading } = stateGetAttribute;

		setLoading(isLoading || false);
		if (success) {
			let dataFunctions = data;
			let fakeFunctions = [...dataFunctions?.sort((a: any, b: any) => b?.children.length - a?.children.length)];
			let convertArray = [];
			let fakeCheckStatus = [];
			let statusAttributes = stateAttributeCategory;

			for (let i = 0; i < fakeFunctions?.length; i++) {
				// Tìm giá trị status từ stateAttributeCategory dựa trên attribute_id
				const matchingStatus = statusAttributes.find((attr: any) => attr.attribute_id === fakeFunctions[i]?.id);

				// Xác định giá trị statusSwitch
				const statusSwitch = matchingStatus ? matchingStatus.status === "A" : false;

				let convertLevel1 = {
					children: [] as any,
					value: fakeFunctions[i]?.id,
					label: fakeFunctions[i]?.attribute_name,
					status: matchingStatus ? matchingStatus.status : null, // Gán status nếu tìm thấy, nếu không thì là null
					id: fakeFunctions[i]?.id,
					statusSwitch: statusSwitch
				};

				convertArray.push(convertLevel1);

				fakeCheckStatus.push({
					id: fakeFunctions[i]?.id,
					status: true
				});
			}

			setCheckStatus(fakeCheckStatus);
			setFunctions(convertArray);
		}
		if (success === false || error) {
			let msg = getMessageV1(message, ", ");
			return notifyError(msg);
		}
	}, [stateGetAttribute.isLoading,stateAttributeCategory ]);

	
	useEffect(() => {
		if (isMount) return;
		if (functions.length === 0) return;
		setLoading(false);

		if (stateAttributeCategory?.length > 0) {
			let arrayChecked = [] as any;

			stateAttributeCategory.forEach((attrCategory: any) => {
				const matchedFunction = functions.find((func) => func.value === attrCategory.attribute_id);

				if (matchedFunction) {
					let fakeMainChecked = [...mainChecked];
					let mainCheckedItem = fakeMainChecked.find((x) => x.attribute_id === attrCategory.attribute_id);

					if (!mainCheckedItem) {
						fakeMainChecked.push({
							id: attrCategory.attribute_id,
							checkAll: true,
							ilu: false
						});
						setMainChecked(fakeMainChecked);
					}

					arrayChecked.push({
						checkAll: true,
						id: matchedFunction.id,
						indeterminate: false,
						// status: status,
						value: []
					});
				}
			});

			setCheckedList(arrayChecked);
		}
	}, [stateAttributeCategory, functions]);

	const onCheckAllChange = (id: any, label: any, status: any) => {
		let fakeCate = [] as any[];
		let listHaveValue = [] as any[];

		// Tìm kiếm phần tử trong mảng functions
		const matchedFunction = functions.find((func: any) => func.id === id);

		if (matchedFunction) {
			// Duyệt qua children của phần tử đã tìm thấy (nếu có)
			matchedFunction.children?.forEach((child: any) => {
				if (child.label) {
					fakeCate = [...fakeCate, ...child.label.split(",").map((e: any) => +e)];
				}
				fakeCate.push(child.label);
				listHaveValue.push(child);
			});
		}

		let updatedCheckedList = [...checkedList];

		// Kiểm tra nếu đã tồn tại id trong checkedList
		const index = updatedCheckedList.findIndex((item) => item.id === id);
		if (index !== -1) {
			updatedCheckedList[index] =
				updatedCheckedList[index].checkAll === false
					? {
							label: label,
							id: id,
							value: fakeCate,
							status: status,
							checkAll: true,
							indeterminate: false
					  }
					: {
							label: label,

							id: id,
							status: status,
							checkAll: false,
							indeterminate: false
					  };
		} else {
			updatedCheckedList.push({
				id: id,
				value: fakeCate,
				label: label,
				status: status,
				checkAll: true,
				indeterminate: false
			});
		}

		setCheckedList(updatedCheckedList);
	};
	const onChange = (list: any, id: any, parent_id: any) => {
		let listCate = functions
			?.find((a) => a.children.find((x: any) => x.id === id))
			?.children?.find((x: any) => x.value === id)?.children;
		let listHaveValue = [] as any[];
		let _list = [] as any[];
		for (var x = 0; x < list.length; x++) {
			const _find = listCate?.find((cate: any) => cate.value === list[x]);
			listHaveValue.push(_find);
			if (_find.label) {
				_list = [..._list, ..._find.label.split(",").map((e: any) => +e)];
			}
			_list.push(_find.value);
		}
		for (var i = 0; i < checkedList.length; i++) {
			if (checkedList[i].id === id) {
				let fakeList = [...checkedList];
				setCheckedList(
					fakeList.map((obj) =>
						obj.id === id
							? {
									parent_id: parent_id,

									id: id,
									value: _list,
									listLabel: listHaveValue,
									thread_id: paramsURL.id,

									checkAll: list.length === listCate.length,
									indeterminate: !!list.length && list.length < listCate.length
							  }
							: obj
					)
				);
				return;
			} else {
				continue;
			}
		}

		setCheckedList([
			...checkedList,
			{
				parent_id: parent_id,
				thread_id: paramsURL.id,
				id: id,
				value: _list,
				listLabel: listHaveValue,

				checkAll: list.length === listCate.length,
				indeterminate: !!list.length && list.length < listCate.length
			}
		]);
	};
	useEffect(() => {
		let arrayTest = [...mainChecked];
		const handleCheckLevel1True = (id: any) => {
			let fakeArray = [...checkedList].filter((x) => x.id === id);
			let lengthArray = [...functions].find((x) => x.id === id);
			let array = arrayTest;

			if (!fakeArray.some((x) => x.checkAll === true) && !fakeArray.some((x) => x.indeterminate === true)) {
				let item = {
					checkAll: false,
					ilu: false,
					id: id
				};
				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			} else if (fakeArray.some((x) => x.indeterminate === true)) {
				let item = {
					checkAll: false,
					ilu: true,
					id: id
				};
				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			} else if (
				(fakeArray && fakeArray.length === 0) ||
				(fakeArray.length === 1 && fakeArray[0].value.length === 0 && !fakeArray[0].checkAll)
			) {
				let item = {
					checkAll: false,
					ilu: false,
					id: id
				};

				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			} else if (
				fakeArray.filter((x) => x.checkAll === false).length > 0 ||
				fakeArray.length < lengthArray?.children?.length
			) {
				let item = {
					checkAll: false,
					ilu: true,
					id: id
				};
				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			} else {
				for (var i = 0; i < fakeArray.length; i++) {
					if (!fakeArray[i].checkAll) {
						let item = {
							checkAll: false,
							ilu: true,
							id: id
						};

						if (array.find((x) => x.id === id)) {
							array = array.map((x) => (x.id === id ? item : x));
						} else {
							array.push(item);
						}
						return;
					}
				}
				let item = {
					checkAll: true,
					ilu: false,
					id: id
				};
				if (array.find((x) => x.id === id)) {
					array = array.map((x) => (x.id === id ? item : x));
				} else {
					array.push(item);
				}
			}
			arrayTest = array;
		};
		for (var i = 0; i < functions.length; i++) {
			handleCheckLevel1True(functions[i].id);
		}
		setMainChecked(arrayTest);
	}, [checkedList]);

	const handleCheckLevel1 = (e: any, id: any) => {
		let lengthArray = [...functions].find((x) => x.id === id);
		let convertChecked = [...checkedList];
		if (e) {
			for (var i = 0; i < lengthArray.children.length; i++) {
				let value = [] as any;
				let listLabel = [] as any;
				for (var j = 0; j < lengthArray.children[i].children.length; j++) {
					const _item = lengthArray.children[i].children[j];
					if (_item.relative_funct_ids) {
						value = [...value, ..._item.relative_funct_ids.split(",").map((e: any) => +e)];
					}
					value.push(_item.value);
					listLabel.push(_item);
					statusAttribute.push(_item.status);
				}
				let item = {
					statusAttribute: statusAttribute,
					id: lengthArray.children[i].id,
					value: value,
					listLabel: listLabel,
					checkAll: true,
					indeterminate: false
				};
				convertChecked = convertChecked.map((x) => (x.id === item.id ? item : x));
				if (!convertChecked.find((x) => x.id === item.id)) {
					convertChecked.push(item);
				}
				setCheckedList(convertChecked);
			}
			let item2 = {
				checkAll: true,
				ilu: false,
				id: id
			};
			if (mainChecked.find((x) => x.id === id)) {
				let fake = [...mainChecked].map((x) => (x.id === id ? item2 : x));
				setMainChecked(fake);
			} else {
				setMainChecked([...mainChecked, item2]);
			}
		} else {
			for (var i = 0; i < lengthArray.children.length; i++) {
				convertChecked = convertChecked.filter((x) => x.id !== lengthArray.children[i].id);
			}
			setCheckedList(convertChecked);
			setMainChecked(mainChecked.filter((x) => x.id !== id));
		}
	};

	const handleSwitchChange = (id: number) => {
		setFunctions((prevFunctions) =>
			prevFunctions.map((func) =>
				func.id === id
					? {
							...func,
							statusSwitch: !func.statusSwitch,
							status: !func.statusSwitch ? "A" : "D"
					  }
					: func
			)
		);

		setShouldUpdate(true); // Đánh dấu cần cập nhật
	};
	// Theo dõi sự thay đổi của functions

	useEffect(() => {
		const updateFunctions = async () => {
			const filteredFunctions = functions.filter((func) => func.status !== null);
			const params = {
				id: categoryId.id,
				filteredFunctions
			};

			try {
				const res: any = isCreate
					? await _apiNotSaga.createCategory(params)
					: await _apiNotSaga.updateAttributeCategory(categoryId.id, params);
				setLoading(false);
				notifySuccess(res.message);
				// onSuccess();
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};

		if (shouldUpdate) {
			updateFunctions();
			setShouldUpdate(false); // Reset lại trạng thái cập nhật
		}
	}, [functions, shouldUpdate]);

	// ---------------------------------------------------------------------

	const onSubmit = async (val: any) => {
		let arrayId = [] as any[];

		for (let i = 0; i < checkedList.length; i++) {
			if (checkedList[i].value?.length > 0 || checkedList[i].checkAll) {
				arrayId.push({
					// thread_id: checkedList[i].thread_id,
					category_id: categoryId.id,
					attribute_id: checkedList[i].id
					// status: checkedList[i].status
				});
			}
		}
		const params = {
			...val,
			status: status ? "A" : "D",
			attribute_ids: [...new Set(arrayId)]
		};
		
		try {
			const res: any = isCreate
				? await _apiNotSaga.createCategory(params)
				: await _apiNotSaga.updateCategory(categoryId.id, params);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const updateStatus = async (r: any, status: number, type: string) => {
		const mapStatus: any = {
			status: status ? "A" : "D"
		};
		// try {
		// 	setLoading(true);
		// 	const res: any = await _apiNotSaga.updateUserOrg(r?.id, { [type]: mapStatus[type] });
		// 	notifySuccess(res.message);
		// 	loadDataOrgUser();
		// 	// setCallback(!callback);
		// 	setLoading(false);
		// } catch (error: any) {
		// 	setLoading(false);
		// 	notifyError(error.message);
		// }
	};

	const deleteCategory = async (id: number) => {
		try {
			const res: any = await _apiNotSaga.deleteCategory(id);
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
			// history.push(`/banner-list`);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const filteredOptions = levelArray.filter((option) => option.id <= (level ?? 0));

	const showMoreAttribute = async () => {
		setShowMore(!showMore);
	};

	return (
		<>
			<Modal
				open={visible}
				width={800}
				onCancel={onCancel}
				// maskClosable={false}
				title={`${isCreate ? "Tạo mới" : "Cập nhật"} danh mục`}
				style={{
					maxWidth: "90vw",
					width: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div>
						</div>
						{!isCreate ? (
							<button
								disabled={loading}
								type="button"
								className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
								onClick={() => deleteCategory(categoryId.id)}
							>
								<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">Xóa bỏ</p>
							</button>
						) : (
							""
						)}

						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!isCreate ? "Cập nhật" : "Tạo mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						<Row gutter={[16, 16]}>
							<Col xs={24} md={12}>
								<FormSelect
									name="parent_id"
									label="Chọn danh mục cha (nếu có)"
									dataSource={titleCategory}
									placeholder="Chọn danh mục cha"
									slOption={{
										name: "title",
										value: "id"
									}}
									showSearch
									optionFilterProp="children"
									// required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormInput
									name="category_name"
									label="Tên danh mục"
									type="text"
									size="large"
									placeholder="Nhập tên danh mục"
									required
								/>
							</Col>
							<Col xs={24} md={12}>
								<FormInput
									name="url"
									label="URL"
									type="text"
									size="large"
									placeholder="Thêm URL (Nếu có)"

									// disabled
								/>
							</Col>
							{!isCreate && (
								<Col xs={24} md={12}>
									<FormSelect
										name="level"
										label="Cấp độ"
										dataSource={filteredOptions}
										placeholder="Chọn cấp độ danh mục"
										slOption={{
											name: "title",
											value: "id"
										}}
										showSearch
										optionFilterProp="children"
										// required
									/>
								</Col>
							)}
							{isCreate && (
								<Col xs={24} md={12}>
									<FormSelect
										name="level"
										label="Cấp độ"
										dataSource={levelArray}
										placeholder="Chọn cấp độ danh mục"
										slOption={{
											name: "title",
											value: "id"
										}}
										showSearch
										optionFilterProp="children"
										// required
									/>
								</Col>
							)}

							<Col xs={24} md={12}>
								<FormInput
									name="meta_title"
									label="Tiêu đề Meta"
									type="text"
									size="large"
									placeholder="Thêm tiêu đề Meta (Nếu có)"

									// disabled
								/>
							</Col>
							<Col xs={24} md={24}>
								<button
									type="button"
									className={"my-3 border-[1px] border-solid border-[#222] rounded-[8px] p-3"}
									onClick={showMoreAttribute}
								>
									{showMore ? <span>Thu gọn </span> : <span>Chọn thuộc tính </span>}
								</button>
								{showMore ? (
									<Row className={`my-4`} gutter={[24, 8]}>
										<Col className={``} md={24} xs={24} sm={24}>
											<div className="contentSection">
												<Loading spinning={loading}>
													<div className="mainPages contentSection-filter">
														{false ? (
															<div className="h-full w-full flex justify-center">
																<div style={{ marginTop: "20%" }}>
																	<Spin />
																</div>
															</div>
														) : (
															<div className="contentSection" style={{ margin: "0" }}>
																<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
																	<Typography.Title level={5}>Chọn thuộc tính</Typography.Title>
																</div>

																<div
																	style={{
																		display: "flex",
																		justifyContent: "space-between",

																		marginTop: "4px"
																	}}
																>
																	<div
																		style={{
																			width: "calc(50% - 4px)"
																		}}
																	>
																		{functions &&
																			functions.length > 0 &&
																			functions.map(
																				(functions, index) =>
																					index % 2 === 0 && (
																						<div
																							style={{
																								width: "100%",
																								marginBottom: "8px",

																								borderRadius: "5px",
																								overflow: "hidden",
																								height: checkStatus.find((x: any) => x.id === functions.id)?.status
																									? "auto"
																									: "32px"
																							}}
																							key={geneUniqueKey()}
																						>
																							<div
																								key={functions.id}
																								style={{
																									background: "rgb(212,212,212)",
																									display: "flex",
																									alignItems: "center",
																									justifyContent: "space-between",
																									padding: "4px 8px "
																								}}
																							>
																								<Checkbox
																									key={geneUniqueKey()}
																									indeterminate={
																										// mainChecked.find((x) => x.id === functions.id)?.ilu
																										checkedList.find((element) => element.id === functions?.id)
																											?.indeterminate
																									}
																									onChange={(e: any) =>
																										// handleCheckLevel1(e.target.checked, functions?.id)
																										onCheckAllChange(functions.id, functions.label, functions.status)
																									}
																									checked={
																										// mainChecked.find((x) => x.id === functions.id)?.checkAll
																										checkedList.find((element) => element.id === functions?.id)
																											?.checkAll
																									}
																								>
																									<div
																										style={{
																											fontSize: "14px",
																											fontWeight: "600"
																										}}
																									>
																										{functions?.label}
																									</div>
																								</Checkbox>

																								{functions.status != null ? (
																									<>
																										<span>Trạng thái</span>
																										<Switch
																											disabled={loading}
																											size="default"
																											checked={functions.statusSwitch}
																											onChange={() => handleSwitchChange(functions.id)}
																										></Switch>
																									</>
																								) : (
																									""
																								)}
																							</div>
																							<div
																								className={`${
																									checkStatus.find((x: any) => x.id === functions.id)?.status
																										? "listContentActive"
																										: "listContent"
																								}`}
																							></div>
																						</div>
																					)
																			)}
																	</div>
																	<div
																		style={{
																			width: "calc(50% - 4px)"
																		}}
																	>
																		{functions &&
																			functions.length > 0 &&
																			functions.map(
																				(functions, index) =>
																					index % 2 === 1 && (
																						<div
																							style={{
																								width: "100%",
																								marginBottom: "8px",

																								borderRadius: "5px",
																								overflow: "hidden",
																								height: checkStatus.find((x: any) => x.id === functions.id)?.status
																									? "auto"
																									: "32px"
																							}}
																							key={geneUniqueKey()}
																						>
																							<div
																								style={{
																									background: "rgb(212,212,212)",
																									display: "flex",
																									alignItems: "center",
																									justifyContent: "space-between",
																									padding: "4px 8px "
																								}}
																							>
																								<Checkbox
																									key={geneUniqueKey()}
																									indeterminate={mainChecked.find((x) => x.id === functions.id)?.ilu}
																									onChange={(e: any) =>
																										// handleCheckLevel1(e.target.checked, functions?.id)
																										onCheckAllChange(functions.id, functions.label, functions.status)
																									}
																									checked={mainChecked.find((x) => x.id === functions.id)?.checkAll}
																								>
																									<div
																										style={{
																											fontSize: "14px",
																											fontWeight: "600"
																										}}
																									>
																										{functions?.label}
																									</div>
																								</Checkbox>

																								{functions.status != null ? (
																									<>
																										<span>Trạng thái</span>
																										<Switch
																											disabled={loading}
																											size="default"
																											checked={functions.statusSwitch}
																											onChange={() => handleSwitchChange(functions.id)}
																										></Switch>
																									</>
																								) : (
																									""
																								)}
																							</div>
																							<div
																								className={`${
																									checkStatus.find((x: any) => x.id === functions.id)?.status
																										? "listContentActive"
																										: "listContent"
																								}`}
																							>
																								{/* {functions?.children &&
																									functions?.children.map((functionLevel2: any) => (
																										<div
																											key={geneUniqueKey()}
																											className="listContentChild"
																											style={{ padding: "4px 8px " }}
																										>
																											<Checkbox
																												key={geneUniqueKey()}
																												indeterminate={
																													checkedList.find(
																														(element) => element.id === functionLevel2?.id
																													)?.indeterminate
																												}
																												onChange={() =>
																													onCheckAllChange(
																														functionLevel2?.id,
																														functions.id
																														// functionLevel2.label
																													)
																												}
																												checked={
																													checkedList.find(
																														(element) => element.id === functionLevel2?.id
																													)?.checkAll
																												}
																												className="checkboxUserGroup"
																											>
																												<div
																													key={geneUniqueKey()}
																													style={{
																														fontSize: "14px",
																														fontWeight: "500"
																													}}
																												>
																													{functionLevel2?.label}
																												</div>
																												{functionLevel2?.children?.length > 0 && (
																													<Checkbox.Group
																														className="endChild"
																														key={geneUniqueKey()}
																														options={functionLevel2?.children}
																														value={
																															checkedList.find(
																																(element) => element.id === functionLevel2?.id
																															)?.value
																														}
																														onChange={(e: any) =>
																															onChange(e, functionLevel2?.id, functions?.id)
																														}
																													/>
																												)}
																											</Checkbox>
																										</div>
																									))} */}
																							</div>
																						</div>
																					)
																			)}
																	</div>
																</div>
															</div>
														)}
														{/* </Form> */}
													</div>
												</Loading>
											</div>
										</Col>
									</Row>
								) : (
									""
								)}
							</Col>
							{/* <Col xs={24} md={12}>
								<FormSelect
									name="department_id"
									label="Phòng ban"
									dataSource={dataDepartments}
									placeholder="Chọn phòng ban"
									slOption={{
										name: "department_name",
										value: "id"
									}}
									disabled={!isCreate}
									// onChange={onChangeUserType}
									showSearch
									optionFilterProp="children"
									required
								/>
							</Col> */}

							{/* <Col xs={24} md={12}>
								<FormSelect
									name="org_code"
									label="Chi nhánh"
									disabled={!isCreate}
									dataSource={stateGetOrgs?.data}
									placeholder="Chọn chi nhánh"
									slOption={{
										name: "org_name",
										value: "org_code"
									}}
									showSearch
									optionFilterProp="children"
									required
									// disabled
								/>
							</Col> */}
							{/* {isCreate && (
								<Col xs={24} md={12}>
									<FormInput
										name="passwd"
										label="Mật khẩu"
										type="text"
										size="large"
										placeholder="Nhập mật khẩu"
										required
										// disabled
									/>
								</Col>
							)} */}
							{/* {!isCreate && (
								<Col xs={24} md={24}>
									<Row gutter={[16, 16]}>
										<Col xs={8} md={8}>
											<p>Chi nhánh phụ trách tuyển dụng</p>
										</Col>

										<Col xs={8} md={8}>
											<FormSelect
												// name="org_code"
												// label="Chi nhánh"
												dataSource={stateGetOrgs?.data}
												onChange={(e) => handleChangeOrg(e)}
												placeholder="Chọn chi nhánh"
												slOption={{
													name: "org_name",
													value: "id"
												}}
												showSearch
												optionFilterProp="children"
												required
												// disabled
											/>
										</Col>
										<Col xs={4} md={4}>
											<Button type="default" onClick={() => handleAddOrg()} className="bg-black">
												<p className="text-[#fdd800]">Thêm</p>
											</Button>
										</Col>
									</Row>
									<div className="contentSection-main">
										<TableStyled
											rowKey="id"
											dataSource={data}
											bordered
											pagination={false}
											isRowLight={true}
											columns={columnsDataOrg({ updateStatus, getDetail }) as any}
											scroll={{
												x: data?.length > 0 ? (columnsDataOrg({}).length - 1) * 100 : null,
												y: 200
											}}
										/>
									</div>
								</Col>
							)} */}
						</Row>
					</Form>
				</Loading>
			</Modal>
		</>
	);
};

export default UserModal;
