const categoriesTypes = {
    START_GET_LIST_CATEGORY: "START_GET_LIST_CATEGORY",
    GET_LIST_CATEGORY_SUCCESS: "GET_LIST_CATEGORY_SUCCESS",
    GET_LIST_CATEGORY_FAILURE: "GET_LIST_CATEGORY_FAILURE",

    START_GET_CATEGORY_BY_ID: "START_GET_CATEGORY_BY_ID",
    GET_CATEGORY_BY_ID_SUCCESS: "GET_CATEGORY_BY_ID_SUCCESS",
    GET_CATEGORY_BY_ID_FAILURE: "GET_CATEGORY_BY_ID_FAILURE",

    START_GET_CATEGORY_BY_ID_CHILDREN: "START_GET_CATEGORY_BY_ID_CHILDREN",
    GET_CATEGORY_BY_ID_CHILDREN_SUCCESS: "GET_CATEGORY_BY_ID_CHILDREN_SUCCESS",
    GET_CATEGORY_BY_ID_CHILDREN_FAILURE: "GET_CATEGORY_BY_ID_CHILDREN_FAILURE",
  }
  
  export default categoriesTypes;