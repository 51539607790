import { Button, Dropdown, Menu, Switch, Tag } from "antd";
import Svg3Dot from "src/assets/svg/Svg3Dot";
import SvgPen from "src/assets/svg/SvgPen";
import { createUser, userInfo } from "src/components/commonRender/renderData";
import styled from "styled-components";
const MenuStyled = styled(Menu)`
	padding: 0 !important;
	border-radius: 3px;
	.ant-dropdown-menu-item {
		padding: 0 !important;
		.ant-btn {
			border: none;
			padding: 16px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 16px;
			width: 100%;
			color: black;
			outline: none;
			&:hover {
				background-color: rgba(0, 0, 0, 0.1);
			}
			svg {
				min-width: 20px;
			}
		}
	}
`;
export const columnsData = ({ updateStatus, getDetail }: any) => {
	const menu = (r: any) => (
		<MenuStyled
			items={[
				{
					key: "detail",
					label: (
						<Button onClick={() => getDetail(r?.id)}>
							<SvgPen fill="black" />
							<span>Chỉnh sửa</span>
						</Button>
					)
				}
			]}
		/>
	);
	return [
		{
			title: "ID",
			key: "id",
			width: 100,
			fixed: "left",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-start items-start">
						<div className="text-blue-600 text-[13px] font-normal  underline leading-snug">#{record?.id} </div>
						{/* <Tag color="success">{record?.status}</Tag> */}
					</div>
				);
			}
		},
		// {
		// 	title: "Hình ảnh",
		// 	width: 140,
		// 	key: "org_name",
		// 	render: (record: any, index: number) => {
		// 		return (
		// 			<div className="flex flex-col justify-start items-start">
		// 				<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">{record?.khuvuc}</div>
		// 				<div>
		// 					<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Khối:</span>
		// 					<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug"> {record?.khoi}</span>
		// 				</div>
		// 			</div>
		// 		);
		// 	}
		// },
		{
			title: "Mã trang",
			dataIndex: "page_code",
			key: "page_code"
		},
		{
			title: "Tên trang",
			dataIndex: "page_name",
			key: "page_name"
		},

		// {
		// 	title: "Thời gian hiển thị",
		// 	key: "user_type_name",
		// 	width: 250,
		// 	render: (record: any, index: number) => {
		// 		return (
		// 			<div className="flex flex-col justify-start items-start">
		// 				<div className="text-black text-opacity-90 text-[13px] font-normal  leading-snug">{record?.phongban}</div>
		// 				<div>
		// 					<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Ngành nghề:</span>
		// 					<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
		// 						{" "}
		// 						{record?.nganhnghe}
		// 					</span>
		// 				</div>
		// 				<div>
		// 					<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Chức danh:</span>
		// 					<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
		// 						{" "}
		// 						{record?.chucdanh}
		// 					</span>
		// 				</div>
		// 			</div>
		// 		);
		// 	}
		// },
		{
			title: "TT hoạt động",
			// dataIndex: "status",
			// key: "status",
			align: "center",
			render: (record: any, index: number) => {
				return (
					<div className="flex flex-col justify-center items-center">
						{record.status === "A" ? <div>Hiển thị</div> : <div>Ẩn</div>}
					</div>
				);
			}
		},
		{
			title: "URL",
			dataIndex: "url",
			key: "url",
			width: 150
			// render: (record: any, index: number) => {
			// 	return (
			// 		<div className="flex flex-col justify-start items-start">
			// 			<div>
			// 				<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Bắt đầu:</span>
			// 				<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
			// 					{" "}
			// 					{record?.batdau}
			// 				</span>
			// 			</div>
			// 			<div>
			// 				<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Kết thúc:</span>
			// 				<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
			// 					{" "}
			// 					{record?.ketthuc}
			// 				</span>
			// 			</div>
			// 		</div>
			// 	);
			// }
		},
		{
			title: "Người tạo",
			dataIndex: "created_by",
			key: "created_by",
			width: 150
			// render: (record: any, index: number) => {
			// 	return (
			// 		<div className="flex flex-col justify-start items-start">
			// 			<div>
			// 				<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Bắt đầu:</span>
			// 				<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
			// 					{" "}
			// 					{record?.batdau}
			// 				</span>
			// 			</div>
			// 			<div>
			// 				<span className="text-black text-opacity-25 text-[13px] font-normal   leading-snug">Kết thúc:</span>
			// 				<span className="text-black text-opacity-90 text-[13px] font-normal   leading-snug">
			// 					{" "}
			// 					{record?.ketthuc}
			// 				</span>
			// 			</div>
			// 		</div>
			// 	);
			// }
		},

		{
			title: "Thao tác",
			dataIndex: "action",
			key: "action",
			align: "center",
			width: 100,
			fixed: "right",
			render: (v: string, r: any) => {
				return (
					<div className="flex justify-center">
						<Dropdown overlay={() => menu(r)} trigger={["hover"]} placement="bottomRight" arrow>
							{/* <Dropdown 
						menu={{ items: items(r) }} 
						overlayClassName="dropdown-css" trigger={["hover"]} placement="bottomRight" arrow> */}
							<Button icon={<Svg3Dot />}></Button>
						</Dropdown>
					</div>
				);
			}
		}
	];
};
