import { Form, Input, Select, Switch, UploadFile } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
// import SvgUploadImage from "src/assets/svg/SvgUploadImage";
import { notifyError, notifySuccess } from "src/components/notification";
// import { routesNameData } from "src/constants";
import { APIMethodsEnum } from "src/constants/enum";
import message from "src/constants/message";
// import { data } from "src/pages/customers/list/data";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
// import { createModuleFunction, updateModuleFunction } from "src/services/actions/moduleFunction.action";
import { convertToConsucutiveString, removeSign } from "src/utils/helpers/functions/textUtils";
import ImageOverlay from "../../../components/custom/ImageOverlay";
import { ModuleFunctionActionTypesEnum } from "../../../constants/enum";
import { TypeModalEnum } from "../edit/data";

const ModuleFunctModal = ({
	form,
	visible,
	setVisible,
	handleCancel,
	type,
	icon,
	setIcon,
	icon2,
	setIcon2,
	setCallback,
	statusId,
	setStatusId,
	icon3
}: any) => {
	//console.log("statusId", statusId);

	const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
	const [loadingUpload2, setLoadingUpload2] = useState<boolean>(false);
	const [fileListMobie, setFileListMobie] = useState<UploadFile[]>([]);
	const [fileListStart, setFileListStart] = useState<UploadFile[]>([]);
	const [fileListEnd, setFileListEnd] = useState<UploadFile[]>([]);
	const [listApiAction, setListApiAction] = useState<any>([]);
	const [openModalIconOverlay, setModalOpenIconOverlay] = useState<boolean>(false);
	const [overlay, setOverlay] = useState<boolean>(false);
	const dispatch = useDispatch();
	const onChangeFunctCode = (e: any) => {
		form.setFieldValue("funct_code", convertToConsucutiveString(e.target.value).toUpperCase());
	};
	useEffect(() => {
		if (form?.getFieldValue("level") === 2) {
			_apiNotSaga
				.getAllFunctsAction()
				.then((res: any) => {
					if (res.success) {
						setListApiAction(res.data);
					}
				})
				.catch((err) => {
					notifyError(err.message);
				});
		}
	}, [form.getFieldValue("level")]);

	useEffect(() => {
		form.setFieldsValue({
			icon_start_svg: fileListStart ? fileListStart[0] : [],
			activate_icon_end_svg: fileListEnd ? fileListEnd[0] : [],
			icon_mobie_svg: fileListMobie ? fileListMobie[0] : []
		});
	}, [fileListStart, fileListEnd, fileListMobie, form]);
	useEffect(() => {
		setFileListStart(icon);
		setFileListEnd(icon2);
		setFileListMobie(icon3);
	}, [icon, icon2, icon3]);
	useEffect(() => {
		const formValues = form?.getFieldsValue();
		if (formValues?.level === 2 && type === TypeModalEnum.CREATE) {
			let functCode = formValues.funct_code + ModuleFunctionActionTypesEnum.VIEWS;
			form.setFieldValue("funct_code", functCode);
			form.setFieldValue("action", ModuleFunctionActionTypesEnum.VIEWS);
		}
	}, [visible]);
	const renameFile = (originalFile: any, newName: string) => {
		return new File([originalFile], newName, {
			type: originalFile?.type,
			lastModified: originalFile?.lastModified
		});
	};
	const onSubmitForm = () => {
		const dataRequest = { ...form.getFieldsValue() };
		//console.log("dataRequest", dataRequest);
		//console.log("statusId", statusId);
		let files: any = [];
		// if (dataRequest.icon_start_svg && !dataRequest.icon_start_svg?.isNotFile)
		// 	files.push(
		// 		renameFile(dataRequest.icon_start_svg, `_ICON_START_SVG.${dataRequest.icon_start_svg.name.split(".")[1]}`)
		// 	);
		// if (dataRequest.activate_icon_end_svg && !dataRequest.activate_icon_end_svg?.isNotFile)
		// 	files.push(
		// 		renameFile(
		// 			dataRequest.activate_icon_end_svg,
		// 			`_ACTIVATE_ICON_END_SVG.${dataRequest.activate_icon_end_svg.name.split(".")[1]}`
		// 		)
		// 	);
		// if (dataRequest.icon_mobie_svg && !dataRequest.icon_mobie_svg?.isNotFile)
		// 	files.push(
		// 		renameFile(dataRequest.icon_mobie_svg, `_ICON_MOBIE_SVG.${dataRequest.icon_mobie_svg.name.split(".")[1]}`)
		// 	);
		dataRequest.status = statusId ? "A" : "D";

		// dataRequest.relative_funct_ids = dataRequest.relative_funct_ids
		// 	? dataRequest.relative_funct_ids.join(",")
		// 	: undefined;
		// delete dataRequest.icon_start_svg;
		// delete dataRequest.icon_mobie_svg;
		// delete dataRequest.activate_icon_end_svg;
		const _newData = { ...dataRequest };

		switch (type) {
			case TypeModalEnum.CREATE: {
				delete _newData.id;
				delete _newData.index;
				delete _newData.init_funct_code;
				delete _newData.raw_funct_code;
				if (_newData.level !== 2) {
					_newData.method = "GET";
				}
				//console.log("_newData", _newData);
				_apiNotSaga
					.createModuleFunct(_newData)
					.then((res: any) => {
						//console.log("res", res);
						if (res.success) {
							notifySuccess("Tạo mới thành công");
							setCallback((pre: boolean) => !pre);
							onCancelModal();
						}
					})
					.catch((err) => {
						notifyError(err.message);
					});
				break;
			}
			case TypeModalEnum.EDIT: {
				_newData.method = _newData.method || "GET";
				delete _newData.init_funct_code;
				delete _newData.raw_funct_code;
				_apiNotSaga
					.updateModuleFunct(_newData.id, {..._newData})
					.then((res: any) => {
						if (res.success) {
							notifySuccess("Cập nhật thành công");
							setCallback((pre: boolean) => !pre);
							onCancelModal();
						}
					})
					.catch((err) => {
						notifyError(err.message);
					});
				break;
			}
		}
		// setIcon(null);
		// setIcon2(null);
	};

	const level = form?.getFieldValue("level");

	const onChangeActions = (value: string) => {
		let functCode: string = form.getFieldValue("funct_code");
		//console.log(value);
		//console.log(functCode + value);
		//console.log(form.getFieldValue("raw_funct_code"));
		if (
			Object.values<string>(ModuleFunctionActionTypesEnum).some((actionType: string) => functCode.includes(actionType))
		) {
			const pattern = new RegExp(`(${Object.values<string>(ModuleFunctionActionTypesEnum).join("|")})`, "g");
			functCode = functCode.replace(pattern, "");
			functCode = functCode + value;
			form.setFieldsValue({ funct_code: functCode });
		}
		if (form.getFieldValue("raw_funct_code")) {
			const pattern = new RegExp(`(${Object.values<string>(ModuleFunctionActionTypesEnum).join("|")})`, "g");
			functCode = functCode.replace(pattern, "");
			functCode = functCode + value;
			form.setFieldsValue({ funct_code: functCode });
		}
	};

	const onChangeRawFunctCode = (e: any) => {
		//console.log("check", e);
		const initFunctCode = form.getFieldValue("init_funct_code");
		let rawFunctCode = convertToConsucutiveString(e).toUpperCase();

		let functCode: string =
			initFunctCode?.split("__").length <= 1
				? initFunctCode?.split("__")[0] + "__" + rawFunctCode
				: initFunctCode?.split("__").slice(0, -1).join("__") + "__" + rawFunctCode;

		form.setFieldsValue({ funct_code: functCode, raw_funct_code: rawFunctCode });
	};
	const onChangeRawLv2FunctCode = (e: any) => {
		//console.log("check", e.length);
		let functCode: string = "";
		const action = form.getFieldValue("action");
		const initFunctCode = form.getFieldValue("funct_code");
		let rawFunctCode = convertToConsucutiveString(e).toUpperCase();
		if (initFunctCode.split("__").length - 1 > 2) {
			if (e.length > 0) {
				functCode = initFunctCode?.split("__").slice(0, -2).join("__") + "__" + rawFunctCode;
			} else {
				functCode = initFunctCode?.split("__").slice(0, -2).join("__") + rawFunctCode;
			}
		} else {
			if (e.length > 0)
				functCode =
					initFunctCode?.split("__").length <= 1
						? initFunctCode?.split("__")[0] + "__" + rawFunctCode
						: initFunctCode?.split("__").slice(0, -1).join("__") + "__" + rawFunctCode;
			else {
				functCode = initFunctCode?.split("__").slice(0, -1).join("__") + rawFunctCode;
			}
		}

		form.setFieldsValue({ funct_code: functCode + action, raw_funct_code: rawFunctCode });
	};
	const uploadImg = () => {};
	const onCancelModal = () => {
		handleCancel();
		setFileListEnd([]);
		setFileListStart([]);
	};
	return (
		<>
			<ImageOverlay
				open={openModalIconOverlay}
				imgSrc={icon}
				onClose={() => {
					setModalOpenIconOverlay(false);
				}}
			/>
			<Modal
				visible={visible}
				title={type === TypeModalEnum.CREATE ? "Tạo mới module" : "Chi tiết"}
				centered
				footer={
					<div className="addStores__footer">
						<div className="flex text-[13px]">
							Hoạt động &nbsp;
							<Switch size="default" checked={statusId} onChange={() => setStatusId(!statusId)}></Switch>
						</div>
						<div style={{ display: "flex", alignItems: "center" }}></div>&nbsp;
						<button
							type="submit"
							form="myForm"
							onClick={() => {
								onSubmitForm();
							}}
							className="addStores__footer__btn editBtn"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">Lưu</p>
						</button>
					</div>
				}
				onCancel={onCancelModal}
				width={"50%"}
			>
				<Form
					form={form}
					id="funct-form"
					onFinish={onSubmitForm}
					layout="vertical"
					initialValues={{ status: true, display_on_website: true, method: APIMethodsEnum.GET }}
					style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}
				>
					<Form.Item name="id" style={{ display: "none" }}>
						<Input type="hidden" />
					</Form.Item>
					<Form.Item name="parent_id" style={{ display: "none" }}>
						<Input type="hidden" />
					</Form.Item>
					<Form.Item name="index" style={{ display: "none" }}>
						<Input type="hidden" />
					</Form.Item>
					<Form.Item name="ui_icon" style={{ display: "none" }}>
						<Input type="hidden" />
					</Form.Item>
					<Form.Item name="level" style={{ display: "none" }}>
						<Input type="hidden" />
					</Form.Item>
					<Form.Item
						label="Display name"
						name="funct_name"
						rules={[{ required: true, message: message.funct.functNameNotEmpty }]}
						style={{ marginBottom: "1rem", width: "calc(50% - 4px)" }}
					>
						<Input placeholder="Nhập display name" className="defaultInput" style={{ borderRadius: "5px" }} />
					</Form.Item>
					<Form.Item
						label="Function code (theo module)"
						name="funct_code"
						rules={[{ required: true, message: message.funct.functCodeNotEmpty }]}
						style={{ marginBottom: "1rem", width: "calc(50% - 4px)" }}
					>
						<Input
							placeholder="MODULE_VIEWS"
							className="defaultInput"
							onChange={onChangeFunctCode}
							style={{ borderRadius: "5px" }}
							disabled={[1, 2].includes(level)}
						/>
					</Form.Item>

					{level === 1 ? (
						<>
							<Form.Item
								name="init_funct_code"
								rules={[{ required: true, message: message.funct.functCodeNotEmpty }]}
								style={{ marginBottom: "1rem", display: "none" }}
							>
								<Input type="hidden" />
							</Form.Item>
							<Form.Item
								label="Function code"
								name="raw_funct_code"
								rules={[{ required: true, message: message.funct.functCodeNotEmpty }]}
								style={{ marginBottom: "1rem", width: "calc(50% - 4px)" }}
							>
								<Input
									placeholder="MODULE_VIEWS"
									className="defaultInput"
									onChange={(e: any) => {
										let check = e.target.value.indexOf("__");
										if (e?.target?.value === "_" || e?.target?.value === "__") {
											form.setFieldValue("raw_funct_code", "");
										} else if (e.target.value.indexOf("_") === 0) {
											form.setFieldValue("raw_funct_code", e?.target?.value?.replace("_", ""));
										} else if (check > 0) {
											form.setFieldValue("raw_funct_code", e.target.value.replaceAll("__", "_"));
										} else {
											onChangeRawFunctCode(e.target.value);
										}
									}}
									// onKeyPress={(e: any) => {
									// 	if (e.key === "_" && (e?.target?.value?.length === 1 || e?.target?.value?.length === 0)) {
									// 		form.setFieldValue("raw_funct_code", "");
									// 	}
									// }}
									onBlur={(e: any) => {
										if (e?.target?.value?.length === e.target.value.lastIndexOf("_") + 1) {
											//console.log("ok2");

											onChangeRawFunctCode(e.target.value.slice(0, e.target.value.length - 1));
										}
									}}
									style={{ borderRadius: "5px" }}
								/>
							</Form.Item>
						</>
					) : null}
					{level === 2 && (
						<Form.Item
							label="Function code"
							name="raw_funct_code"
							rules={[{ required: false, message: message.funct.functCodeNotEmpty }]}
							style={{ marginBottom: "1rem", width: "calc(50% - 4px)" }}
						>
							<Input
								placeholder="MODULE_VIEWS"
								className="defaultInput"
								onChange={(e: any) => {
									//console.log("e", e.target.value.indexOf("__"));

									let check = e.target.value.indexOf("__");
									if (e?.target?.value === "_" || e?.target?.value === "__") {
										form.setFieldValue("raw_funct_code", "");
									} else if (e.target.value.indexOf("_") === 0) {
										form.setFieldValue("raw_funct_code", e?.target?.value?.replace("_", ""));
									} else if (check > 0) {
										form.setFieldValue("raw_funct_code", e.target.value.replaceAll("__", "_"));
									} else {
										onChangeRawLv2FunctCode(e.target.value);
									}
								}}
								// onKeyPress={(e: any) => {
								// 	if (e.key === "_" && (e?.target?.value?.length === 1 || e?.target?.value?.length === 0)) {
								// 		form.setFieldValue("raw_funct_code", "");
								// 	}
								// }}
								onBlur={(e: any) => {
									//console.log("qqq", e?.target?.value);
									//console.log("qqq", e?.target?.value?.length);
									//console.log("eee", e.target.value.lastIndexOf("__") + 2);
									//console.log("bbb", e.target.value.slice(0, e.target.value.length - 1));
									if (e?.target?.value?.length === e.target.value.lastIndexOf("_") + 1) {
										const initFunctCode = form.getFieldValue("funct_code");
										//console.log("abc", initFunctCode?.split("__").slice(0, -2).join("__"));

										onChangeRawLv2FunctCode(e.target.value.slice(0, e.target.value.length - 1));
									}
								}}
								style={{ borderRadius: "5px" }}
							/>
						</Form.Item>
					)}
					{level === 2 ? (
						<Form.Item
							label="Actions"
							name="action"
							className="ordersPage__search__form__item"
							style={{ width: "calc(50% - 4px)", marginBottom: "1rem" }}
						>
							<Select
								placeholder="Lựa chọn actions cho chức năng"
								// className="defaultSelect"
								// style={{ border: "1px solid #bfc4c9", overflow: "hidden", marginBottom: 0 }}
								onChange={onChangeActions}
							>
								{Object.entries(ModuleFunctionActionTypesEnum).map(([key, val]) => (
									<Select.Option key={key} value={val}>
										{key}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					) : null}
					{level === 2 && (
						<Form.Item
							label="API Method"
							name="method"
							className="ordersPage__search__form__item"
							style={{ width: "calc(50% - 4px)", marginBottom: "1rem" }}
						>
							<Select
								placeholder="Phương thức request"
								// className="defaultSelect"
								// style={{ border: "1px solid #bfc4c9", overflow: "hidden", marginBottom: 0 }}
								defaultValue={APIMethodsEnum.GET}
							>
								{Object.entries(APIMethodsEnum).map(([key, val]) => (
									<Select.Option key={key} value={val}>
										{key}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					)}

					{level === 2 && (
						<Form.Item
							label="API Route"
							name="api_route"
							rules={[{ required: true, message: message.funct.functAPIRouteNotEmpty }]}
							style={{ width: "calc(50% - 4px)", marginBottom: "1rem" }}
						>
							<Input placeholder="/api/v1/{module}" className="defaultInput" style={{ borderRadius: "5px" }} />
						</Form.Item>
					)}
					{/* {level === 2 && (
						<Form.Item
							label="Danh sách api liên quan"
							name="relative_funct_ids"
							className="ordersPage__search__form__item"
							style={{ width: "calc(100% - 4px)", marginBottom: "1rem" }}
						>
							<Select
								placeholder="Danh sách api liên quan"
								className="mutil-form-select"
								mode="multiple"
								optionFilterProp="children"
								filterOption={(input: any, option: any) =>
									removeSign(option.children.toLowerCase()).indexOf(removeSign(input.toLowerCase())) >= 0
								}
							>
								{listApiAction.map((item: any) => (
									<Select.Option key={item.id} value={item.id}>
										{item.funct_name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					)} */}
					{level !== 2 ? (
						<>
							<Form.Item
								label="UI Route"
								name="ui_route"
								rules={[{ required: true, message: message.funct.functUIRouteNotEmpty }]}
								style={{ marginBottom: "1rem", width: "calc(50% - 4px)" }}
							>
								<Input placeholder="Đường dẫn UI đến chức năng" className="defaultInput" />
							</Form.Item>
							<Form.Item
								label="API Route"
								name="api_route"
								rules={[{ required: true, message: message.funct.functUIMobieNotEmpty }]}
								style={{ marginBottom: "1rem", width: "calc(50% - 4px)" }}
							>
								<Input placeholder="Đường dẫn mobile đến chức năng" className="defaultInput" />
							</Form.Item>
						</>
					) : null}
					{/* {level !== 2 && (
						<Row className="w-full" gutter={[14, 14]}>
							<Col md={12} xs={24} sm={24}>
								<Form.Item name="icon_mobie_svg" label="Mobile Icon">
									<UpdaloadImage
										fileList={fileListMobie}
										className="iconMoudle"
										setChangeFileList={setFileListMobie}
										uploadImg={uploadImg}
										maxCount={1}
										width={100}
									/>
								</Form.Item>
							</Col>
							<Col md={6} xs={24} sm={24}>
								<Form.Item name="icon_start_svg" label="Icon">
									<UpdaloadImage
										fileList={fileListStart}
										className="icon2Upload"
										setChangeFileList={setFileListStart}
										uploadImg={uploadImg}
										maxCount={1}
										width={100}
									/>
								</Form.Item>
							</Col>
							<Col md={6} xs={24} sm={24}>
								<Form.Item name="activate_icon_end_svg" label="Activate icon">
									<UpdaloadImage
										fileList={fileListEnd}
										id="files"
										className="icon2Upload"
										setChangeFileList={setFileListEnd}
										uploadImg={uploadImg}
										maxCount={1}
									/>
								</Form.Item>
							</Col>
						</Row>
					)} */}
				</Form>
			</Modal>
		</>
	);
};

export default ModuleFunctModal;
