import { Reducer } from "redux";
import attributeTypes from "../actions/types/attribute.types";
import { IAttributeState } from "../interfaces/attribute.interfaces";

import { ISyntheticAction } from "../interfaces/root.interfaces";
import { rootState } from "./state/root.states";

const initState: IAttributeState = {
	stateGetAttribute: { ...rootState },
	// stateGetAttributeDetail: { ...rootState },
	// stateGetAttributeDetailChildren:{ ...rootState },
};

const attributeReducer: Reducer<IAttributeState, ISyntheticAction> = (
	state: IAttributeState = initState,
	action: ISyntheticAction
) => {
	const { type, response } = action;

	switch (type) {
		/*************************** START *************************/
		/*                       LOGIN ACCOUNT                     */

		case attributeTypes.START_GET_LIST_ATTRIBUTE: {
			const stateReducer: IAttributeState = {
				...state,
				stateGetAttribute: {
					...rootState,
					isLoading: true
				},
			};
			return stateReducer;
		}
		case attributeTypes.GET_LIST_ATTRIBUTE_SUCCESS: {
			const stateReducer: IAttributeState = {
				...state,
				stateGetAttribute: {
					...rootState,
					isLoading: false,
					...response
					// data: response.data,
					// message: response.message,
					// success: response.success
				}
			};
			return stateReducer;
		}
		case attributeTypes.GET_LIST_ATTRIBUTE_FAILURE: {
			const stateReducer: IAttributeState = {
				...state,
				stateGetAttribute: {
					...rootState,
					isLoading: false,
					message: response.message,
					error: true
				}
			};
			return stateReducer;
		}

		// /**************************** END **************************/
		// case attributeTypes.START_GET_ATTRIBUTE_BY_ID: {
		// 	const stateReducer: IAttributeState = {
		// 		...state,
		// 		stateGetAttributeDetail: {
		// 			...rootState,
		// 			isLoading: true
		// 		},
		// 	};
		// 	return stateReducer;
		// }
		// case attributeTypes.GET_ATTRIBUTE_BY_ID_SUCCESS: {
		// 	const stateReducer: IAttributeState = {
		// 		...state,
		// 		stateGetAttributeDetail: {
		// 			...rootState,
		// 			...response,
		// 			isLoading: false,
		// 			// data: response.data,
		// 			// message: response.message,
		// 			// success: response.success
		// 		}
		// 	};
		// 	return stateReducer;
		// }
		// case attributeTypes.GET_ATTRIBUTE_BY_ID_FAILURE: {
		// 	const stateReducer: IAttributeState = {
		// 		...state,
		// 		stateGetAttributeDetail: {
		// 			...rootState,
		// 			isLoading: false,
		// 			message: response.message,
		// 			error: true
		// 		}
		// 	};
		// 	return stateReducer;
		// }
		///////////////////////////////////////////////////////////
		// case attributeTypes.START_GET_ATTRIBUTE_BY_ID_CHILDREN: {
		// 	const stateReducer: IAttributeState = {
		// 		...state,
		// 		stateGetAttributeDetailChildren: {
		// 			...rootState,
		// 			isLoading: true
		// 		},
		// 	};
		// 	return stateReducer;
		// }
		// case attributeTypes.GET_ATTRIBUTE_BY_ID_CHILDREN_SUCCESS: {
		// 	const stateReducer: IAttributeState = {
		// 		...state,
		// 		stateGetAttributeDetailChildren: {
		// 			...rootState,
		// 			isLoading: false,
		// 			data: response.data,
		// 			message: response.message,
		// 			success: response.success
		// 		}
		// 	};
		// 	return stateReducer;
		// }
		// case attributeTypes.GET_ATTRIBUTE_BY_ID_CHILDREN_FAILURE: {
		// 	const stateReducer: IAttributeState = {
		// 		...state,
		// 		stateGetAttributeDetailChildren: {
		// 			...rootState,
		// 			isLoading: false,
		// 			message: response.message,
		// 			error: true
		// 		}
		// 	};
		// 	return stateReducer;
		// }
		default:
			return state;
	}
};

export default attributeReducer;
