import { call, put, takeLatest } from "redux-saga/effects";
import { API_ATTRIBUTE } from "src/services/api/url.index";
import { api } from "../../api/api.index";
import { API_URL } from "../../api/config";
import { IAction, ICatchError, IRootResponse } from "../../interfaces/root.interfaces"; /*eslint-disable-line*/
import { actionFailure, actionSuccess } from "../root.actions";
import attributeTypes from "../types/attribute.types";

function* getAttribute(action: IAction) {
	try {
		// yield delay(500, true);
		const { payload } = action;
		const res: IRootResponse = yield call(() => {
			return api.get(`${API_URL}/${API_ATTRIBUTE}`, payload?.params);
		});
		yield put(actionSuccess(attributeTypes.GET_LIST_ATTRIBUTE_SUCCESS, res));
	} catch (err: ICatchError | any) {
		yield put(actionFailure(attributeTypes.GET_LIST_ATTRIBUTE_FAILURE, err));
	}
}

// function* getCategoriesDetail(action: IAction) {
// 	try {
// 		// yield delay(500, true);
// 		const { payload } = action;
// 		const res: IRootResponse = yield call(() => {
// 			return api.get(`${API_URL}/${API_ATTRIBUTE}/${payload.id}`);
// 		});
		
// 		yield put(actionSuccess(attributeTypes.GET_ATTRIBUTE_BY_ID_SUCCESS, res));
// 	} catch (err: ICatchError | any) {
// 		yield put(actionFailure(attributeTypes.GET_ATTRIBUTE_BY_ID_FAILURE, err));
// 	}
// }

// function* getCategoriesDetailChildren(action: IAction) {
// 	try {
// 		// yield delay(500, true);
// 		const { payload } = action;
// 		const res: IRootResponse = yield call(() => {
// 			return api.get(`${API_URL}/${API_ATTRIBUTE}/${payload.id}/children`);
// 		});
// 		yield put(actionSuccess(attributeTypes.GET_ATTRIBUTE_BY_ID_CHILDREN_SUCCESS, res));
// 	} catch (err: ICatchError | any) {
// 		yield put(actionFailure(attributeTypes.GET_ATTRIBUTE_BY_ID_CHILDREN_FAILURE, err));
// 	}
// }

const attributeSagas = [
	takeLatest(attributeTypes.START_GET_LIST_ATTRIBUTE, getAttribute),
	// takeLatest(attributeTypes.START_GET_ATTRIBUTE_BY_ID, getCategoriesDetail),
	// takeLatest(attributeTypes.START_GET_ATTRIBUTE_BY_ID_CHILDREN, getCategoriesDetailChildren)

];
export default attributeSagas;
