import { SearchOutlined } from "@ant-design/icons";
import { Form, Modal, Switch, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconPlus from "src/assets/svg/SvgIconPlus";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FlexFilter from "src/components/filter/FlexFilter";
import FormInput from "src/components/form/FormInput";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import PanigationAntStyled from "src/components/panigation/PanigationAntStyled";
import TableStyled from "src/components/table/TableStyled";
import { DEFAULT_FILTER, FLEX_FILTER_ELEMENTS, STATUS_BASE } from "src/constants";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
import { columnsData } from "./data";
import FormSelect from "src/components/form/FormSelect";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { AppState } from "src/types";

const Roles = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [params, setParams] = useState<any>({...DEFAULT_FILTER,isGetAdmin:true});
	const [data, setData] = useState<any>([]);
	const [detail, setDetail] = useState<any>({});
	const [total, setToal] = useState<number>(0);
	const [status, setStatus] = useState<boolean>(true);
	const dispatch = useDispatch();
	const [visible, setVisible] = useState<boolean>(false);
	const [form] = Form.useForm();
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);

	useEffect(() => {
		dispatch(getUserTypes({status: 'A'}));
	}, [dispatch]);

	useEffect(() => {
		const init = async () => {
			try {
				setLoading(true);
				const res: any = await _apiNotSaga.getRoles(params);
				setData(res?.data);
				setToal(res?.page?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, [params]);
	
	const onCreate: () => void = () => {
		setDetail({});
		setVisible(true);
	};
	const onSearch: (val: any) => void = (val) => {
		const { params: flexParams } = val;
		const newParams = removeIdentity({
			...params,
			...flexParams,
			page: 1
		});
		setParams(newParams);
	};
	const handleActions: any = {
		btnAdd: onCreate,
		btnSearch: onSearch
	};
	const actionsFilter = (val: any) => {
		const { idBtn } = val;
		handleActions[idBtn](val);
	};
	const updateStatus = async (r: any, status: number) => {
		try {
			setLoading(true);
			const newData = data.map((e: any) => (e.id === r.id ? { ...e, status: status ? "A" : "D" } : e));
			const res: any = await _apiNotSaga.updateRole(r?.id, { status: status ? "A" : "D",  });
			setData(newData);
			notifySuccess(res.message);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};
	const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
	const cancelModal = () => {
		setVisible(false);
		form.resetFields();
	};
	const onCreateRole = async (val: any) => {
		const _params = {
			...val,
			status: status ? "A" : "D"
		};
		try {
			const res: any = !detail?.id
				? await _apiNotSaga.createRole(_params)
				: await _apiNotSaga.updateRole(detail.id, _params);
			notifySuccess(res.message);
			setVisible(false);
			setParams({
				...params,
				page: 1
			});
		} catch (error: any) {
			notifyError(error.message);
		}
		
	};
const deleteRole = async (id:any) => {
	try {
		const res: any = await _apiNotSaga.deleteRole(detail.id);
		// setDetail(res?.data);
		// form.setFieldsValue({
		// 	role_name: res?.data?.role_name,
		// 	role_code: res?.data?.role_code,
		// });
		// setStatus(res?.data?.status === "A");
		notifySuccess(res.message);
		setVisible(false);
		window.location.reload();
	} catch (error: any) {
		notifyError(error.message);
	}
}

	const getDetail = async (id: number) => {
		try {
			const res: any = await _apiNotSaga.roleDetail(id);
			setDetail(res?.data);
			form.setFieldsValue({
				role_name: res?.data?.role_name,
				role_code: res?.data?.role_code,
			});
			setStatus(res?.data?.status === "A");
			setVisible(true);
		} catch (error: any) {
			notifyError(error.message);
		}
	};
	return (
		<>
			{visible && (
				<Modal
					open={visible}
					// maskClosable={false}
					onCancel={cancelModal}
					footer={
						<div className="addStores__footer">
							<div className="flex text-[13px]">
								Hoạt động &nbsp;
								<Switch size="default" checked={status} onChange={() => setStatus(!status)}></Switch>
							</div>
							<div style={{ display: "flex", alignItems: "center" }}></div>&nbsp;
							<button onClick={deleteRole} className="addStores__footer__btn editBtn">
								<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">Xóa vai trò</p>
							</button>
							<div style={{ display: "flex", alignItems: "center" }}></div>&nbsp;
							<button type="submit" form="roleForm" className="addStores__footer__btn editBtn">
								<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!detail.id ? "Tạo mới" : "Cập nhật"}</p>
							</button>
						</div>
					}
					title={<Typography.Title level={3}>{`${!detail.id ? "Tạo mới" : "Cập nhật"} nhóm vai trò`}</Typography.Title>}
				>
					<Form form={form} name="roleForm" layout="vertical" onFinish={onCreateRole}>
					{!detail.id ? (
						<>
						<FormInput
							id="form-name"
							name="id"
							label=""
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Nhập ID"
							required
							className="w-full"
						/>
						<FormInput
							id="form-name"
							name="role_name"
							label=""
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Nhập tên nhóm vai trò"
							required
							className="w-full"
						/>
						<FormInput
							id="form-name"
							name="role_code"
							label=""
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Nhập mã nhóm vai trò"
							required
							className="w-full"
						/></>
					) : (
						<>
						
					
						<FormInput
							id="form-name"
							name="role_name"
							label=""
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Nhập tên nhóm vai trò"
							required
							className="w-full"
						/>
						<FormInput
							id="form-name"
							name="role_code"
							label=""
							type="includeSomeSpecialChar"
							size="large"
							placeholder="Nhập mã nhóm vai trò"
							required
							className="w-full"
						/>
						</>
					)}
					
						{/* <FormSelect
							name="user_type_id"
							label="Loại người dùng"
							dataSource={stateGetUserTypes?.data?.data}
							placeholder="Chọn loại người dùng"
							slOption={{
								name: "user_type_name",
								value: "id"
							}}
							required
						/> */}
					</Form>
				</Modal>
			)}
			<div className="contentSection">
				<Loading spinning={loading}>
					<div className="top-0 contentSection-filter">
						<FlexFilter
							onSearch={(v) => actionsFilter(v)}
							elements={[
								{
									...FLEX_FILTER_ELEMENTS.input,
									paramsName: "search",
									placeholder: "Nhập tên nhóm vai trò"
								},
								{
									...FLEX_FILTER_ELEMENTS.select,
									label: "Trạng thái hoạt động",
									placeholder: "Chọn trạng thái",
									paramsName: "status",
									width: 160,
									options: {
										data: STATUS_BASE,
										keyOption: "id",
										valueOption: "id",
										nameOption: "name"
									}
								},
								// {
								// 	...FLEX_FILTER_ELEMENTS.datpicker,
								// 	label: "Ngày tạo",
								// 	hasSelectDate: false,
								// 	width: 300
								// },
								{
									...FLEX_FILTER_ELEMENTS.btnSearch,
									icon: <SearchOutlined />,
									width: 150
								},
								{
									...FLEX_FILTER_ELEMENTS.btn,
									icon: <SvgIconPlus fill="black" />,
									width: 150,
									text: "Tạo mới",
									paramsName: "btnAdd",
									style: {
										color: "black",
										fontWeight: 600
									}
								}
							]}
						/>
					</div>
					<hr className=" mb-[10px] border-neutral_color_1_2" />
					<div className="contentSection-main">
						<TableStyled
							rowKey="id"
							dataSource={data}
							bordered
							pagination={false}
							isRowLight={true}
							columns={columnsData({ updateStatus, getDetail }) as any}
							scroll={{
								x: data?.length > 0 ? (columnsData({}).length - 1) * 100 : null,
								y: "calc(70vh - 65px)"
							}}
						/>
						<PanigationAntStyled
							style={{ marginTop: "8px" }}
							current={params.page}
							pageSize={params.limit}
							showSizeChanger
							onChange={onChangePaging}
							total={total}
						/>
					</div>
				</Loading>
			</div>
		</>
	);
};

export default Roles;
