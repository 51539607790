/*eslint-disable */

import { CloseOutlined } from "@ant-design/icons";
import {
	Affix,
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Input,
	InputNumber,
	Row,
	Select,
	Space,
	Tabs
} from "antd";
import TextArea from "antd/lib/input/TextArea";

import { useHistory, useParams } from "react-router-dom";

import Title from "antd/lib/typography/Title";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import SvgIconPlus from "src/assets/svg/SvgIconPlus";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import Editor2 from "src/components/editor/EditorV2";
import FormInput from "src/components/form/FormInput";
import FormInputNumber from "src/components/form/FormInputNumber";
import FormSelect from "src/components/form/FormSelect";
import FormUpload from "src/components/form/FormUpload";
import { notifyError, notifySuccess } from "src/components/notification";
import UpdaloadImage from "src/components/upload/UploadImage";
import { REASON_BASE, SALARY_BASE, WORK_EXP_BASE, WORK_FROM_BASE } from "src/constants";
import { getOrgs } from "src/services/actions/org.action";
import { _apiNotSaga, uploadFile } from "src/services/api/apiNotSaga";
import { CND_URL, TUYENDUNG_URL } from "src/services/api/config";
import { AppState, RouteParams } from "src/types";
import { dateFormatDMY } from "src/utils/helpers/functions/date";
import { localGetAccount } from "src/utils/localStorage";

const DetailsCreateDistrict = (props: any) => {
	let history = useHistory();
	const [form] = Form.useForm();
	const [isUpload, setIsUpload] = useState<boolean>(false);
	const [fileListCover, setFileListCover] = useState<any>([]);
	const [fileListPopup, setFileListPopup] = useState<any>([]);
	const [formCreate] = Form.useForm();
	const [provinces, setProvinces] = useState<any>([]);
	const editorRef = useRef<any>(null);
	const [ticketID, setTicketID] = useState<any>("");
	const paramsURL = useParams<RouteParams>();
	const [recruimentSeoId, setRecruimentSeoId] = useState<any>("");
	const _account = localGetAccount();
	// const [searchParams, setSearchParams] = useSearchParams();
	const editor1Ref = useRef<any>(null);
	const [metaTitle, setMetaTitle] = useState<any>("");
	const editor2Ref = useRef<any>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [districtDetails, setDistrictDetails] = useState<any>([]);
	const [dataPosition, setDataPosition] = useState<any>([]);
	const [dataDepartment, setDataDepartment] = useState<any>([]);
	const [dataEducation, setDataEducation] = useState<any>([]);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [isPopup, setIsPopup] = useState<boolean>(false);
	const [isHot, setIsHot] = useState<boolean>(false);
	const [dataZone, setDataZone] = useState<any>([]);
	const dispatch = useDispatch();
	const [stateContent, setStateContent] = useState<any>("");
	const [metadesc, setMetadesc] = useState<any>("");
	const [requestDesc, setRequestDesc] = useState<any>("");
	const [policy, setPolicy] = useState<any>("");
	const [dataUser, setDataUser] = useState<any>([]);
	const [metakeywork, setMetakeywork] = useState<any>("");
	const query = new URLSearchParams(props.location.search);
	const ticketid = query.get("ticket");
	const [metaslug, setMetaslug] = useState<any>("");
	const [metaImage, setMetaImage] = useState<any>("");
	const [jobDesc, setJobDesc] = useState<any>("");
	const [urlPopup, setUrlPopup] = useState<any>("");
	const [showTicket, setShowTicket] = useState<boolean>(false);
	const [statusNews, setStatusNews] = useState<any>("");
	const [slug, setSlug] = useState<any>("");
	const [rangeType, setRangeType] = useState<any>("");
	const [isLoad, setIsLoad] = useState<boolean>(false);
	const [postOffice, setPostOffice] = useState<any>([]);
	const [valueProvinceName, setValueProvinceName] = useState<any>([]);
	const [valueProvinceCode, setValueProvinceCode] = useState<any>([]);
	useEffect(() => {
		// getRecruimentNewsDetails(paramsURL.id);
		const getProvince = async () => {
			const res: any = await _apiNotSaga.getProvinces({});

			setProvinces(res?.data);
		};
		getProvince();
		// const initu = async () => {
		// 	try {
		// 		setLoading(true);
		// 		const res: any = await _apiNotSaga.getRecruimentNewsDetails(paramsURL.id);
		// 		// console.log(moment(res?.data?.expired_at).format(dateFormatDMY));
		// 		// setDataNews(res?.data);
		// 		const initp = async () => {
		// 			try {
		// 				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
		// 				setLoading(true);
		// 				const ress: any = await _apiNotSaga.getPositions({
		// 					limit: 100,
		// 					status: "A",
		// 					department_id: res?.data?.department_id
		// 				});
		// 				setDataPosition(ress?.data);
		// 				setLoading(false);
		// 			} catch (error: any) {
		// 				setLoading(false);
		// 				notifyError(error.message);
		// 			}
		// 		};
		// 		initp();
		// 		const initS = async () => {
		// 			try {
		// 				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
		// 				setLoading(true);
		// 				const resss: any = await _apiNotSaga.getRecruimentNewsSeo({ recruitment_news_id: paramsURL.id });
		// 				let dataseo = resss?.data[0] || {};
		// 				setRecruimentSeoId(dataseo?.id);
		// 				setMetaslug(dataseo?.meta_slug);
		// 				setMetadesc(dataseo?.meta_desc);
		// 				setMetaTitle(dataseo?.meta_title);
		// 				setMetakeywork(dataseo?.meta_keywork);
		// 				setMetaImage(dataseo?.meta_image);
		// 				// editorRef.current
		// 				// editor1Ref.current.setContent(dataseo?.request_desc);
		// 				// editor2Ref.current.setContent(dataseo?.policy);
		// 				setJobDesc(dataseo?.job_desc);
		// 				setRequestDesc(dataseo?.request_desc);
		// 				setPolicy(dataseo?.policy);
		// 				const mobie: any =
		// 					dataseo?.meta_image !== "" && dataseo?.meta_image
		// 						? [
		// 								{
		// 									url: `${CND_URL}/${dataseo?.meta_image}`,
		// 									isNotFile: true
		// 								}
		// 						  ]
		// 						: [];
		// 				setFileListCover(mobie);
		// 				formCreate.setFieldsValue({
		// 					meta_slug: dataseo?.meta_slug,
		// 					meta_title: dataseo?.meta_title,
		// 					meta_desc: dataseo?.meta_desc,
		// 					meta_keywork: dataseo?.meta_keywork,
		// 					meta_image: dataseo?.meta_image || ""
		// 				});
		// 				setLoading(false);
		// 			} catch (error: any) {
		// 				setLoading(false);
		// 				notifyError(error.message);
		// 			}
		// 		};
		// 		initS();
		// 		setStatusNews(res?.data?.status);
		// 		setSlug(res?.data?.slug);
		// 		setIsUpload(res?.data?.is_cv === 1 ? true : false);
		// 		setIsPopup(res?.data?.is_popup === 1 ? true : false);
		// 		setIsHot(res?.data?.is_hot === 1 ? true : false);
		// 		setShowTicket(res?.data?.ticket_id !== "" && res?.data?.ticket_id !== 0 ? true : false);
		// 		setTicketID(res?.data?.ticket_id);
		// 		const initz = async () => {
		// 			try {
		// 				let params = {
		// 					ClientKey: "mobileapp",
		// 					Type: "01",
		// 					CityCode: res?.data?.province_id.toString()
		// 				};
		// 				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
		// 				setLoading(true);
		// 				const ress: any = await _apiNotSaga.getPostOffices(params);
		// 				if (ress?.ReturnCode === "200") {
		// 					setPostOffice(ress.Data);
		// 				}
		// 				// setDataZone(res?.data);
		// 				setLoading(false);
		// 			} catch (error: any) {
		// 				setLoading(false);
		// 				notifyError(error.message);
		// 			}
		// 		};
		// 		initz();
		// 		const mobies: any =
		// 			res?.data?.url_popup !== "" && res?.data?.url_popup
		// 				? [
		// 						{
		// 							url: `${CND_URL}/${res?.data?.url_popup}`,
		// 							isNotFile: true
		// 						}
		// 				  ]
		// 				: [];
		// 		setFileListPopup(mobies);
		// 		setUrlPopup(res?.data?.url_popup);
		// 		formCreate.setFieldsValue({
		// 			...res?.data,
		// 			work_location: res?.data?.work_location !== "" ? JSON?.parse(res?.data?.work_location) : [],
		// 			expired_at: moment(res?.data?.expired_at),
		// 			public_at: moment(res?.data?.public_at),
		// 			salary_type: res?.data?.salary_type?.toString()
		// 		});
		// 		setLoading(false);
		// 	} catch (error: any) {
		// 		setLoading(false);
		// 		notifyError(error.message);
		// 	}
		// };
		// initu();
	}, [isLoad]);

	// useEffect(() => {
	// 	if (ticketid) {
	// 		setLoading(true);
	// 		const init = async () => {
	// 			try {
	// 				// if (users.length === 0 || !stateGetOrgs?.data) return;
	// 				setLoading(true);
	// 				const res: any = await _apiNotSaga.recruimentTicketDetails(ticketid);
	// 				const initp = async () => {
	// 					try {
	// 						// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
	// 						setLoading(true);
	// 						const ress: any = await _apiNotSaga.getPositions({
	// 							limit: 100,
	// 							status: "A",
	// 							department_id: res?.data?.department_id
	// 						});
	// 						setDataPosition(ress?.data);
	// 						setLoading(false);
	// 					} catch (error: any) {
	// 						setLoading(false);
	// 						notifyError(error.message);
	// 					}
	// 				};
	// 				initp();
	// 				formCreate.setFieldsValue({
	// 					career_id: res?.data?.career_id,
	// 					career_name: res?.data?.career_name,
	// 					department_block_id: res?.data?.department_block_id,
	// 					department_block_name: res?.data?.department_block_name,
	// 					department_id: res?.data?.department_id,
	// 					department_name: res?.data?.department_name,
	// 					org_id: res?.data?.org_id,
	// 					org_name: res?.data?.org_name,
	// 					position_id: res?.data?.position_id,
	// 					position_name: res?.data?.position_name,
	// 					work_location_id: res?.data?.work_location_id,
	// 					work_location_name: res?.data?.work_location_name,
	// 					zone_id: res?.data?.zone_id,
	// 					zone_name: res?.data?.zone_name,
	// 					ticket_id: res?.data?.ticket_id
	// 					// qty: res?.data?.qty?.toString(),
	// 				});
	// 				// handleChangeOrg(res?.data?.org_id);
	// 				// handleChangeDepartment(res?.data?.department_id);
	// 				// setStatus(res?.data?.status === "A");
	// 				// setLock(res?.data?.locked === "Y");
	// 				setLoading(false);
	// 			} catch (error: any) {
	// 				setLoading(false);
	// 				notifyError(error.message);
	// 			}
	// 		};
	// 		init();
	// 	}
	// }, []);
	const onSubmitForm = async (val: any) => {
		// console.log(val?.salary_from);
		// delete val.meta_slug;
		// delete val.meta_title;
		// delete val.meta_image;
		// if (val?.work_location?.length === 0 || !val?.work_location) return notifyError("Vui lòng nhập địa điểm làm việc");
		// if (val?.public_at > val?.expired_at) return notifyError("Ngày công bố không được lớn hơn ngày hết hạn");
		// if (val?.salary_from > val?.salary_to) return notifyError("Lương từ không được lớn hơn lương đến");
		// if (parseInt(val?.qty) < 1) return notifyError("Số lượng tuyển không được nhỏ hơn 1");
		// if (parseInt(val?.salary_from) < 0) return notifyError("Lương từ không được nhỏ hơn 0");
		// if (isPopup && !urlPopup) return notifyError("Vui lòng nhập ảnh popup");
		const _newParams = {
			...val
			// status: "O",
			// career_name: dataCareer?.find((e: any) => e.id === val?.career_id)?.career_name,
			// department_name: dataDepartment?.find((e: any) => e.id === val?.department_id)?.department_name,
			// position_name: dataPosition?.find((e: any) => e.id === val?.position_id)?.position_name,
			// education_name: dataEducation?.find((e: any) => e.id === val?.education_id)?.education_name,
			// zone_name: dataZone?.find((e: any) => e.id === val?.zone_id)?.zone_name,
			// org_name: stateGetOrgs?.data?.find((e: any) => e.id === val?.org_id)?.org_name,
			// work_location: JSON?.stringify(val?.work_location),
			// public_at: moment(val?.public_at).format("YYYY-MM-DD"),
			// expired_at: moment(val?.expired_at).format("YYYY-MM-DD"),
			// salary_type: val?.salary_type?.toString(),
			// is_cv: isUpload ? 1 : 0,
			// is_popup: isPopup ? 1 : 0,
			// is_hot: isHot ? 1 : 0,
			// url_popup: urlPopup,
			// province_name: districtDetails.province.name,
			// province_code:  districtDetails.province.code
			// created_by: `${dataUser?.find((e: any) => e.id === val?.created_by)?.user_erp} - ${dataUser?.find((e: any) => e.id === val?.created_by)?.full_name}`,
			// approved_by: `${dataUser?.find((e: any) => e.id === val?.approved_by)?.user_erp} - ${dataUser?.find((e: any) => e.id === val?.approved_by)?.full_name}`,
			// user_cv_id: `${dataUser?.find((e: any) => e.id === val?.user_cv_id)?.user_erp} - ${dataUser?.find((e: any) => e.id === val?.user_cv_id)?.full_name}`,
		};
		
		// isEdit
		// 	? dispatch(updateVehicleGroup(stateGetVehicleGroupById?.data?.id, _newParams))
		// 	: dispatch(createVehicleGroup(_newParams));
	};
	const handleAccept = async (val: any) => {
	
		const _newParams = {
			...val
		};
		const res: any = await _apiNotSaga.updateDistrictDetail(paramsURL.id, _newParams);
		setIsLoad(!isLoad);
		notifySuccess(res.message);
		// setLoading(true);
		history.push(`/list-district`);
		
	};
	const deleteProvince = async (id: any) => {
	
		// const _newParams = {
		// 	...val
		// };
	
		const res: any = await _apiNotSaga.deleteDistrict(paramsURL.id);
		setIsLoad(!isLoad);
		notifySuccess(res.message);
		// setLoading(true);
		history.push(`/list-district`);
		
	};
	useEffect(() => {
		if (!stateGetOrgs?.data) {
			dispatch(getOrgs({}));
		}
		const initu = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getUsers({ limit: 100, status: "A" });
				setDataUser(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initu();
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getDistrictDetail(paramsURL.id);
				setDistrictDetails(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();

		// const initd = async () => {
		// 	try {
		// 		// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
		// 		setLoading(true);
		// 		const res: any = await _apiNotSaga.getDepartment([]);
		// 		setDataDepartment(res?.data);
		// 		setLoading(false);
		// 	} catch (error: any) {
		// 		setLoading(false);
		// 		notifyError(error.message);
		// 	}
		// };
		// initd();
		// const inite = async () => {
		// 	try {
		// 		// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
		// 		setLoading(true);
		// 		const res: any = await _apiNotSaga.getEducation([]);
		// 		setDataEducation(res?.data);
		// 		setLoading(false);
		// 	} catch (error: any) {
		// 		setLoading(false);
		// 		notifyError(error.message);
		// 	}
		// };
		// inite();
		// const initz = async () => {
		// 	try {
		// 		// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
		// 		setLoading(true);
		// 		const res: any = await _apiNotSaga.getZones([]);
		// 		setDataZone(res?.data);
		// 		setLoading(false);
		// 	} catch (error: any) {
		// 		setLoading(false);
		// 		notifyError(error.message);
		// 	}
		// };
		// initz();
	}, []); //eslint-disable-line
	const handleChangeDepartment = (e: any) => {
		const initp = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getPositions({ limit: 100, status: "A", department_id: e });
				setDataPosition(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initp();
	};
	// console.log(districtDetails);
	// const uploadImgCover = (info: any) => {
	// 	console.log(info);
	// 	if (info.file) {
	// 		let file = info.file;
	// 		// setCoverFile(file);
	// 		uploadFile(file, (response: any) => {
	// 			if (response) {
	// 				console.log(response);
	// 				setMetaImage(response.data[0]);
	// 				// return formCreate.setFieldsValue({
	// 				// 	meta_image: response.data[0]
	// 				// });
	// 			}
	// 		});
	// 	}
	// };
	// const uploadImgPopup = (info: any) => {
	// 	console.log(info);
	// 	if (info.file) {
	// 		let file = info.file;
	// 		// setCoverFile(file);
	// 		uploadFile(file, (response: any) => {
	// 			if (response) {
	// 				console.log(response);
	// 				setUrlPopup(response.data[0]);
	// 				// return formCreate.setFieldsValue({
	// 				// 	meta_image: response.data[0]
	// 				// });
	// 			}
	// 		});
	// 	}
	// };
	// const handleChangeRange = (e: any) => {
	// 	setRangeType(e);
	// };
	// const handleChangeProvince = (e: any) => {
	// 	console.log(e);
	// 	const initz = async () => {
	// 		try {
	// 			let params = {
	// 				ClientKey: "mobileapp",
	// 				Type: "01",
	// 				CityCode: e.toString()
	// 			};
	// 			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
	// 			setLoading(true);
	// 			const res: any = await _apiNotSaga.getPostOffices(params);
	// 			if (res?.ReturnCode === "200") {
	// 				setPostOffice(res.Data);
	// 			}
	// 			// setDataZone(res?.data);
	// 			setLoading(false);
	// 		} catch (error: any) {
	// 			setLoading(false);
	// 			notifyError(error.message);
	// 		}
	// 	};
	// 	initz();
	// };
	const { features, menu: _menu, ..._user } = _account ? JSON.parse(_account) : null;
	return (
		<div className="p-6 trip-detail">
			<Form name="myForm" layout="vertical" form={formCreate} onFinish={onSubmitForm}>
				<Card className="p-0 rounded-md shadow-sm border border-neutral-300 ">
					<Tabs
						className="bg-white"
						defaultActiveKey="1"
						// onChange={onChange}
						items={[
							{
								label: `Thông tin chung`,
								key: "1",
								children: (
									<div className="">
										<div className={`bg-white p-8 rounded-2xl border border-slate-300 m-4`}>
											<div className="flex w-full justify-around items-center">
												<Title level={4}>Chỉnh sửa thông tin </Title>
												<Title level={4}>Thông tin tỉnh thành </Title>
												{/* {showTicket ? (
													<p className="font-bold">
														Ticket ID:{" "}
														<a target="_blank" href={`https://ticketdev.ntlogistics.vn/tickets/${ticketID}`}>
															<span className="text-link">#{ticketID}</span>
														</a>
													</p>
												) : null} */}
											</div>

											<Row gutter={[24, 24]}>
												<Col md={12} xs={24} sm={24}>
													<Row gutter={[24, 24]}>
														<Col md={24} xs={24} sm={24}>
															<FormInput
																// id="form-carGroup_name"
																name="district_code"
																label="Mã Quận / Huyện"
																type="text"
																size="large"
																// defaultValue={districtDetails.province_name}
																// required
																placeholder="Nhập mã Quận / Huyện"
																classItem="mb-0"
															/>
														</Col>
														{/* <Col md={12} xs={24} sm={24}>
														<FormInput
															id="form-carGroup_name"
															name="carGroup_name"
															label="SEO"
															type="text"
															size="large"
															placeholder="Nhập SEO"
															classItem="mb-0"
														/>
													</Col> */}
														<Col md={24} xs={24} sm={24}>
															<FormInput
																// id="form-carGroup_name"
																name="district_name"
																label="Tên Quận / Huyện"
																type="text"
																size="large"
																// required
																placeholder="Nhập tên Quận/ Huyện"
																classItem="mb-0"
															/>
														</Col>
													</Row>
												</Col>
												<Col md={12} xs={24} sm={24}>
													<div className="bg-neutral-100 w-full h-[326px] overflow-y-scroll px-4">
														<Row gutter={[24, 24]}>
															<Col md={24} xs={24} sm={24}>
																<span>Tên Quận / Huyện:</span>
																<div className="p-3 rounded-lg border-2 border-solid">
																	<p>{districtDetails.district_name}</p>
																</div>
															</Col>
															<Col md={24} xs={24} sm={24}>
																<span>Mã Quận / Huyện:</span>
																<div className="p-3 rounded-lg border-2 border-solid">
																	<p>{districtDetails.district_code}</p>
																</div>
															</Col>
															{/* <Col md={12} xs={24} sm={24}>
														<FormInput
															id="form-carGroup_name"
															name="carGroup_name"
															label="SEO"
															type="text"
															size="large"
															placeholder="Nhập SEO"
															classItem="mb-0"
														/>
													</Col> */}
														</Row>
													</div>
												</Col>
											</Row>
											{/* <Row gutter={[24, 24]}>
												<Col xs={24} md={8}>
													<FormSelect
														name="province_id"
														label="Tỉnh thành"
														dataSource={provinces}
														placeholder="Tỉnh thành"
														slOption={{
															name: "province_name",
															value: "id"
														}}
														showSearch
														optionFilterProp="children"
														required
													/>
												</Col>
											</Row> */}
										</div>
									</div>
								)
							}
						]}
					/>
				</Card>
				<Affix offsetBottom={2}>
					<div className="bg-[#404040] h-[60px] flex justify-end items-center w-full shadow-2xl border border-black">
						{/* {(statusNews === "A" || statusNews === "D") && (
							<Button
								size="large"
								className="mr-[20px] border-[#FFFFB8] border-1 hover:bg-[#404040] bg-[#404040] text-[#FFFFB8] hover:text-[#FFFFB8]font-bold"
							>
								<a
									href={`${TUYENDUNG_URL}${slug}/${metaslug}`}
									target="_blank"
									className="text-[15px] text-[#FFFFB8] hover:text-[#FFFFB8] font-bold"
								>
									Xem Tin
								</a>
							</Button>
						)}
						{statusNews === "O" && formCreate.getFieldValue('approved_by') === _user?.user?.id   && (
							<Button
								size="large"
								// block={true}
								// type="ghost"
								className="mr-[20px] border-[#FFFFB8] border-1 hover:bg-[#404040] bg-[#404040] text-[#FFFFB8] hover:text-[#FFFFB8]font-bold"
								onClick={() => {
									handleAccept(formCreate.getFieldsValue());
								}}
							>
								<p className="text-[15px] text-[#FFFFB8] hover:text-[#FFFFB8] font-bold">Duyệt tin</p>
							</Button>
						)} */}
						<Button
							size="large"
							// block={true}
							// type="link"
							htmlType="submit"
							// form="myForm"
							className="mr-[20px] bg-[#FADB14] hover:bg-[#FADB14] text-[#404040] font-bold"
							onClick={() => {
								deleteProvince(districtDetails.id);
							}}
						>
							<p className="text-[15px] text-[#404040] hover:text-[#404040]">Xóa tỉnh thành</p>
						</Button>
						<Button
							size="large"
							// block={true}
							// type="link"
							htmlType="submit"
							form="myForm"
							className="mr-[20px] bg-[#FADB14] hover:bg-[#FADB14] text-[#404040] font-bold"
							onClick={() => {
								handleAccept(formCreate.getFieldsValue());
							}}
						>
							<p className="text-[15px] text-[#404040] hover:text-[#404040]">Cập nhật</p>
						</Button>
						
					</div>
				</Affix>
			</Form>
		</div>
	);
};

export default DetailsCreateDistrict;
