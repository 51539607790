import React from "react";

function SvgIcon00023(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25" {...props}>
			<path
				fill="#001529"
				d="M17.708 5.208a3.125 3.125 0 116.25 0 3.125 3.125 0 01-6.25 0zM2.083 6.25h12.5a1.042 1.042 0 000-2.083h-12.5a1.042 1.042 0 000 2.083zm6.25 3.125a3.125 3.125 0 00-2.937 2.083H2.083a1.042 1.042 0 000 2.084h3.313a3.124 3.124 0 102.937-4.167zm14.584 2.083h-8.334a1.042 1.042 0 000 2.084h8.334a1.042 1.042 0 000-2.084zm-12.5 7.292H2.083a1.041 1.041 0 100 2.083h8.334a1.041 1.041 0 100-2.083zm12.5 0h-3.313a3.126 3.126 0 100 2.083h3.313a1.041 1.041 0 100-2.083z"
			></path>
		</svg>
	);
}

export default SvgIcon00023;
