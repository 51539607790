import { Button, Col, Form, Modal, Row, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SvgIconStorage from "src/assets/svg/SvgIconStorage";
import FormInput from "src/components/form/FormInput";
import FormSelect from "src/components/form/FormSelect";
import Loading from "src/components/loading/Loading";
import { notifyError, notifySuccess } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { getOrgs } from "src/services/actions/org.action";
import { getUserTypes } from "src/services/actions/userTypes.action";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { AppState, RouteParams } from "src/types";
import { useIsMount } from "src/utils/helpers/functions/useIsMount";
// import { columnsDataOrg } from "./data";
import { useHistory, useParams } from "react-router-dom";

const UserModal = ({
	visible = true,
	attributeIDLv1 = {} as any,
	attributeIDLv2 = {} as any,
	onCancel = () => {},
	onSuccess = () => {}
}) => {
	const paramsURL = useParams<RouteParams>();
	const isCreate = attributeIDLv1?.id ? false : true;
	const [form] = Form.useForm();
	const [data, setData] = useState<any>([]);
	const [status, setStatus] = useState<boolean>(true);
	const [isSupper, setIsSupper] = useState<boolean>(false);
	const [lock, setLock] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [attributeDetails, setAttributeDetails] = useState<any>([]);
	const [userTypes, setUserTypes] = useState<any>([]);
	const isMount = useIsMount();
	const dispatch = useDispatch();
	const [orgId, setOrgId] = useState<any>("");
	// const { stateGetRoles } = useSelector((e: AppState) => e.rolesReducer);
	const { stateGetUserTypes } = useSelector((e: AppState) => e.userTypesReducer);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [users, setUsers] = useState<any>([]);
	const [orgCodeAccess, setOrgCodeAccess] = useState<any>([]);
	const [dataDepartments, setDataDepartments] = useState<any>([]);
	const [level, setLevel] = useState<number | null>(null);
	const [dataAttribute, setDataAttribute] = useState<any>([]);
	const [showCreateModal, setShowCreateModal] = useState<boolean>(true);

	interface Option {
		id: number;
		title: string;
	}

	interface ApiResponse {
		data: any[]; // Thay `any` bằng kiểu dữ liệu chính xác của đối tượng trong mảng data nếu bạn biết
		message: string;
	}

	const [titleCategory, setTitleCategory] = useState<{ id: any; title: any }[]>([]);
	// useEffect(() => {
	// 	// if (stateGetUserTypes?.data) {
	// 	// 	setUserTypes(stateGetUserTypes?.data?.data);
	// 	// } else {
	// 	// 	dispatch(getUserTypes());
	// 	// }
	// 	if (!stateGetOrgs?.data) {
	// 		dispatch(getOrgs({}));
	// 	}
	// }, []); //eslint-disable-line
	// useEffect(() => {
	// 	const initpage = async () => {
	// 		try {
	// 			// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
	// 			setLoading(true);
	// 			const ress: any = await _apiNotSaga.getListCategory({});
	// 			setAttributeDetails(ress?.data);
	// 			// setToal(res?.paging?.total);
	// 			setLoading(false);
	// 		} catch (error: any) {
	// 			setLoading(false);
	// 			notifyError(error.message);
	// 		}
	// 	};
	// 	initpage();
	// }, []);

	const levelArray: Option[] = [
		{ id: 0, title: "level 0" },
		{ id: 1, title: "level 1" },
		{ id: 2, title: "level 2" },
		{ id: 3, title: "level 3" }
	];

	useEffect(() => {
		if (isMount) return;
		const { success, data, error } = stateGetUserTypes;
		if (success) {
			setUserTypes(data?.data);
			return;
		}
		if (success === false || error) {
			return notifyError(`${error}`);
		}
	}, [stateGetUserTypes.isLoading]); //eslint-disable-line

	// useEffect(() => {
	// 	// let id = attributeIDLv1.AttributeIDLv1;

	// 		const init = async () => {
	// 			try {
	// 				setLoading(true);
	// 				let res: any = await _apiNotSaga.getAttributeDetail(attributeIDLv1.id);

	// 				setAttributeDetails(res?.data[0]);
	// 				// setLevel(res?.data[0].level);
	// 				form.setFieldsValue({
	// 					...res?.data[0]
	// 				});
	// 				setStatus(res?.data[0].status === "A");
	// 				setLoading(false);
	// 			} catch (error: any) {
	// 				setLoading(false);
	// 				notifyError(error.message);
	// 			}
	// 		};
	// 		if (isCreate) {
	// 			form.resetFields();

	// 	}
	// 	init();

	// }, []);
	useEffect(() => {
		const init = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getListAttribute({});
				setDataAttribute(res?.data);
				// setToal(res?.paging?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		init();
	}, []); //eslint-disable-line

	const listAttribute = dataAttribute.map((item: { attribute_name: any; id: any }) => ({
		attribute_name: item.attribute_name,
		id: item.id
	}));

	useEffect(() => {
		const init = async () => {
			try {
				setLoading(true);
				let res: ApiResponse;
				if (attributeIDLv1.idlv1 === true) {
					res = (await _apiNotSaga.getAttributeDetail(attributeIDLv1.id)) as ApiResponse;
				} else {
					res = (await _apiNotSaga.getAttributeValueDetail(attributeIDLv1.id)) as ApiResponse;
				}

				if (res && res.data && res.data[0]) {
					setAttributeDetails(res.data[0]);
					// setLevel(res.data[0].level);
					form.setFieldsValue({
						...res.data[0]
					});
					setStatus(res.data[0].status === "A");
				}
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};

		if (isCreate) {
			form.resetFields();
		} else {
			init();
		}
	}, [attributeIDLv1, isCreate, form]);
	console.log(attributeIDLv1.id);

	useEffect(() => {
		const fetchData = async () => {
			// Giả sử bạn lấy dữ liệu từ API và cập nhật state
			setLoading(true);
			const ress: any = await _apiNotSaga.getListCategory({});
			// setAttributeDetails(ress?.data);
			// setToal(res?.paging?.total);
			setLoading(false);
			// Sau khi setAttributeDetails, cập nhật titleCategory
			const newTitleCategory = ress.data.map((item: { id: any; category_name: any }) => ({
				id: item.id,
				title: item.category_name
			}));
			newTitleCategory.unshift({ id: 0, title: "Danh mục bậc 1" });
			setTitleCategory(newTitleCategory);
		};

		fetchData();
	}, []);

	const onSubmit = async (val: any) => {
		const params = {
			...val,
			status: status ? "A" : "D"
		};
		
		setLoading(true);
		try {
			// const res: any = isCreate
			// 	? await _apiNotSaga.createAttribute(params)
			// 	: await _apiNotSaga.updateCategory(attributeIDLv1.id, params);
			let res: any;
			if (isCreate) {
				if (showCreateModal === true) {
					res = await _apiNotSaga.createAttribute(params);
				} else {
					res = await _apiNotSaga.createAttributeValue(params);
				}
			} else {
				if (attributeIDLv1.idlv1 === true) {
					res = await _apiNotSaga.updateAttribute(attributeIDLv1.id, params);
				} else {
					res = await _apiNotSaga.updateAttributeValue(attributeIDLv1.id, params);
				}
			}
			setLoading(false);
			notifySuccess(res.message);
			onSuccess();
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	const updateStatus = async (r: any, status: number, type: string) => {
		const mapStatus: any = {
			status: status ? "A" : "D"
		};
		try {
			setLoading(true);
			const res: any = await _apiNotSaga.updateUserOrg(r?.id, { [type]: mapStatus[type] });
			notifySuccess(res.message);
			// loadDataOrgUser();
			// setCallback(!callback);
			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			notifyError(error.message);
		}
	};

	// const deleteCategory = async (id: number) => {
	// 	try {
	// 		// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
	// 		let res: any;
	// 		// const res: any = await _apiNotSaga.deleteCategory(id);
	// 		if (attributeIDLv1.idlv1 == true) {
	// 			res = await _apiNotSaga.deleteAttribute(id);
	// 		} else {
	// 			res = await _apiNotSaga.deleteAttributeValue(id);
	// 		}
	// 		setLoading(false);
	// 		notifySuccess(res.message);
	// 		onSuccess();
	// 		// history.push(`/banner-list`);
	// 	} catch (error: any) {
	// 		setLoading(false);
	// 		notifyError(error.message);
	// 	}
	// 	console.log(id,attributeIDLv1.idlv1)
	// };
	const deleteCategory = async (id: number) => {
		try {
		//   setLoading(true);
		  let res = attributeIDLv1.idlv1
			? await _apiNotSaga.deleteAttribute(id)
			: await _apiNotSaga.deleteAttributeValue(id);
	  
			// const typedRes = res as ApiResponse; // Type assertion
		//   setLoading(false);
		//   notifySuccess(typedRes.message);
		//   onSuccess();
	
		} catch (error: any) {
		  setLoading(false);
		  notifyError(error.message);
		}
		
	  };
	const createAttributeValue = () => {
		setShowCreateModal(!showCreateModal);
	};
	// const getDetail = async (id: number) => {
	// 	// setDetail({ id });
	// 	// setVisible(true);
	// };
	const filteredOptions = levelArray.filter((option) => option.id <= (level ?? 0));

	return (
		<>
			<Modal
				open={visible}
				width={800}
				onCancel={onCancel}
				// maskClosable={false}
				// title={`${isCreate ? "Tạo mới" : "Cập nhật"} thuộc tính`}
				title={`${isCreate ? "Tạo mới" : "Cập nhật"} thuộc tính`}
				style={{
					maxWidth: "90vw",
					width: "80vw"
				}}
				footer={
					<div className="addStores__footer flex flex-col md:flex-row">
						<div className="flex items-center gap-8">
							<div className="flex text-[13px] mb-4 md:mb-0">
								Hoạt động &nbsp;
								<Switch disabled={loading} size="default" checked={status} onChange={(e) => setStatus(e)}></Switch>
							</div>
							{/* <div className="flex text-[13px] mb-4 md:mb-0">
								Supper Admin
								<Switch disabled={loading} size="default" checked={isSupper} onChange={(e) => setIsSupper(e)}></Switch>
							</div> */}
						</div>
						{!isCreate ? (
							<button
							     type="button"
								disabled={loading}
								className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
								onClick={() => deleteCategory(attributeIDLv1.id)}
							>
								<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">Xóa bỏ</p>
							</button>
						) : (
							""
						)}

						<Button
							disabled={loading}
							htmlType="submit"
							form="myForm"
							className="addStores__footer__btn editBtn disabled:cursor-not-allowed"
						>
							<SvgIconStorage fill="#fdd800" /> <p className="text-[#fdd800]">{!isCreate ? "Cập nhật" : "Tạo mới"}</p>
						</Button>
					</div>
				}
			>
				<Loading spinning={loading}>
					<Form layout="vertical" form={form} name="myForm" onFinish={onSubmit} scrollToFirstError>
						{/* <Typography.Title level={5}>Thông tin chung</Typography.Title> */}
						{/* tạo thuộc tính và giá trị thuộc tính */}
						{attributeIDLv1.id && (
							<Row gutter={[16, 16]}>
								{attributeIDLv1.idlv1 == true ? (
									<>
										<Col xs={24} md={12}>
											<FormInput
												name="attribute_code"
												label="Mã thuộc tính"
												type="text"
												size="large"
												placeholder="Nhập mã thuộc tính"
												required
											/>
										</Col>
										<Col xs={24} md={12}>
											<FormInput
												name="attribute_name"
												label="Tên thuộc tính"
												type="text"
												size="large"
												placeholder="Thêm tên thuộc tính"
												required
											/>
										</Col>
									</>
								) : (
									<>
										<Col xs={24} md={12}>
											<FormSelect
												name="attribute_id"
												label="Chọn thuộc tính cha"
												dataSource={listAttribute}
												placeholder="Chọn thuộc tính cha"
												slOption={{
													name: "attribute_name",
													value: "id"
												}}
												showSearch
												optionFilterProp="children"
												required
											/>
										</Col>
										<Col xs={24} md={12}>
											<FormInput
												name="attribute_value"
												label="Tên giá trị thuộc tính"
												type="text"
												size="large"
												placeholder="Thêm tên giá trị thuộc tính"
												required
											/>
										</Col>
									</>
								)}
							</Row>
						)}
						{showCreateModal === true ? (
							<>
								{isCreate ? (
									<Row gutter={[16, 16]}>
										<Col xs={24} md={12}>
											<FormInput
												name="attribute_code"
												label="Mã thuộc tính thuộc tính"
												type="text"
												size="large"
												placeholder="Thêm tên thuộc tính"
												required
												// disabled
											/>
										</Col>
										<Col xs={24} md={12}>
											<FormInput
												name="attribute_name"
												label="Tên thuộc tính"
												type="text"
												size="large"
												placeholder="Thêm tên thuộc tính"
												required
												// disabled
											/>
										</Col>
									</Row>
								) : (
									""
								)}
							</>
						) : (
							<>
								{isCreate ? (
									<Row gutter={[16, 16]}>
										<Col xs={24} md={12}>
											<FormSelect
												name="attribute_id"
												label="Chọn thuộc tính cha"
												dataSource={listAttribute}
												placeholder="Chọn thuộc tính cha"
												slOption={{
													name: "attribute_name",
													value: "id"
												}}
												showSearch
												optionFilterProp="children"
												// required
											/>
										</Col>
										<Col xs={24} md={12}>
											<FormInput
												name="attribute_value"
												label="Tên giá trị thuộc tính"
												type="text"
												size="large"
												placeholder="Thêm tên giá trị thuộc tính"
												required
												// disabled
											/>
										</Col>
									</Row>
								) : (
									""
								)}
							</>
						)}
					</Form>
				</Loading>
				{attributeIDLv1 && attributeIDLv1.id != undefined ? (
				""
				):(	<>
					{showCreateModal === true ? (
						<button
							className="border-[2px] border-solid border-[#222222fe] p-1 rounded-[8px] mt-6"
							type="button"
							onClick={createAttributeValue}
						>
							Tạo giá trị thuộc tính
						</button>
					) : (
						<button
							className="border-[2px] border-solid border-[#222222fe] p-1 rounded-[8px] mt-6"
							type="button"
							onClick={createAttributeValue}
						>
							Tạo thuộc tính
						</button>
					)}
					</>)}
				
			</Modal>
		</>
	);
};

export default UserModal;
