const attributeTypes = {
    START_GET_LIST_ATTRIBUTE: "START_GET_LIST_ATTRIBUTE",
    GET_LIST_ATTRIBUTE_SUCCESS: "GET_LIST_ATTRIBUTE_SUCCESS",
    GET_LIST_ATTRIBUTE_FAILURE: "GET_LIST_ATTRIBUTE_FAILURE",

    // START_GET_ATTRIBUTE_BY_ID: "START_GET_ATTRIBUTE_BY_ID",
    // GET_ATTRIBUTE_BY_ID_SUCCESS: "GET_ATTRIBUTE_BY_ID_SUCCESS",
    // GET_ATTRIBUTE_BY_ID_FAILURE: "GET_ATTRIBUTE_BY_ID_FAILURE",

    // START_GET_ATTRIBUTE_BY_ID_CHILDREN: "START_GET_ATTRIBUTE_BY_ID_CHILDREN",
    // GET_ATTRIBUTE_BY_ID_CHILDREN_SUCCESS: "GET_ATTRIBUTE_BY_ID_CHILDREN_SUCCESS",
    // GET_ATTRIBUTE_BY_ID_CHILDREN_FAILURE: "GET_ATTRIBUTE_BY_ID_CHILDREN_FAILURE",
  }
  
  export default attributeTypes;