import { all } from "redux-saga/effects";
import accountSagas from "./account.sagas";
import pageHeaderSagas from "./pageHeader.sagas";
import waitingBookSagas from "./waitingBook.sagas";
import vehicleGroupSagas from "./vehicleGroup.sagas";
import repairRegulationSagas from "./repairRegulation.sagas";
import repairManagementSagas from "./repairManagement.sagas";
import suppliesSagas from "./supplies.sagas";
import vehicleTypeSagas from "./vehicleType.sagas";
import globalSagas from "./global.sagas";
import vehicleSagas from "./vehicle.sagas";
import routingGroupSagas from "./routingGroups.sagas";
import driversSagas from "./drivers.sagas";
import routesSagas from "./routes.sagas";
import placesSagas from "./places.sagas";
import priceOffTypesSagas from "./priceOffTypes.sagas";
import customerSagas from "./customer.sagas";
import orgSagas from "./org.sagas";
import rolesSagas from "./roles.sagas";
import mouduleFuntSagas from "./moduleFunct.sagas";
import userTypesSagas from "./userTypes.sagas";
import repairPartnerSagas from "./repairPartner.sagas";
import exportSagas from "./export.sagas";
import importSagas from "./import.sagas";
import transportTripsSagas from "./transportTrips.sagas";
import transportAutoConfigsSagas from "./transportAutoConfigs.sagas";
import userSagas from "./user.sagas";

import categoriesSagas from "./categories.sagas";
import attributeSagas from "./attribute.sagas";

function* rootSagas() {
  yield all([
    ...accountSagas,
    ...customerSagas,
    ...pageHeaderSagas,
    ...waitingBookSagas,
    ...suppliesSagas,
    ...vehicleGroupSagas,
    ...repairRegulationSagas,
    ...repairManagementSagas,
    ...vehicleTypeSagas,
    ...globalSagas,
    ...vehicleSagas,
    ...routingGroupSagas,
    ...driversSagas,
    ...routesSagas,
    ...placesSagas,
    ...priceOffTypesSagas,
    ...orgSagas,
    ...rolesSagas,
    ...mouduleFuntSagas,
    ...userTypesSagas,
    ...repairPartnerSagas,
    ...exportSagas,
    ...importSagas,
    ...transportTripsSagas,
    ...transportAutoConfigsSagas,
    ...userSagas,
    ...categoriesSagas,
    ...attributeSagas
  ]);
}

export default rootSagas;
