/*eslint-disable*/
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import SvgMoreOutLined from "src/components/Svg/SvgMoreOutLined";
import FlexFilter, { FLEX_FILTER_CONFIGS } from "src/components/filter/FlexFilter";
import Loading from "src/components/loading/Loading";
import { DEFAULT_FILTER, FLEX_FILTER_ELEMENTS, ISHOT, REASON_BASE, STATUS_POST_BASE, WORK_FROM_BASE } from "src/constants";
import "./style.scss";
import { _paramsFilter, columnsData, columnsDataDetail } from "./data";
import TableStyled from "src/components/table/TableStyled";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { notifyError } from "src/components/notification";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "src/types";
import { getOrgs } from "src/services/actions/org.action";
import { removeIdentity } from "src/utils/helpers/functions/removeIdentity";
interface DataType {
	key: React.ReactNode;
	name: string;
	age: number;
	address: string;
	children?: DataType[];
}
const ListPost = () => {
    
	const [data, setData] = useState<any>([]);
	const [params, setParams] = useState<any>(DEFAULT_FILTER);
	const [paramsFilter, setParamsFilter] = useState<any>({ ..._paramsFilter });
	const [visible, setVisible] = useState<any>(false);
	const history = useHistory();
	const [dataEducation, setDataEducation] = useState<any>([]);
	const [dataPosition, setDataPosition] = useState<any>([]);
	const [loading, setLoading] = useState<any>(false);
	const [total, setTotal] = useState(0);
	
	const [dataSyscTicket, setDataSyscTicket] = useState<any>([]);
	const [isEdit, setIsEdit] = useState<any>(false);
	const [idEdit, setIdEdit] = useState<any>();
	const [idCostsIncurred, setIdCostsIncurred] = useState<any>();
	const [visibleCostsIncurred, setVisibleCostsIncurred] = useState<any>(false);
	const [statusStatements, setStatusStatements] = useState<any>([]);
	const [statusPayment, setStatusPayment] = useState<any>([]);
	const [visibleCancel, setVisibleCancel] = useState<any>(false);
	const [paramsCancel, setParamsCancel] = useState<any>({});
	const [dataNews, setDataNews] = useState<any>([]);
	const [account, setAccount] = useState<any>({});
	const dispatch = useDispatch();
	const [dataZone, setDataZone] = useState<any>([]);
	const { stateGetOrgs } = useSelector((e: AppState) => e.orgReducer);
	const [dataCareer, setDataCareer] = useState<any>([]);
	const [dataDepartment, setDataDepartment] = useState<any>([]);
	useEffect(() => {
		
		if (!stateGetOrgs?.data) {
			dispatch(getOrgs({}));
		}
		const initcareer = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getCareer({ limit: 100, status: "A" });
				setDataCareer(res?.data);
				
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initcareer();
		const initp = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const ress: any = await _apiNotSaga.getPositions({
					limit: 100,
					status: "A",
					// department_id: res?.data?.department_id
				});
				setDataPosition(ress?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initp();
		const initd = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getDepartment([]);
				setDataDepartment(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initd();
		const inite = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getEducation([]);
				setDataEducation(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		inite();
		const initz = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getZones([]);
				setDataZone(res?.data);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initz();
	}, [dispatch]); //eslint-disable-line
	useEffect(() => {
		const initu = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setLoading(true);
				const res: any = await _apiNotSaga.getRecruimentNews(params);
				setDataNews(res?.data);
				setTotal(res?.total);
				setLoading(false);
			} catch (error: any) {
				setLoading(false);
				notifyError(error.message);
			}
		};
		initu();
	}, [params]);
    const onChangePaging = (page: number, pageSize: number) => {
		setParams((prevState: any) => ({
			...prevState,
			page: page,
			limit: pageSize
		}));
	};
    const onEdit = (id: any) => {
		setIsEdit(true);
		setVisible(true);
		setIdEdit(id);
	};
    const onCancel = (id: any, isCancel: boolean) => {
		if (isCancel) {
			// updateStatementTripCost(id, { status_cancel: "C" });
		} else {
			setVisibleCancel(true);
			paramsCancel.id = id;
			paramsCancel.status = "R";
			setParamsCancel({ ...paramsCancel });
		}
	};
    const pushUpApproval = (id: number, userId: number, isPush: boolean) => {
		let _paramsPush = {
			user_id: userId
		};
		let _params = {
			approval_id: userId
		};
		// updateStatementTripCost(id, isPush ? _paramsPush : _params);
	};
	const onCreateTicket = (id: number) => {
		let _params = {
			ticket_id: id
		};
		// updateStatementTripCost(id, _params);
	};
    const costsIncurredDetail = (id: any) => {
		setIdCostsIncurred(id);
		setVisibleCostsIncurred(true);
	};
	const getDetail = async (id: number) => {
		history.push(`/create-post/${id}`);
	};
	const onSearch: (val: any) => void = (val) => {
		const { params: flexParams } = val;
		const newParams = removeIdentity({
			...params,
			...flexParams,
			page: 1
		});
		setParams(newParams);
	};
	return (
		<Loading spinning={loading}>
			<div className="contentSection">
				<div className="top-0 contentSection-filter">
					<FlexFilter
						onSearch={(v) => onSearch(v)}
						manipulation={[
							{
								paramsName: "btnAdd",
								icon: <PlusOutlined />,
								text: "Tạo mới"
							}
							// {
							// 	paramsName: "btnSysc",
							// 	icon: <TransactionOutlined />,
							// 	text: "Tạo ticket thanh toán"
							// }
						]}
						elements={[
							{
								...FLEX_FILTER_ELEMENTS.input,
								placeholder: "Nhập tiêu đề cần tìm",
                                label: "Tiêu đề",
								paramsName: "search"
							},
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Chi nhánh",
								placeholder: "Chọn Chi nhánh",
								paramsName: "org_id",
								width: 150,
								options: {
									data: stateGetOrgs?.data,
									keyOption: "id",
									valueOption: "id",
									nameOption: "org_name"
								}
							},
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Ngành nghề",
								placeholder: "Chọn ngành nghề",
								paramsName: "career_id",
								width: 180,
								options: {
									data: dataCareer,
									keyOption: "id",
									valueOption: "id",
									nameOption: "career_name"
								}
							},
                            {
								...FLEX_FILTER_ELEMENTS.select,
								label: "Trạng thái",
								placeholder: "Chọn trạng thái",
								width: 160,
								options: {
									data: STATUS_POST_BASE,
									keyOption: "id",
									valueOption: "id",
									nameOption: "name"
								},
								paramsName: "status"
							},
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Trạng thái",
								placeholder: "Chọn trạng thái",
								width: 160,
								options: {
									data: STATUS_POST_BASE,
									keyOption: "id",
									valueOption: "id",
									nameOption: "name"
								},
								paramsName: "status"
							},
							{
								...FLEX_FILTER_ELEMENTS.btnFilterMore
							},
							// {
							// 	...FLEX_FILTER_ELEMENTS.createdRange,
							// 	initValue: FLEX_FILTER_CONFIGS.initTime.optional.result,
							// 	initSelectDate: FLEX_FILTER_CONFIGS.initTime.optional.title,
							// 	width: 250
							// },
							{
								...FLEX_FILTER_ELEMENTS.btnSearch,
								icon: <SearchOutlined />
							},
							// { ...FLEX_FILTER_ELEMENTS.btnAction, icon: <SvgMoreOutLined /> }
						]}
						elementsMore={[
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Chức danh",
								placeholder: "Chức danh",
								width: 160,
								options: {
									data: dataPosition,
									keyOption: "id",
									valueOption: "id",
									nameOption: "position_name"
								},
								paramsName: "position_id"
							},
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Phòng ban",
								placeholder: "Phòng ban",
								width: 160,
								options: {
									data: dataDepartment,
									keyOption: "id",
									valueOption: "id",
									nameOption: "department_name"
								},
								paramsName: "department_id",
							},
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Bằng cấp",
								placeholder: "Bằng cấp",
								width: 160,
								options: {
									data: dataEducation,
									keyOption: "id",
									valueOption: "id",
									nameOption: "education_name"
								},
								paramsName: "education_id",
							},
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Loại hình công việc",
								placeholder: "Loại hình công việc",
								width: 160,
								options: {
									data: WORK_FROM_BASE,
									keyOption: "id",
									valueOption: "id",
									nameOption: "name"
								},
								paramsName: "form_work",
							},
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Tin hot",
								placeholder: "Tin hot",
								width: 160,
								options: {
									data: ISHOT,
									keyOption: "id",
									valueOption: "id",
									nameOption: "name"
								},
								paramsName: "is_hot",
							},
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Tin Popup",
								placeholder: "Tin Popup",
								width: 160,
								options: {
									data: ISHOT,
									keyOption: "id",
									valueOption: "id",
									nameOption: "name"
								},
								paramsName: "is_popup",
							},
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Vùng miền",
								placeholder: "Vùng miền",
								width: 160,
								options: {
									data: dataZone,
									keyOption: "id",
									valueOption: "id",
									nameOption: "zone_name"
								},
								paramsName: "zone_id",
							},
							// {
							// 	...FLEX_FILTER_ELEMENTS.select,
							// 	label: "Chi nhánh",
							// 	placeholder: "Chi nhánh",
							// 	width: 160,
							// 	options: {
							// 		data: stateGetOrgs?.data,
							// 		keyOption: "id",
							// 		valueOption: "id",
							// 		nameOption: "org_name"
							// 	},
							// 	paramsName: "org_id",
							// },
							{
								...FLEX_FILTER_ELEMENTS.select,
								label: "Lý do tuyển",
								placeholder: "Lý do tuyển",
								width: 160,
								options: {
									data: REASON_BASE,
									keyOption: "id",
									valueOption: "id",
									nameOption: "name"
								},
								paramsName: "reason",
							},
							{
								...FLEX_FILTER_ELEMENTS.createdRange,
								label: "Ngày tạo",
								initValue: FLEX_FILTER_CONFIGS.initTime.optional.result,
								initSelectDate: FLEX_FILTER_CONFIGS.initTime.optional.title,
								width: 250
							},
						]}
					/>
				</div>
				<hr className=" mb-[10px] border-neutral_color_1_2" />
				<div className="contentSection-main">
					<TableStyled
						rowKey="id"
						dataSource={dataNews}
						bordered
						pagination={{
							locale: {
								items_per_page: "/Trang"
							},
							current: params.page,
							showSizeChanger: true,
							defaultPageSize: params.limit,
							total: total || 0,
							showTotal: (total: any, range: any) => (
								<div className="flex gap-2">
									<strong>
										{range[0]} - {range[1]}
									</strong>
									trong tổng
									<strong>{total}</strong>
								</div>
							),
							onChange: onChangePaging
						}}
						scroll={{
							// x: 1444,
							y: "calc(70vh - 80px)"
						}}
						isRowLight={true}
						columns={columnsData({ onEdit, onCancel, pushUpApproval, onCreateTicket, user: account,getDetail }) as any}
						// expandable={{
						// 	expandedRowRender: (record: any, i: number) => (
						// 		<div className="w-full bg-[#fffdf5]">
						// 			<TableStyled
						// 				rowKey="id"
						// 				dataSource={record?.details || []}
						// 				bordered
						// 				pagination={false}
						// 				isRowLight={true}
						// 				backgroundHeader={"#dfe2e4"}
						// 				columns={columnsDataDetail({ costsIncurredDetail }) as any}
						// 				scroll={{
						// 					y: "calc(40vh - 50px)"
						// 				}}
						// 			/>
						// 		</div>
						// 	),
						// 	rowExpandable: (record: any) => record.name !== "Not Expandable"
						// }}
					/>
				</div>
			</div>
		</Loading>
	);
};

export default ListPost;
