import React from "react";

const SvgNoti = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 31 31" fill="none">
			<g clip-path="url(#clip0_7101_5393)">
				<path
					d="M20.6869 25.8338C20.6869 28.6873 18.3736 31.0004 15.5201 31.0004C12.6666 31.0004 10.3535 28.6873 10.3535 25.8338C10.3535 22.9803 12.6666 20.667 15.5201 20.667C18.3736 20.667 20.6869 22.9803 20.6869 25.8338Z"
					fill="#F1B11E"
				/>
				<path
					d="M15.5201 5.16659C14.8073 5.16659 14.2285 4.58808 14.2285 3.875V1.29159C14.2285 0.578743 14.8073 0 15.5201 0C16.2332 0 16.8119 0.578743 16.8119 1.29159V3.875C16.8119 4.58808 16.2332 5.16659 15.5201 5.16659Z"
					fill="#F1B11E"
				/>
				<path
					d="M27.6347 21.8442C25.6818 20.1934 24.5619 17.7819 24.5619 15.2257V11.6246C24.5619 6.6387 20.5048 2.58301 15.5201 2.58301C10.5356 2.58301 6.47852 6.6387 6.47852 11.6246V15.2257C6.47852 17.7819 5.35863 20.1948 3.39394 21.8558C2.89159 22.2846 2.60352 22.9111 2.60352 23.5724C2.60352 24.8191 3.61744 25.833 4.86385 25.833H26.1764C27.423 25.833 28.4369 24.8191 28.4369 23.5724C28.4369 22.9111 28.1489 22.2846 27.6347 21.8442Z"
					fill="#F1B11E"
				/>
			</g>
			<defs>
				<clipPath id="clip0_7101_5393">
					<rect width="31" height="31" fill="white" color="white"/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default SvgNoti;
