/*eslint-disable*/
const env = process.env;

const CONFIG = {
	Development: { url: env.REACT_APP_API_URL_DEV },
	Production: { url: env.REACT_APP_API_URL_PRO },
	Localhost: { url: env.REACT_APP_API_URL_LOCAL }
};
// export const API_URL = CONFIG[env.REACT_APP_ENVIROMENT].url;
export const NAME_SERVER = env.REACT_APP_ENVIROMENT;
export const API_BO = `${env.REACT_APP_API_BO_URL}v1`;
export const API_URL = `${env.REACT_APP_API_URL}v1`;
export const API_URL_ERP = `${env.REACT_APP_URL_ERP}v1`;
export const API_URL_MASTER = `${env.REACT_APP_API_URL}api`;
export const API_DEFAULT = `${env.REACT_APP_API_URL}`;

export const API_END_POINT = `${env.REACT_APP_API_URL}`;
export const API_URL_FE = `${env.REACT_APP_URL_FE}`;

export const API_URL_CDN = `${env.REACT_APP_API_CDN}`;

export const CND_URL = `${env.REACT_APP_CDN_URL}`;
export const TICKET_URL = `${env.REACT_APP_TICKET_URL}`;
export const ONESIGNAL_KEY = `${env.REACT_APP_ONESIGNAL_KEY}`;
export const CUBE_URL = `${env.REACT_APP_CUBE_URL}`;
export const CUBE_TOKEN = `${env.REACT_APP_CUBE_TOKEN}`;

export const TUYENDUNG_URL = `${env.REACT_APP_TUYENDUNG_URL}`;
